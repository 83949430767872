/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CubeDetailQuery } from '../models/CubeDetailQuery';
import type { MemberCubeCubeCategoryUpdateForm } from '../models/MemberCubeCubeCategoryUpdateForm';
import type { MemberCubesForm } from '../models/MemberCubesForm';
import type { MemberCubeUpdateForm } from '../models/MemberCubeUpdateForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberCubeControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMemberCube(
        requestBody: MemberCubeUpdateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/member-cube',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createMemberCubes(
        requestBody: MemberCubesForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/member-cube',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberCubeId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMemberCubeCubeCategory(
        memberCubeId: number,
        requestBody: MemberCubeCubeCategoryUpdateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/member-cube/{memberCubeId}/cube-category',
            path: {
                'memberCubeId': memberCubeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberCubeIdA
     * @param memberCubeIdB
     * @returns any OK
     * @throws ApiError
     */
    public static mergeMemberCubeAtoB(
        memberCubeIdA: number,
        memberCubeIdB: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/member-cube/{memberCubeIdA}/merge/{memberCubeIdB}',
            path: {
                'memberCubeIdA': memberCubeIdA,
                'memberCubeIdB': memberCubeIdB,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberCubeId
     * @returns CubeDetailQuery OK
     * @throws ApiError
     */
    public static getMemberCubeDetail(
        memberCubeId: number,
    ): CancelablePromise<CubeDetailQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member-cube/{memberCubeId}',
            path: {
                'memberCubeId': memberCubeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberCubeId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMemberCube(
        memberCubeId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/member-cube/{memberCubeId}',
            path: {
                'memberCubeId': memberCubeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
