import { atom } from 'recoil'

export const loggedInMemberState = atom({
  key: 'member',
  default: {
    memberId: 0,
    workspaceId: '',
  },
})

export const chatMessageUnreadCountState = atom({
  key: 'chatMessageUnreadCountState',
  default: 0
})
