import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  CubeControllerService,
  CubeQuery,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
} from '../../services'
import Loading from 'react-loading'
import { Button } from '../../components/button'

interface Props {
  memberId: number
  closeModal: () => void
}
export const CubeExtractModal: FC<Props> = ({ memberId, closeModal }) => {
  const [cubeExtractStatus, setCubeExtractStatus] = useState(
    '' as 'extracting' | 'extracted' | 'creating' | '',
  )
  const [fileName, setFileName] = useState('')
  const [extractedCubes, setExtractedCubes] = useState([] as CubeQuery[])
  const [checkedCubes, setCheckedCubes] = useState([] as CubeQuery[])

  // キューブ抽出
  const createCubeExtractModal = () => {
    // ファイル選択ボタンがクリックされたときの処理
    const fileSelect = () => {
      const inputElement = document.getElementById('file') as HTMLInputElement
      inputElement.value = ''
      setFileName('')
      inputElement.click()
    }

    // ファイルが選択されたときの処理
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      setFileName(file?.name || '')
    }

    const isFileNameEmpty = fileName === ''
    // キューブ抽出処理
    const extract = () => {
      // ファイルが選択されていない場合は何もしない
      if (isFileNameEmpty) {
        return
      }
      setCubeExtractStatus('extracting')

      const inputElement = document.getElementById('file') as HTMLInputElement
      CubeControllerService.extractCubes({
        // @ts-ignore
        file: inputElement.files?.[0],
      })
        .then(res => {
          if (!res || !res.length) {
            toast.warning('有効なデータが見つかりませんでした')
            setCubeExtractStatus('')
          } else {
            setExtractedCubes(res)
            setCheckedCubes(res)
            setCubeExtractStatus('extracted')
          }
        })
        .catch(err => {
          toast.error('抽出に失敗しました')
          setCubeExtractStatus('')
          apiErrorHandler(err)
        })
    }

    return (
      <div
        className="fixed bottom-0 top-[5%] w-full left-1/2 transform -translate-x-1/2 md:w-[590px] max-w-full overflow-hidden rounded-xl bg-white flex flex-col justify-start p-6 md:rounded-t-xl md:top-1/2 md:bottom-auto md:flex md:transform md:-translate-x-1/2 md:-translate-y-1/2"
        role="dialog"
        aria-modal="true"
        aria-labelledby="extract-cube__title"
      >
        <div className="flex justify-between items-center">
          <h1
            className="font-bold text-2xl md:text-3xl"
            id="extract-cube__title"
          >
            キューブを自動抽出
          </h1>

          <Button onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
              className="w-full h-auto object-cover"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
        </div>

        <p className="mt-10 text-gray-500 text-sm text-left">
          ご自身のことがわかる書類をアップロードすると、自動でキューブを抽出します。
          <br />
          書類の一例：
          <br />
          ・職務経歴書
          <br />
          ・ポートフォリオ
          <br />
          ・関わったプロジェクトの提案書
        </p>
        <div className="flex flex-col my-10 mb-16 text-left">
          <div className="mt-0">
            <div className="font-bold text-sm block text-left">
              <p className="mb-2">ファイルから抽出</p>
              <Button
                className="btn-base btn-primary-base text-xs rounded-lg px-3 py-2 inline-flex items-center gap-1"
                onClick={fileSelect}
              >
                <span>ファイルをアップロード</span>
                <svg
                  className="w-3.5 h-3.5 fill-current text-white"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99992 1.66602L10.0974 1.67185C10.2834 1.69379 10.4566 1.77775 10.5891 1.91019C10.7215 2.04263 10.8055 2.21583 10.8274 2.40185L10.8333 2.49935V5.83268L10.8374 5.95768C10.8672 6.35449 11.038 6.72754 11.319 7.00937C11.5999 7.29121 11.9724 7.46323 12.3691 7.49435L12.4999 7.49935H15.8333L15.9308 7.50518C16.1168 7.52712 16.29 7.61108 16.4224 7.74352C16.5549 7.87597 16.6388 8.04917 16.6608 8.23518L16.6666 8.33268V15.8327C16.6666 16.4704 16.423 17.0839 15.9855 17.5479C15.548 18.0119 14.9498 18.2911 14.3133 18.3285L14.1666 18.3327H5.83325C5.19558 18.3327 4.58199 18.0891 4.11803 17.6516C3.65408 17.2141 3.37483 16.6159 3.33742 15.9793L3.33325 15.8327V4.16602C3.33322 3.52834 3.57686 2.91475 4.01432 2.4508C4.45179 1.98684 5.05001 1.70759 5.68658 1.67018L5.83325 1.66602H9.99992Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M15.8333 5.83268H12.4999L12.4991 2.49852L15.8333 5.83268Z"
                    fill="#ffffff"
                  />
                </svg>
              </Button>
              <input
                type="file"
                id="file"
                accept="text/plain,.pdf,.docx,.xlsx,.pptx"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              <span className="ml-2">{fileName}</span>
              <p className="mt-2 text-gray-500 font-normal text-sm">
                128MBまでのpdfまたはdocsファイルをアップロード可能
                <br />※ アップロードされたファイルはparks上には保存されません。
              </p>
            </div>
          </div>

          <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
            <Button
              className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
              onClick={closeModal}
            >
              キャンセル
            </Button>

            <Button
              className="px-6 py-3 flex justify-center items-center btn-primary-base"
              onClick={extract}
              disabled={isFileNameEmpty}
            >
              キューブを抽出する
            </Button>
          </div>
        </div>
      </div>
    )
  }

  // キューブ抽出中
  const createCubeLoadingModal = (cancelButtonDisabled: boolean) => {
    return (
      <div
        className="extracting-cube__content modal__content"
        role="dialog"
        aria-modal="true"
        aria-labelledby="extracting-cube__title"
      >
        <div className="flex justify-between items-center">
          <h1
            className="font-bold text-2xl md:text-3xl"
            id="extracting-cube__title"
          >
            キューブを自動抽出
          </h1>

          <Button className="col-start-3 justify-self-end" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
        </div>

        {/*
          <div>
            <p className="extracting-cube__text">キューブを抽出しています...</p>
            <div className="extracting-cube__circle">
              <svg>
                <circle className="base" cx="62" cy="62" r="58"></circle>
                <circle className="line" cx="62" cy="62" r="60"></circle>
              </svg>
              <div className="extracting-cube__percencubee-inner">
                <h3 className="extracting-cube__percencubee">
                  <span>75</span>%
                </h3>
              </div>
            </div>
          </div>
          */}
        <Loading className="loading" type="spin" color="#007559" />
        {!cancelButtonDisabled && (
          <div className="flex justify-center mt-10">
            <Button
              className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
              onClick={closeModal}
            >
              キャンセル
            </Button>
          </div>
        )}
      </div>
    )
  }

  // キューブ抽出完了
  const createCubeExtractedModal = () => {
    // キューブをチェックしたときの処理
    const checkCube = (cube: CubeQuery) => {
      if (checkedCubes.some(t => t.cubeName === cube.cubeName)) {
        const newCheckedCubes = checkedCubes.filter(
          t => t.cubeName !== cube.cubeName,
        )
        setCheckedCubes(newCheckedCubes)
      } else {
        setCheckedCubes([...checkedCubes, cube])
      }
    }

    // キューブ登録
    const createCubes = () => {
      if (checkedCubes.length === 0) return
      setCubeExtractStatus('creating')
      const memberCubeForms = checkedCubes.map(cube => {
        return {
          memberId: memberId,
          cubeName: cube.cubeName,
          cubeCategoryId: 3, // パーソナリティ固定
        } as MemberCubeForm
      })
      MemberCubeControllerService.createMemberCubes({
        memberCubes: memberCubeForms,
      } as MemberCubesForm)
        .then(() => {
          closeModal()
          toast.success('キューブを登録しました')
        })
        .catch(err => {
          toast.error('登録に失敗しました')
          apiErrorHandler(err)
        })
    }

    return (
      <div
        className="fixed bottom-0 top-[5%] w-full left-1/2 transform -translate-x-1/2 md:w-[590px] max-w-full overflow-hidden rounded-xl bg-white flex flex-col justify-start p-6 md:rounded-t-xl md:top-1/2 md:bottom-auto md:flex md:transform md:-translate-x-1/2 md:-translate-y-1/2"
        role="dialog"
        aria-modal="true"
        aria-labelledby="extract-result__title"
      >
        <div className="flex justify-between items-center">
          <h1
            className="font-bold text-2xl md:text-3xl"
            id="extract-result__title"
          >
            キューブを自動抽出
          </h1>

          <Button onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
              className="w-full h-auto object-cover"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
        </div>

        <p className="mt-10 text-gray-500 text-sm text-left">
          抽出結果からキューブを選択してください。選択されたキューブはパーソナリティのカテゴリーに追加されます
        </p>
        <div className="mt-6 mb-16 -ml-2 overflow-y-scroll hidden-scrollbar text-sm text-left md:max-h-[266px] md:mt-10">
          {extractedCubes.map((cube, index) => (
            <p
              className={`relative mt-2.5 pl-10 ${index === 0 ? 'mt-0' : ''}`}
              key={index}
            >
              <input
                type="checkbox"
                id={`management-${index}`}
                name={`management-${index}`}
                className="absolute opacity-0"
                checked={checkedCubes.some(
                  target => target.cubeName === cube.cubeName,
                )}
                onChange={() => checkCube(cube)}
              />
              <label
                htmlFor={`management-${index}`}
                className="inline-block p-1.5 pl-3 pr-3 border border-sky-200 rounded-lg bg-[#e8f1fe] font-bold cursor-pointer relative leading-[23px]"
              >
                <span
                  className={`absolute top-1.5 -left-9 w-[18px] h-[18px] border border-gray-200 rounded flex justify-center items-center ${
                    checkedCubes.some(
                      target => target.cubeName === cube.cubeName,
                    )
                      ? 'bg-green'
                      : 'bg-white'
                  }`}
                >
                  {checkedCubes.some(
                    target => target.cubeName === cube.cubeName,
                  ) && (
                    <svg
                      className="w-3.5 h-2.5"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5L5 9L13 1"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </span>
                {cube.cubeName}
              </label>
            </p>
          ))}
        </div>

        <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
          <Button
            className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
            onClick={closeModal}
          >
            キャンセル
          </Button>
          <Button
            className="px-6 py-3 flex justify-center items-center btn-primary-base"
            onClick={createCubes}
          >
            キューブを追加する
          </Button>
        </div>
      </div>
    )
  }

  switch (cubeExtractStatus) {
    case 'extracting':
      return createCubeLoadingModal(false)
    case 'extracted':
      return createCubeExtractedModal()
    case 'creating':
      return createCubeLoadingModal(true)
    default:
      return createCubeExtractModal()
  }
}
