import React from 'react'
import { Button } from '.'

interface BtnWrapperProps {
  displayType: string
  firstButton?: string
  secondButton?: string
  thirdButton?: string
  onCategoryClick: React.MouseEventHandler<HTMLButtonElement>
  onAddClick: React.MouseEventHandler<HTMLButtonElement>
  onExtractClick: React.MouseEventHandler<HTMLButtonElement>
}

const BtnWrapper: React.FC<BtnWrapperProps> = ({
  displayType,
  onCategoryClick,
  onAddClick,
  onExtractClick,
  firstButton = 'カテゴリーを追加',
  secondButton = 'キューブを追加',
  thirdButton = 'キューブを自動抽出',
}) => {
  return (
    <div className={`profile-page__lead__btn-wrapper ${displayType}`}>
      <Button
        className="btn-base btn-outline-base w-full px-5 py-3 md:w-fit"
        onClick={onCategoryClick}
      >
        {firstButton}
      </Button>
      <Button
        className="btn-primary-base w-full px-5 py-3 text-center transition-opacity duration-300 ease-out hover:opacity-60 md:w-fit"
        onClick={onAddClick}
      >
        {secondButton}
      </Button>
      <Button
        className="btn-base btn-outline-base w-full px-5 py-3 md:w-fit"
        onClick={onExtractClick}
      >
        {thirdButton}
      </Button>
    </div>
  )
}

export default BtnWrapper
