import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

export const PrivacyPolicy: FC = () => {
  return (
    <main>
      <Helmet>
        <title>プライバシーポリシー</title>
        <meta property="og:title" content="プライバシーポリシー" />
      </Helmet>
      <h1>
        <a href="/">parks</a>
      </h1>
      <div>
        <h2>プライバシーポリシー</h2>
        <p>
          株式会社Rond（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
        </p>
        <p>
          <br />
        </p>
        <h3>第1条（個人情報）</h3>
        <p>
          <br />
        </p>
        <p>
          「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
        </p>
        <p>
          <br />
        </p>
        <h3>第2条（個人情報の収集方法）</h3>
        <p>
          <br />
        </p>
        <p>
          当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
        </p>
        <p>
          <br />
        </p>
        <h3>第3条（個人情報を収集・利用する目的）</h3>
        <p>
          <br />
        </p>
        <p>当社が個人情報を収集・利用する目的は，以下のとおりです。</p>
        <p>
          <br />
        </p>
        <p>
          当社サービスの提供・運営のためユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するためメンテナンス，重要なお知らせなど必要に応じたご連絡のため利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするためユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため有料サービスにおいて，ユーザーに利用料金を請求するため上記の利用目的に付随する目的
        </p>
        <p>
          <br />
        </p>
        <h3>第4条（利用目的の変更）</h3>
        <p>
          <br />
        </p>
        <p>
          当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
        </p>
        <p>
          <br />
        </p>
        <h3>第5条（個人情報の第三者提供）</h3>
        <p>
          <br />
        </p>
        <p>
          当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        </p>
        <p>
          <br />
        </p>
        <p>
          1.
          人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
        </p>
        <p>
          <br />
        </p>
        <p>
          2.
          公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
        </p>
        <p>
          <br />
        </p>
        <p>
          3.
          国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        </p>
        <p>
          <br />
        </p>
        <p>
          4.
          予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
        </p>
        <p>
          <br />
        </p>
        <p>1. 利用目的に第三者への提供を含むこと</p>
        <p>2. 第三者に提供されるデータの項目</p>
        <p>3. 第三者への提供の手段または方法</p>
        <p>4. 本人の求めに応じて個人情報の第三者への提供を停止すること</p>
        <p>
          5.
          本人の求めを受け付ける方法前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
        </p>
        <p>
          <br />
        </p>
        <p>
          1.
          当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
        </p>
        <p>
          2. 合併その他の事由による事業の承継に伴って個人情報が提供される場合
        </p>
        <p>
          3.
          個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
        </p>
        <p>
          <br />
        </p>
        <h3>第6条（個人情報の開示）</h3>
        <p>
          <br />
        </p>
        <p>
          当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1,000円の手数料を申し受けます。
        </p>
        <p>
          <br />
        </p>
        <p>
          1.
          本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
        </p>
        <p>2. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
        <p>
          3.
          その他法令に違反することとなる場合前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
        </p>
        <p>
          <br />
        </p>
        <h3>第7条（個人情報の訂正および削除）</h3>
        <p>
          <br />
        </p>
        <p>
          ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
        </p>
        <p>
          <br />
        </p>
        <h3>第8条（個人情報の利用停止等）</h3>
        <p>
          <br />
        </p>
        <p>
          当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
        </p>
        <p>
          <br />
        </p>
        <h3>第9条（プライバシーポリシーの変更）</h3>
        <p>
          <br />
        </p>
        <p>
          本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
        </p>
        <p>
          <br />
        </p>
        <h3>第10条（お問い合わせ窓口）</h3>
        <p>
          <br />
        </p>
        <p>
          本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
        </p>
        <p>
          <br />
        </p>
        <p>
          住所：〒107-0062 東京都港区南青山 2丁目2番15号Win AoyamaビルUCF6階
        </p>
        <p>社名：株式会社Rond</p>
        <p>
          Eメールアドレス：
          <a data-fr-linked="true" href="mailto:info@rond.co.jp">
            info@rond.co.jp
          </a>
        </p>
        <p>
          <br />
        </p>
        <h3>第11条（Googleカレンダーへのアクセスについて）</h3>
        <p>
          <br />
        </p>
        <p>
          当社サービスではユーザーのGoogleカレンダーと連携し、予定を取得・登録する事ができます。
          <br />
          目的としては、当社サービス上で他者とミーティングをスケジュールする際に、お互いの予定が空いている箇所を認識するためです。
          <br />
          また、お互いの予定が空いている箇所へのミーティング予定の登録も可能になります。
          <br />
        </p>
        <br />
        <p>
          当社サービスは、上記目的以外で、ユーザーが利用するGoogleカレンダーへアクセスすることはありません。
          <br />
        </p>
        <br />
        <p>
          当社サービス「parks」は、Google APIから取得した情報の使用に関して「
          <NavLink
            to={
              'https://developers.google.com/terms/api-services-user-data-policy?hl=ja'
            }
            target="_blank"
          >
            Google API サービスのユーザーデータに関するポリシー
          </NavLink>
          」に準拠しております。
          <br />
        </p>
        <br />
        <p>
          ※Google、Googleカレンダーは、Google Inc. の商標または登録商標です。
          <br />
        </p>
        <p>
          <br />
        </p>
        <h3>第12条（Outlookの予定表へのアクセスについて）</h3>
        <p>
          <br />
        </p>
        <p>
          当社サービスではユーザーのOutlookの予定表と連携し、予定を取得・登録する事ができます。
          <br />
          目的としては、当社サービス上で他者とミーティングをスケジュールする際に、お互いの予定が空いている箇所を認識するためです。
          <br />
          また、お互いの予定が空いている箇所へのミーティング予定の登録も可能になります。
          <br />
        </p>
        <br />
        <p>
          当社サービスは、上記目的以外で、ユーザーが利用するOutlookの予定表へアクセスすることはありません。
          <br />
        </p>
        <br />
        <h3>第13条（Firebaseの利用について）</h3>
        <p>
          <br />
        </p>
        <p>
          本サービスは、提供するサービスの向上および適切な機能を提供するために、Google
          Inc.が提供するFirebaseを利用しています。
          <br />
          Firebaseを通じて収集される情報は、本サービスの機能改善やユーザーエクスペリエンスの最適化のために使用されます。
          <br />
          収集する情報には、デバイス情報、アプリケーションの使用状況、クラッシュレポートなどが含まれます。
          <br />
          これらの情報は匿名化されており、特定のユーザーを直接識別するものではありません。
          <br />
        </p>
        <br />
        <p>
          上記で収集した情報は、サービスの品質向上やユーザーの利便性向上、またはマーケティング活動のために分析・利用されることがあります。
          <br />
        </p>
        <br />
        <p>
          本サービスは、Firebaseを利用することにより、収集された情報をGoogle
          Inc.と共有します。ただし、これらの情報はGoogle Inc.の
          <NavLink
            to={'https://www.google.com/policies/privacy/'}
            target="_blank"
          >
            プライバシーポリシー
          </NavLink>
          に基づいて管理されます。
          <br />
        </p>
        <br />
        <p>
          ユーザーは、本サービスの設定やデバイスの設定からFirebaseによる情報収集をオプトアウトすることができます。
          <br />
          オプトアウトする方法についての詳細は、本サービスのサポートページまたは公式ドキュメントをご参照ください。
          <br />
        </p>
        <br />
        <p>
          Firebaseに関する詳細や、Firebaseが提供するサービスに関する情報は、Google
          Inc.の公式ウェブサイトまたは関連ドキュメントをご参照ください。
          <br />
        </p>
        <br />
        <p>
          ※Firebaseは、Google Inc. の商標または登録商標です。
          <br />
        </p>
        <p>
          <br />
        </p>

        <p>以上</p>
        <p>
          <br />
        </p>
      </div>
    </main>
  )
}

export default PrivacyPolicy
