import { useNavigate } from 'react-router-dom'
import { Button } from '.'
import { useChatRoom } from '../../hooks/useChatRoom'

type ChatButtonProps = {
  className: string
  memberId: number
}

const ChatButton: React.FC<ChatButtonProps> = ({ className, memberId }) => {
  const navigate = useNavigate()
  const { addChatRoom } = useChatRoom()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    addChatRoom([memberId]).then(chatRoomId => {
      navigate(`/chat-detail?chatRoomId=${chatRoomId}`)
    })
  }

  return (
    <Button
      className={className}
      imageUrl="/images/icon_chat_plus.svg"
      onClick={handleClick}
    >
      チャットを送る
    </Button>
  )
}

export default ChatButton
