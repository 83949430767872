import React, { FC, useState } from 'react'
import {
  AdminControllerService,
  AdminMember,
  MemberRoleMap,
  MemberRoleQuery,
  MemberUpdateForm,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'
import { toast } from 'react-toastify'
import { Button } from '../../components/button'

interface Props {
  adminMember: AdminMember
  memberRoleMaps: MemberRoleMap[]
  disabled?: boolean
  closeModal: () => void
}

export const AdminProfileEditModal: FC<Props> = ({
  adminMember,
  memberRoleMaps,
  disabled,
  closeModal,
}) => {
  const [memberName, setUserName] = useState(adminMember.memberName)
  const [email, setEmail] = useState(adminMember.email)
  const [memberRole, setMemberRole] = useState(adminMember.memberRole)
  const [isLoading, setIsLoading] = useState(false)

  const update = () => {
    // 初期表示と同じ場合は何もしない
    if (
      memberName === adminMember.memberName &&
      email === adminMember.email &&
      memberRole === adminMember.memberRole
    ) {
      return
    }
    // バリデーション
    if (!validate()) return

    setIsLoading(true)
    AdminControllerService.updateMemberFromAdmin(adminMember.memberId, {
      memberName,
      email,
      memberRole,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
        toast.success('メンバー情報を更新しました')
      })
      .catch(err => {
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  const validate = () => {
    if (memberName === '') {
      toast.error('名前を入力してください')
      return false
    }
    if (email === '') {
      toast.error('メールアドレスを入力してください')
      return false
    }
    if (
      !/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
        email,
      )
    ) {
      toast.error('メールアドレスの形式が正しくありません')
      return false
    }
    return true
  }

  return (
    <div className="profile-edit__content">
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <>
          <div className="profile-edit__head">
            <div className="profile-edit__img-admin">
              <img
                src={
                  adminMember.profileImageUrl
                    ? adminMember.profileImageUrl
                    : '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
              />
            </div>
            <Button
              className="profile-edit-page_icon_clear modal-close"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                aria-label="Close modal"
              >
                <rect width="24" height="24" rx="12" fill="#EDF0F7" />
                <path
                  d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                  fill="#8A8F9F"
                />
              </svg>
            </Button>
          </div>

          <div className="profile-edit__body">
            <dl className="profile-edit__list">
              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">名前</dt>
                <dd className="profile-edit__desc">
                  <input
                    type="text"
                    placeholder="名前を入力してください"
                    value={memberName}
                    disabled={disabled}
                    onChange={e => setUserName(e.target.value)}
                  />
                </dd>
              </div>
              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">メールアドレス</dt>
                <dd className="profile-edit__desc">
                  <input
                    type="text"
                    placeholder="メールアドレスを入力してください"
                    value={email}
                    disabled={disabled}
                    onChange={e => setEmail(e.target.value)}
                  ></input>
                </dd>
              </div>
              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">ロール</dt>
                <dd className="profile-edit__desc">
                  <select
                    value={memberRole}
                    onChange={e =>
                      setMemberRole(
                        e.target.value as MemberRoleQuery.memberRole,
                      )
                    }
                    disabled={disabled}
                  >
                    {memberRoleMaps.map((memberRoleMap, index) => (
                      <option key={index} value={memberRoleMap.name}>
                        {memberRoleMap.label}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
            </dl>
            <div className="modal-edit-btn__wrapper">
              <Button
                className="btn_secondary-l btn_modal-edit_cancel"
                onClick={closeModal}
              >
                {disabled ? '閉じる' : 'キャンセル'}
              </Button>
              {!disabled && (
                <Button className="btn btn_modal-edit_save" onClick={update}>
                  保存
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
