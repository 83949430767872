/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MemberRoleQuery = {
    memberRole: MemberRoleQuery.memberRole;
};

export namespace MemberRoleQuery {

    export enum memberRole {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        MEMBER = 'MEMBER',
    }


}

