/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnouncementCreateForm } from '../models/AnnouncementCreateForm';
import type { CreateWorkspaceInvitationForm } from '../models/CreateWorkspaceInvitationForm';
import type { CubeHistoryQuery } from '../models/CubeHistoryQuery';
import type { PositionQuery } from '../models/PositionQuery';
import type { QuestionsCreateForm } from '../models/QuestionsCreateForm';
import type { RankingQuery } from '../models/RankingQuery';
import type { WordCloudQuery } from '../models/WordCloudQuery';
import type { WorkspaceAddForm } from '../models/WorkspaceAddForm';
import type { WorkspaceDeleteForm } from '../models/WorkspaceDeleteForm';
import type { WorkspaceDto } from '../models/WorkspaceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeveloperControllerService {

    /**
     * @returns WorkspaceDto OK
     * @throws ApiError
     */
    public static getAllWorkspaces(): CancelablePromise<Array<WorkspaceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/developer/workspaces',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createWorkspace(
        requestBody: WorkspaceAddForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/developer/workspaces',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static deleteWorkspace(
        requestBody: WorkspaceDeleteForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/developer/workspaces',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param workspaceId
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static createSpecifiedWorkspaceInvitationUrl(
        workspaceId: string,
        requestBody: CreateWorkspaceInvitationForm,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/developer/workspace/{workspaceId}/invitation-url',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createQuestions(
        requestBody: QuestionsCreateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/developer/questions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param workspaceId
     * @param formData
     * @returns string OK
     * @throws ApiError
     */
    public static createMembersFromTsv(
        workspaceId: string,
        formData?: {
            file: Blob;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/developer/members/{workspaceId}',
            path: {
                'workspaceId': workspaceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param workspaceId
     * @param formData
     * @returns string OK
     * @throws ApiError
     */
    public static sendEmail(
        workspaceId: string,
        formData?: {
            file: Blob;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/developer/members/{workspaceId}/email',
            path: {
                'workspaceId': workspaceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createAnnouncements(
        requestBody: AnnouncementCreateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/developer/announcements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param rankingType
     * @param workspaceId
     * @returns RankingQuery OK
     * @throws ApiError
     */
    public static getDeveloperRanking(
        rankingType: 'MOST_GIFTED_CUBES' | 'MOST_GIFTED_MEMBERS' | 'MOST_GIFT_MEMBERS_OWN' | 'MOST_GIFT_MEMBERS_OTHER',
        workspaceId?: string,
    ): CancelablePromise<Array<RankingQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/developer/ranking',
            query: {
                'rankingType': rankingType,
                'workspaceId': workspaceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param workspaceId
     * @returns WordCloudQuery OK
     * @throws ApiError
     */
    public static getDeveloperWordCloud(
        workspaceId?: string,
    ): CancelablePromise<Array<WordCloudQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/developer/ranking/word-cloud',
            query: {
                'workspaceId': workspaceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param workspaceId
     * @returns CubeHistoryQuery OK
     * @throws ApiError
     */
    public static getDeveloperCubeHistory(
        workspaceId?: string,
    ): CancelablePromise<Array<CubeHistoryQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/developer/ranking/cube-history',
            query: {
                'workspaceId': workspaceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param workspaceId
     * @returns PositionQuery OK
     * @throws ApiError
     */
    public static getDeveloperPositions(
        workspaceId?: string,
    ): CancelablePromise<Array<PositionQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/developer/positions',
            query: {
                'workspaceId': workspaceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
