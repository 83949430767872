import React, { FC } from 'react'

interface Props {
  label: string
  placeholder: string
  value?: string
  textarea?: boolean
  maxLength: number
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | ((event: React.ChangeEvent<HTMLTextAreaElement>) => void)
  error?: string | null | undefined
}

export const TextInput: FC<Props> = ({
  label,
  placeholder,
  value,
  textarea = false,
  maxLength,
  onChange,
  error,
}) => {
  return (
    <>
      <label className="form__label">
        {label}
        {textarea ? (
          <textarea
            placeholder={placeholder}
            value={value ? value : ''}
            onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement>}
            maxLength={maxLength}
            className="form__textarea"
            disabled={onChange === undefined || onChange === null}
          />
        ) : (
          <input
            type="text"
            placeholder={placeholder}
            value={value ? value : ''}
            onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
            maxLength={maxLength}
            disabled={onChange === undefined || onChange === null}
          />
        )}
      </label>
      {error && <div className="form__error_message">{error}</div>}
    </>
  )
}
