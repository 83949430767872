/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateMemberForm = {
    memberName?: string;
    email?: string;
    memberRole?: UpdateMemberForm.memberRole;
    deleteFlg?: boolean;
};

export namespace UpdateMemberForm {

    export enum memberRole {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        MEMBER = 'MEMBER',
    }


}

