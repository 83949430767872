import { FC } from 'react'
import { Button } from '../../components/button'

interface Props {
  closeModal: () => void
}
export const AboutCubeModal: FC<Props> = ({ closeModal }) => {
  return (
    <div
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[80%] w-[590px] max-h-screen overflow-y-scroll bg-white text-center rounded-lg p-6 pb-10 hidden-scrollbar"
      role="dialog"
      aria-modal="true"
      aria-labelledby="delete-talk__title"
    >
      <div className="flex justify-between items-center">
        <h1
          className="w-full text-lg text-center font-bold"
          id="delete-talk__title"
        >
          キューブとは？
        </h1>
      </div>

      <div>
        <img
          className="block mx-auto mt-4"
          src="/images/about-cube-modal_img.png"
          alt="キューブの説明画像"
          width={272}
          height={151}
        />
        <p className="mt-6 pb-10 text-sm text-left">
          parksでは一人ひとりの個性のかけらを「キューブ」と呼んでいます。
          <br />
          あなたの興味・関心、スキル・専門知、パーソナリティをキューブとして登録しましょう。
        </p>
      </div>

      <div className="flex justify-center absolute right-0 bottom-0 left-0 mb-5">
        <Button
          className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
          onClick={closeModal}
        >
          閉じる
        </Button>
      </div>
    </div>
  )
}
