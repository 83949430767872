import { FC } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { Footer } from '../../layouts/footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/button'

export const NotFoundPage: FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main main__complete">
        <div className="form__complete">
          <h1 className="pagetitle">お探しのページが見つかりません。</h1>
          <p className="complete__text">
            アクセスしたページは削除された可能性があり、
            <br />
            表示することができません。
          </p>
          <div className="form">
            <p className="form__submit">
              <Button
                className="btn btn--sp-wide"
                onClick={() => {
                  navigate(`/`)
                }}
              >
                ホームへ
              </Button>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
