import { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { useModal } from '../../contexts/modalContext'
import { AddChatModal } from '../../modals/addChatModal'
import { ChatRoomControllerService, ChatRoomQuery } from '../../services'
import { useRecoilValue } from 'recoil'
import { loggedInMemberState } from '../../lib/atoms'
import { HeaderType } from '../../enums/HeaderType'
import env from '../../config/env'
import { Button } from '../../components/button'
import { ChatListItem } from '../../components/chat/ChatListItem'

export const ChatList: FC = () => {
  const { openModal } = useModal()
  const [chatRooms, setChatRooms] = useState<ChatRoomQuery[]>([])
  const loggedInMember = useRecoilValue(loggedInMemberState)

  useEffect(() => {
    const fetchInitialChatRooms = async () => {
      const res = await ChatRoomControllerService.getChatRooms()
      setChatRooms(res)
      // mockの場合は表示数を増やす
      if (env.ENV === 'mock') {
        const chatRoom = res[0]
        const chatRoom1 = {
          ...chatRoom,
          attendees: [...chatRoom.attendees, ...chatRoom.attendees],
        }
        const chatRoom2 = {
          ...chatRoom,
          attendees: [
            ...chatRoom.attendees,
            ...chatRoom.attendees,
            ...chatRoom.attendees,
          ],
        }
        setChatRooms([chatRoom1, chatRoom2])
      }
    }
    fetchInitialChatRooms()
    const intervalId = setInterval(fetchInitialChatRooms, 3000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.SIMPLE_WITHOUT_SEARCH} />
      <SideNavi />
      <main className="main-sidenav">
        <ul className="chat__memberList">
          {chatRooms.length === 0 && (
            <li className="chat__memberList__item chat__memberList__no-item">
              <p>チャットがありません</p>
            </li>
          )}
          {chatRooms.map(chatRoom => (
            <ChatListItem
              key={chatRoom.chatRoomId}
              chatRoom={chatRoom}
              loggedInMember={loggedInMember}
            />
          ))}
        </ul>
        <Button
          className="chat__sticky-button btn_secondary-l"
          onClick={() => openModal(AddChatModal)}
        >
          <img src="/images/icon_chat_plus.svg" />
          チャットを作る
        </Button>
      </main>
    </>
  )
}
