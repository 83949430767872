import { useDraggable } from '@dnd-kit/core'
import React, { FC, ReactNode, useEffect, useState } from 'react'
type Props = {
  id: number
  children: ReactNode
  moved: boolean
}
export const Draggable: FC<Props> = ({ id, children, moved }) => {
  const { setNodeRef, listeners, attributes, transform, isDragging } =
    useDraggable({ id })
  const [shouldBlink, setShouldBlink] = useState(false)

  useEffect(() => {
    if (moved) {
      setShouldBlink(true)
      setTimeout(() => setShouldBlink(false), 3000)
    }
  }, [moved])

  const transformStyle = transform
    ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
    : undefined
  const classNames = `draggable ${shouldBlink ? 'blink' : ''}`
  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={{
        transform: transformStyle,
        height: 'fit-content',
        touchAction: 'none',
        zIndex: isDragging ? 1 : 0,
      }}
      className={classNames}
    >
      {children}
    </div>
  )
}
