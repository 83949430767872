/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PositionQuery } from '../models/PositionQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PositionControllerService {

    /**
     * @returns PositionQuery OK
     * @throws ApiError
     */
    public static getPositions(): CancelablePromise<Array<PositionQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/positions',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param keyword
     * @returns PositionQuery OK
     * @throws ApiError
     */
    public static suggestPositions(
        keyword?: string,
    ): CancelablePromise<Array<PositionQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/positions/suggest',
            query: {
                'keyword': keyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
