/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminMember = {
    memberId: number;
    memberName: string;
    email: string;
    profileImageUrl?: string;
    memberRole: AdminMember.memberRole;
    deleteFlg: boolean;
    loginFlg: boolean;
};

export namespace AdminMember {

    export enum memberRole {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        MEMBER = 'MEMBER',
    }


}

