/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CubeHistoryQuery } from '../models/CubeHistoryQuery';
import type { RankingQuery } from '../models/RankingQuery';
import type { WordCloudQuery } from '../models/WordCloudQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RankingControllerService {

    /**
     * @param rankingType
     * @returns RankingQuery OK
     * @throws ApiError
     */
    public static getRanking(
        rankingType: 'MOST_GIFTED_CUBES' | 'MOST_GIFTED_MEMBERS' | 'MOST_GIFT_MEMBERS_OWN' | 'MOST_GIFT_MEMBERS_OTHER',
    ): CancelablePromise<Array<RankingQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ranking',
            query: {
                'rankingType': rankingType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns WordCloudQuery OK
     * @throws ApiError
     */
    public static getWordCloud(): CancelablePromise<Array<WordCloudQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ranking/word-cloud',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns CubeHistoryQuery OK
     * @throws ApiError
     */
    public static getCubeHistory(): CancelablePromise<Array<CubeHistoryQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ranking/cube-history',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
