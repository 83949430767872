import React, { FC } from 'react'
import { CubeHistoryQuery, MemberRoleQuery } from '../../services'
import CubeSummaryTable from './CubeSummaryTable'
import { useNavigate } from 'react-router-dom'

interface CubeHistorySectionProps {
  headContents: string[]
  cubeHistoryQueries: CubeHistoryQuery[]
  filterDate: string
  setFilterDate: React.Dispatch<React.SetStateAction<string>>
  memberRole: MemberRoleQuery
}

export const CubeHistorySection: FC<CubeHistorySectionProps> = ({
  headContents,
  cubeHistoryQueries,
  filterDate,
  setFilterDate,
  memberRole,
}) => {
  const navigate = useNavigate()

  const handleFilterDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterDate(e.target.value)
    navigate('?page=1') // ページ番号をリセット
  }

  return (
    <div className="ranking_page__content">
      <h3 className="ranking-page__lead">
        <div>キューブ履歴</div>
        <div className="ranking-page_sort-text">
          <span className="sort-icon" />
          <select onChange={handleFilterDateChange}>
            <option value="">全期間</option>
            {cubeHistoryQueries
              .map(cubeHistory => {
                const date = new Date(cubeHistory.createdAt)
                const year = date.getFullYear()
                const month = String(date.getMonth() + 1).padStart(2, '0')
                return { year, month }
              })
              .filter(
                (date, index, self) =>
                  self.findIndex(
                    d => d.year === date.year && d.month === date.month,
                  ) === index,
              )
              .sort((a, b) => {
                if (a.year < b.year) return 1
                if (a.year > b.year) return -1
                if (a.month < b.month) return 1
                if (a.month > b.month) return -1
                return 0
              })
              .map((date, index) => (
                <option value={`${date.year}-${date.month}`} key={index}>
                  {`${date.year}年${date.month}月`}
                </option>
              ))}
          </select>
          <div className="arrow-down-icon-wrapper">
            <span className="arrow-down-icon" />
          </div>
        </div>
      </h3>
      <CubeSummaryTable
        headContents={headContents}
        cubeHistoryQueries={cubeHistoryQueries.filter(cubeHistoryQuery => {
          const date = new Date(cubeHistoryQuery.createdAt)
          const year = date.getFullYear()
          const month = String(date.getMonth() + 1).padStart(2, '0')
          return filterDate === '' || `${year}-${month}` === filterDate
        })}
        filterDate={filterDate}
        memberRole={memberRole}
      />
    </div>
  )
}
