import { initializeApp } from 'firebase/app'
import { OpenAPI } from './services'
import env from './config/env'

const init = () => {
  OpenAPI.BASE = env.BACKEND_BASE_URL
  OpenAPI.WITH_CREDENTIALS = true

  // APIキーが設定されている場合はFirebaseを初期化する
  if (env.FIREBASE_API_KEY) {
    const firebaseConfig = {
      apiKey: env.FIREBASE_API_KEY,
      authDomain: 'parks-5b0f7.firebaseapp.com',
      projectId: 'parks-5b0f7',
      storageBucket: 'parks-5b0f7.appspot.com',
      messagingSenderId: '1069301655144',
      appId: '1:1069301655144:web:a142b4357b95e5aa23fddd',
      measurementId: 'G-J1ZE6QTL4G',
    }
    initializeApp(firebaseConfig)
  }
}

export default init
