import { FC, useState, useEffect } from 'react'
import { Button } from '../../components/button'
import { QuestionControllerService } from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
interface Props {
  closeModal: () => void
  setIsLoading: (isLoading: boolean) => void
}

export const QuestionForm: FC<Props> = ({ closeModal, setIsLoading }) => {
  // 質問内容入力
  const [questionValue, setQuestionValue] = useState('')
  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setQuestionValue(event.target.value)
  }

  // 回答の選択肢入力
  const [answerValues, setAnswerValues] = useState<string[]>(['', ''])
  const handleAnswerChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValues = [...answerValues]
    newValues[index] = event.target.value // 該当インデックスの値を更新
    setAnswerValues(newValues)
  }

  // 回答の選択肢追加
  const addAnswer = () => {
    if (answerValues.length < 4) {
      setAnswerValues([...answerValues, ''])
    }
  }

  // 匿名で質問するチェックボックス
  const [isAnonymous, setIsAnonymous] = useState(false)
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnonymous(event.target.checked)
  }

  // 入力欄が入力済みか
  const [isEmptyForm, setIsEmptyForm] = useState(false)

  useEffect(() => {
    // 質問内容と選択肢(2件以上)が入力済みかを確認
    const hasTwoOrMoreAnswers =
      answerValues.filter(value => value.trim() !== '').length >= 2
    const isEmpty = questionValue.trim() === '' || !hasTwoOrMoreAnswers
    setIsEmptyForm(isEmpty)
  }, [questionValue, answerValues])

  const createQuestion = () => {
    const questionForm = {
      question: questionValue,
      choices: answerValues.filter(answer => answer !== ''),
      anonymousFlg: isAnonymous,
    }
    setIsLoading(true)

    QuestionControllerService.createQuestion(questionForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <>
      <div className="mt-5 mb-24 flex flex-col items-start gap-5 max-h-full md:max-h-[500px]">
        <div className='grid gap-[22px] w-full'>
        <p className="w-full">
          <label className="font-bold text-sm leading-normal block text-left">
            質問内容
            <textarea
              className="resize-none h-24 py-3 px-4 block w-full mt-1 rounded-xl bg-neutral-paleGray font-normal text-base"
              name="description"
              placeholder="例）犬と猫どちらが好きですか？"
              value={questionValue}
              onChange={handleQuestionChange}
              maxLength={140}
            ></textarea>
          </label>
        </p>
        {answerValues.map((value, index) => (
          <div key={index} className="w-full mt-0">
            <label className="font-bold text-sm leading-normal block text-left">
              選択肢{index + 1}
              <input
                className="py-3 px-4 block w-full mt-1 rounded-xl bg-neutral-paleGray font-normal text-base"
                type="text"
                value={value}
                onChange={event => handleAnswerChange(index, event)}
                placeholder="選択肢を入力してください"
                maxLength={25}
              />
            </label>
          </div>
        ))}
        </div>
        {answerValues.length < 4 && (
          <Button
            className="bg-white text-green border border-green m-0 rounded-3xl block text-sm font-bold text-base min-w-8.125 py-3 px-6 text-center hover:cursor-pointer"
            imageUrl="/images/icon_add.svg"
            onClick={addAnswer}
          >
            選択肢を追加
          </Button>
        )}
        <p className="flex mt-0 ml-1 text-sm">
          <input
            type="checkbox"
            id="anonymous-post"
            name="anonymous-post"
            className="peer"
            checked={isAnonymous}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="anonymous-post" className="label-custom label-before label-checked">匿名で質問する</label>
        </p>
      </div>
      <>
        <div className="flex justify-center right-0 bottom-0 left-0 mb-5 gap-2 md:mb-6 fixed max-w-full">
          <Button className="flex items-center justify-center bg-white text-green border border-green rounded-[40px] text-sm font-bold max-w-full py-3 px-6 text-center hover:cursor-pointer py-[10px] px-[22px] w-[130px]" onClick={closeModal}>
            キャンセル
          </Button>
          <Button
            className="block min-w-32 px-6 py-3 text-center text-white bg-green rounded-full font-bold text-sm m-0 hover:cursor-pointer hover:opacity-60
            disabled:opacity-100 disabled:border disabled:border-[#c9cfd5] disabled:bg-[#c9cfd5] disabled:cursor-not-allowed" 
            disabled={isEmptyForm}
            onClick={createQuestion}
          >
            投稿する
          </Button>
        </div>
      </>
    </>
  )
}
