/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchQuery } from '../models/SearchQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberSearchControllerService {

    /**
     * @param aiFlg
     * @param keyword
     * @param cubeIds
     * @param sort
     * @param page
     * @param limit
     * @returns SearchQuery OK
     * @throws ApiError
     */
    public static searchMembers(
        aiFlg: boolean,
        keyword?: string,
        cubeIds?: Array<number>,
        sort: 'COMMON' | 'NEW' = 'NEW',
        page: number = 1,
        limit: number = 20,
    ): CancelablePromise<SearchQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/members/search',
            query: {
                'keyword': keyword,
                'cubeIds': cubeIds,
                'aiFlg': aiFlg,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
