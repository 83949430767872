/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginMember } from '../models/LoginMember';
import type { MemberQuery } from '../models/MemberQuery';
import type { MemberRoleQuery } from '../models/MemberRoleQuery';
import type { MemberUpdateForm } from '../models/MemberUpdateForm';
import type { SimpleMemberQuery } from '../models/SimpleMemberQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMember(
        requestBody: MemberUpdateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/member',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberId
     * @param sort
     * @param limit
     * @returns MemberQuery OK
     * @throws ApiError
     */
    public static getMembers(
        memberId: number,
        sort: 'COMMON' | 'NEW',
        limit: number = 20,
    ): CancelablePromise<Array<MemberQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/members',
            query: {
                'memberId': memberId,
                'sort': sort,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param keyword
     * @returns SimpleMemberQuery OK
     * @throws ApiError
     */
    public static suggestMembers(
        keyword?: string,
    ): CancelablePromise<Array<SimpleMemberQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/members/suggest',
            query: {
                'keyword': keyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param onboardingType
     * @returns boolean OK
     * @throws ApiError
     */
    public static getOnboarding(
        onboardingType: 'MEMBER_ONBOARDING_COACH_MARK' | 'MEMBER_ONBOARDING_FIRST_TRY' | 'MEMBER_ONBOARDING_FIRST_TRY_AFTER' | 'MEMBER_ONBOARDING_ABOUT',
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/members/onboarding/{onboardingType}',
            path: {
                'onboardingType': onboardingType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param onboardingType
     * @returns any OK
     * @throws ApiError
     */
    public static deleteOnboarding(
        onboardingType: 'MEMBER_ONBOARDING_COACH_MARK' | 'MEMBER_ONBOARDING_FIRST_TRY' | 'MEMBER_ONBOARDING_FIRST_TRY_AFTER' | 'MEMBER_ONBOARDING_ABOUT',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/members/onboarding/{onboardingType}',
            path: {
                'onboardingType': onboardingType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberId
     * @returns MemberQuery OK
     * @throws ApiError
     */
    public static getMember(
        memberId: number,
    ): CancelablePromise<MemberQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member/{memberId}',
            path: {
                'memberId': memberId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns MemberRoleQuery OK
     * @throws ApiError
     */
    public static getMemberRole(): CancelablePromise<MemberRoleQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member/role',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns LoginMember OK
     * @throws ApiError
     */
    public static getLoggedInMember(): CancelablePromise<LoginMember> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member/logged-in-member',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
