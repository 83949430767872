/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MemberEmailSettingForm } from '../models/MemberEmailSettingForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberEmailSettingControllerService {

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getMemberEmailSettings(): CancelablePromise<Array<'RECEIVE_CUBE' | 'RECEIVE_MESSAGE' | 'WEEKLY_REPORT'>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member/setting/email',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMemberEmailSettings(
        requestBody: MemberEmailSettingForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/member/setting/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
