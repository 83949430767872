import { useCallback, useRef } from 'react'

export const useDebounce = (timeout: number) => {
  const timer = useRef<NodeJS.Timeout>()

  return useCallback(
    (fn: () => void) => {
      clearTimeout(timer.current)
      timer.current = setTimeout(fn, timeout)
    },
    [timeout],
  )
}
