/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminMember } from '../models/AdminMember';
import type { CreateMembersForm } from '../models/CreateMembersForm';
import type { CreateWorkspaceInvitationForm } from '../models/CreateWorkspaceInvitationForm';
import type { MemberEntity } from '../models/MemberEntity';
import type { UpdateMemberForm } from '../models/UpdateMemberForm';
import type { UploadResponse } from '../models/UploadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminControllerService {

    /**
     * @param memberId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMemberFromAdmin(
        memberId: number,
        requestBody: UpdateMemberForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/member/{memberId}',
            path: {
                'memberId': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns UploadResponse OK
     * @throws ApiError
     */
    public static sendRecommendEmail(
        formData?: {
            file: Blob;
        },
    ): CancelablePromise<Array<UploadResponse>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/recommend-email',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param cubeId
     * @returns any OK
     * @throws ApiError
     */
    public static sendRecommendEmailFromCube(
        cubeId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/recommend-email-from-cube/{cubeId}',
            path: {
                'cubeId': cubeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns AdminMember OK
     * @throws ApiError
     */
    public static getAdminMembers(): CancelablePromise<Array<AdminMember>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/members',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static createMembers(
        requestBody: CreateMembersForm,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/members',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static createWorkspaceInvitationUrl(
        requestBody: CreateWorkspaceInvitationForm,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/create-workspace-invitation-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param cubeId
     * @returns MemberEntity OK
     * @throws ApiError
     */
    public static getMemberByCubeId(
        cubeId: number,
    ): CancelablePromise<Array<MemberEntity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/members/cube',
            query: {
                'cubeId': cubeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
