import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
  title?: string
  description?: string
}

export const Head: FC<Props> = ({
  title = 'parks（パークス）｜日本初のトランザクティブ・メモリー・システム',
  description = 'parksは組織内の「誰が何を知っているか」を可視化し、 組織運営者の工数をかけずに繋がりを強め、コラボレーションを生み出すツール',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}
