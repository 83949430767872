import React, { FC, useEffect, useState } from 'react'
import Autosuggest, {
  InputProps as AutosuggestInputProps,
  SuggestionSelectedEventData,
} from 'react-autosuggest'
import apiErrorHandler from '../../api/apiErrorHandler'

interface Props {
  className?: string
  placeholder: string
  value: string
  setValue: (value: string) => void
  onSelected: (value: string) => void
  getSuggests: (value: string) => Promise<string[]>
}

const useDebounce = (value: any, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const SuggestTextInput: FC<Props> = ({
  className,
  placeholder,
  value,
  setValue,
  onSelected,
  getSuggests,
}) => {
  // デバウンスした入力値
  const debouncedValue = useDebounce(value, 500)
  // サジェスト候補
  const [suggestions, setSuggestions] = useState([] as string[])
  // サジェスト履歴
  const [suggestionsHistory, setSuggestionsHistory] = useState([] as string[])
  // サジェスト候補を選択したかどうか
  const [suggestionSelected, setSuggestionsSelected] = useState(false)

  useEffect(() => {
    // debouncedValueで入力値を管理して0.5秒入力がなければAPIを叩く（サジェスト候補が選択されたときはスキップ）
    if (!suggestionSelected && debouncedValue) {
      getSuggests(debouncedValue)
        .then(res => {
          // サジェスト候補に入力値を追加
          const resNames = [debouncedValue, ...res]
          // サジェスト候補を更新
          const names = getSuggestions(debouncedValue, resNames)
          setSuggestions(
            getSuggestions(
              debouncedValue,
              getSuggestions(debouncedValue, resNames),
            ),
          )

          // 重複を削除してサジェスト履歴を更新
          const allNames = suggestionsHistory.concat(names)
          const uniqueNames = allNames.filter(
            (obj, index, self) => !self.slice(index + 1).some(o => o === obj),
          )
          setSuggestionsHistory(uniqueNames)
        })
        .catch(err => {
          apiErrorHandler(err)
        })
    } else {
      setSuggestions([])
      setSuggestionsSelected(false)
    }
  }, [debouncedValue])

  const getSuggestions = (inputValue: string, names: string[]): string[] => {
    const inputLength = inputValue.length
    const inputValueLower = inputValue.toLowerCase()
    const inputKatakana = hiraganaToKatakana(inputValue)
    return inputLength === 0
      ? []
      : names.filter(
          name =>
            name.toLowerCase().slice(0, inputLength) === inputValueLower || // アルファベットの前方一致
            name.indexOf(inputValue) != -1 || // かなの部分一致
            name.indexOf(inputKatakana) != -1, // カナの部分一致
        )
  }

  const hiraganaToKatakana = (str: string): string => {
    let result = ''
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i)
      if (charCode >= 12353 && charCode <= 12435) {
        result += String.fromCharCode(charCode + 96)
      } else {
        result += str[i]
      }
    }
    return result
  }

  // デバウンスを利用するために独自で実装しているので利用しない
  const onSuggestionsFetchRequested = () => {
    return
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  // フォーカスが外れたときの処理
  const onBlur = () => {
    setValue('')
  }

  // サジェスト候補を選択したときの処理
  const onSuggestionSelected = (
    _event: React.FormEvent<HTMLInputElement>,
    { suggestion }: SuggestionSelectedEventData<string>,
  ) => {
    setSuggestionsSelected(true)
    onSelected(suggestion)
    setValue('')
  }

  const onChange: AutosuggestInputProps<string>['onChange'] = (
    _event,
    { newValue },
  ) => {
    if (newValue.length > 64) return
    setValue(newValue)
  }

  const onKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void = event => {
    if (
      value !== '' && // 入力値が空でない
      value === debouncedValue && // 入力値とデバウンスした入力値が一致
      event.key === 'Enter' && // Enterキーが押されたとき
      !event.nativeEvent.isComposing // IME変換中ではない
    ) {
      addUserCubeHandling()
    }
  }

  const addUserCubeHandling = () => {
    const targetName = suggestionsHistory.filter(name => name === value)[0]
    onSelected(targetName)
    setValue('')
  }

  const inputProps: AutosuggestInputProps<string> = {
    placeholder: placeholder,
    value: value || '',
    onChange,
    onKeyDown,
    onBlur,
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={(suggestion: string) => suggestion}
      renderSuggestion={(suggestion: string) => <div>{suggestion}</div>}
      inputProps={inputProps}
      theme={{
        input: className,
        suggestionsContainer: 'autosuggest-suggestions-container',
        suggestion: 'autosuggest-suggestion',
        suggestionsList: 'autosuggest-suggestions-list',
        suggestionHighlighted: 'autosuggest-suggestion-highlighted',
      }}
      renderSuggestionsContainer={({ containerProps, children, query }) => (
        <div {...containerProps}>{query && <ul>{children}</ul>}</div>
      )}
    />
  )
}
