import React, { FC, useEffect, useState } from 'react'
import { SelectInput } from '../selectInput'
import { createDate } from '../../lib/common'

interface Option {
  value: string
  label: string
}

interface Props {
  label: string
  date?: string
  onChange: (date: string) => void
}

export const DateInput: FC<Props> = ({ label, date, onChange }) => {
  const years = [{ value: '', label: '' }] as Option[]
  const months = [{ value: '', label: '' }] as Option[]
  const days = [{ value: '', label: '' }] as Option[]

  const [selectedYear, setSelectedYear] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('')
  const [selectedDay, setSelectedDay] = useState('')

  useEffect(() => {
    if (date) {
      const dateSplit = date
        .replace(/[^0-9]/g, ' ')
        .trim()
        .split(' ')
      setSelectedYear(dateSplit[0])
      setSelectedMonth(dateSplit[1])
      setSelectedDay(dateSplit[2])
    }
  }, [date])

  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    years.push({
      value: i.toString().padStart(2, '0'),
      label: i.toString().padStart(2, '0'),
    })
  }
  for (let i = 1; i <= 12; i++) {
    months.push({
      value: i.toString().padStart(2, '0'),
      label: i.toString().padStart(2, '0'),
    })
  }
  for (let i = 1; i <= 31; i++) {
    days.push({
      value: i.toString().padStart(2, '0'),
      label: i.toString().padStart(2, '0'),
    })
  }

  const onChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value)
    onChange(createDate(event.target.value, selectedMonth, selectedDay))
  }

  const onChangeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value)
    onChange(createDate(selectedYear, event.target.value, selectedDay))
  }

  const onChangeDay = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDay(event.target.value)
    onChange(createDate(selectedYear, selectedMonth, event.target.value))
  }

  // 年選択の初期値を指定
  const handleYearSelect = () => {
    if (!selectedYear) {
      setSelectedYear('2000')
    }
  }

  return (
    <>
      <label className="font-bold text-sm leading-6 block text-left">
        {label}
      </label>
      <div className="-ml-3">
        <div className="relative inline-flex items-center">
          <SelectInput
            options={years}
            selected={selectedYear}
            disabled={false}
            onChange={onChangeYear}
            onOpen={handleYearSelect}
          />
          <span className="ml-1">年</span>
        </div>
        <div className="relative inline-flex items-center">
          <SelectInput
            options={months}
            selected={selectedMonth}
            disabled={false}
            onChange={onChangeMonth}
          />
          <span className="ml-1">月</span>
        </div>
        <div className="relative inline-flex items-center">
          <SelectInput
            options={days}
            selected={selectedDay}
            disabled={false}
            onChange={onChangeDay}
          />
          <span className="ml-1">日</span>
        </div>
      </div>
    </>
  )
}
