import { CommonControllerService } from '../services'
import { toast } from 'react-toastify'

const base64ToFile = (base64Image: string, filename: string): File => {
  // Base64データからMIMEタイプを抽出
  const mimeMatch = base64Image.match(/data:(.*);base64,/)
  if (!mimeMatch) {
    throw new Error('Invalid Base64 string')
  }
  const mime = mimeMatch[1]

  // Base64データをデコードしてUint8Arrayに変換
  const byteString = atob(base64Image.split(',')[1])
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const intArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i)
  }

  // Blobオブジェクトを作成
  const blob = new Blob([intArray], { type: mime })

  // Fileオブジェクトを作成
  return new File([blob], filename, { type: mime })
}

const uploadImage = async (image: string): Promise<string | undefined> => {
  if (image === null) return
  // base64形式を画像ファイルに変換
  const file = base64ToFile(image, 'profile.jpg')
  try {
    const res = await CommonControllerService.uploadImage({
      // @ts-ignore
      file: file,
    })
    return res.imageUrl
  } catch (err) {
    toast.error('アップロードに失敗しました。')
  }
}

export { uploadImage }
