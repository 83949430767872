import React from 'react'
import { CubeQuery } from '../../services'
import { Button } from '../button'

interface CubeListProps {
  cubes: CubeQuery[]
  selectedCubeIds: number[]
  handleCubeClick: (cubeId: number) => void
}

const SearchResultCubeList: React.FC<CubeListProps> = ({
  cubes,
  selectedCubeIds,
  handleCubeClick,
}) => {
  return (
    <ul className="search_input-page_chips__list">
      {cubes.map(cube => (
        <li className="search_input-page_chips-list__item" key={cube.cubeId}>
          <Button
            className={`chips ${
              selectedCubeIds.includes(cube.cubeId) ? 'chips-selected' : ''
            }`}
            onClick={() => handleCubeClick(cube.cubeId)}
          >
            {cube.cubeName}
          </Button>
        </li>
      ))}
    </ul>
  )
}

export default SearchResultCubeList
