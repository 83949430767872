import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { useLocation, useNavigate } from 'react-router-dom'
import { MemberSearchControllerService, SearchQuery } from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'
import { Pagination } from '../../components/pagination'
import env from '../../config/env'
import SearchInputFilters from '../../components/SearchFilters'
import MemberCard from '../../components/MemberCard'

export const SearchInput: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const page = parseInt(query.get('page') as string) || 1
  const keyword = query.get('keyword') || ''
  const [keywordParam, setKeywordParam] = useState('')
  const sort = (query.get('sort') || 'COMMON') as 'COMMON' | 'NEW'
  const [aiFlg, setAiFlg] = useState(query.get('ai') !== 'false')
  const cubeIdsParam = query.get('cubeIds')
  const cubeIds = cubeIdsParam ? cubeIdsParam.split(',').map(Number) : []
  const [selectedCubeIds, setSelectedCubeIds] = useState<number[]>(cubeIds)

  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState({} as SearchQuery)
  const navigate = useNavigate()
  useEffect(() => {
    // ページング用のパラメータを作成
    setKeywordParam(keyword ? `&keyword=${keyword}` : '')

    // 新しいURLからCubeIdsを取得して状態更新
    const handlePopState = () => {
      const newCubeIds =
        new URLSearchParams(window.location.search)
          .get('cubeIds')
          ?.split(',')
          .map(Number) || []
      setSelectedCubeIds(newCubeIds)
    }
    const searchMembers = () => {
      setIsLoading(true)
      MemberSearchControllerService.searchMembers(
        aiFlg,
        keyword,
        selectedCubeIds,
        sort,
        page,
      )
        .then(res => {
          // mockの場合は表示数を増やす
          if (env.ENV === 'mock') {
            res.members = [...res.members, ...res.members, ...res.members]
          }
          setSearch(res)
          setIsLoading(false)
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setIsLoading(false)
        })
    }
    searchMembers()
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [keyword, page, selectedCubeIds, sort, aiFlg])

  // aiFlgが変更されたときにURLパラメータを更新する
  useEffect(() => {
    const newQuery = new URLSearchParams(location.search)
    newQuery.set('ai', aiFlg.toString())
    navigate(`${location.pathname}?${newQuery.toString()}`, { replace: true })
  }, [aiFlg, navigate, location.pathname, location.search])

  const handleCubeClick = (cubeId: number) => {
    const newCubeIds = selectedCubeIds.includes(cubeId)
      ? selectedCubeIds.filter(id => id !== cubeId)
      : [...selectedCubeIds, cubeId]

    setSelectedCubeIds(newCubeIds)
    updateURL(
      keyword ? keyword : '',
      newCubeIds.join(','),
      sort ? sort : 'COMMON',
    )
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSort = event.target.value as 'COMMON' | 'NEW'
    updateURL(keyword ? keyword : '', selectedCubeIds.join(','), newSort)
  }

  // URLを更新
  const updateURL = (
    newKeyword: string,
    newCubeIds: string,
    newSort: string,
  ) => {
    const newParams = new URLSearchParams()
    if (newKeyword) newParams.set('keyword', newKeyword)
    if (newCubeIds) newParams.set('cubeIds', newCubeIds)
    if (newSort) newParams.set('sort', newSort)
    navigate(`?${newParams.toString()}`)
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav">
        <SearchInputFilters
          keyword={keyword}
          aiFlg={aiFlg}
          setAiFlg={setAiFlg}
          selectedCubeIds={selectedCubeIds}
          handleCubeClick={handleCubeClick}
          search={search}
          handleSortChange={handleSortChange}
          sort={sort}
          isLoading={isLoading}
        />
        {isLoading ? (
          <Loading className="loading" type="spin" color="#007559" />
        ) : (
          <div className="search_input-page__inner search_input-page__inner02">
            <div className="search_input-page__block">
              <ul className="search_input-page__list">
                {search.members.map(member => (
                  <MemberCard
                    key={member.memberId}
                    member={member}
                    className="search_input-page-list__item"
                    cubeListClassName="card_list-m__list"
                    cubeItemClassName="card_list-m__item"
                  />
                ))}
              </ul>
              <Pagination
                totalPage={search.totalPage}
                currentPage={page}
                goNext={() => navigate(`?page=${page + 1}${keywordParam}`)}
                goBack={() => navigate(`?page=${page - 1}${keywordParam}`)}
                goPage={page => navigate(`?page=${page}${keywordParam}`)}
              />
            </div>
          </div>
        )}
      </main>
    </>
  )
}
