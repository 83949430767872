/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CubeDetailQuery } from '../models/CubeDetailQuery';
import type { CubeQuery } from '../models/CubeQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CubeControllerService {

    /**
     * @param formData
     * @returns CubeQuery OK
     * @throws ApiError
     */
    public static extractCubes(
        formData?: {
            file: Blob;
        },
    ): CancelablePromise<Array<CubeQuery>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/cubes/extract',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns CubeQuery OK
     * @throws ApiError
     */
    public static getCubes(): CancelablePromise<Array<CubeQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cubes',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param cubeId
     * @param yearMonth
     * @returns CubeDetailQuery OK
     * @throws ApiError
     */
    public static getCubeDetail(
        cubeId: number,
        yearMonth?: string,
    ): CancelablePromise<CubeDetailQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cubes/{cubeId}',
            path: {
                'cubeId': cubeId,
            },
            query: {
                'yearMonth': yearMonth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param keyword
     * @returns CubeQuery OK
     * @throws ApiError
     */
    public static suggestCubes(
        keyword?: string,
    ): CancelablePromise<Array<CubeQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cubes/suggest',
            query: {
                'keyword': keyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
