import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { HeaderType } from '../../enums/HeaderType'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  MemberControllerService,
  MemberQuery,
  QuestionControllerService,
  QuestionQuery,
} from '../../services'
import { Button } from '../../components/button'
import { CubeSendModal } from '../../modals/cubeSendModal'
import { useModal } from '../../contexts/modalContext'
import { PostModal } from '../../modals/postModal'
import QuestionCardList from '../../components/question/questionCardList'
import Timeline from '../../components/timeLine'
import { CoachMark } from '../../components/onboarding/CoachMark'
import {
  answerQuestionsList,
  deleteQuestionFromList,
} from '../../lib/questionActions'
import { useLocation } from 'react-router-dom'
import env from '../../config/env'

export const Home: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const isOpenModal = query.get('openModal') === 'true'

  const [member, setMember] = useState({} as MemberQuery)
  const [pickupQuestions, setPickupQuestions] = React.useState<QuestionQuery[]>(
    [],
  )
  const [latestQuestions, setLatestQuestions] = React.useState<QuestionQuery[]>(
    [],
  )
  const [showFirstTry, setShowFirstTry] = useState<boolean>(false)
  const [showCoachMark, setShowCoachMark] = useState<boolean>(false)
  const [showAbout, setShowAbout] = useState<boolean>(false)
  const [showFirstTryAfter, setShowFirstTryAfter] = useState(false)
  const { openModal, closeModal } = useModal()

  useEffect(() => {
    // 0を指定することでログインユーザの情報を取得する
    MemberControllerService.getMember(0).then(setMember).catch(apiErrorHandler)

    // 右側の質問リスト取得
    getLatestQuestions()
    getPickupQuestions()

    // 初めての方へ
    getAboutMessage()
    // コーチマークの表示判定
    env.ENV !== 'mock' && getCoachMark()
    // "キューブを送る"を促進するメッセージ表示(初回ログイン1日後)
    getFirstTryMessage()
    // "メンバー検索"を促進するメッセージ表示("FirstTryMessage"非表示にしてから3日後)
    getFirstTryAfterMessage()

    if (isOpenModal) {
      openModal(CubeSendModal)
    }
  }, [])

  const getAboutMessage = () => {
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_ABOUT')
      .then(setShowAbout)
      .catch(apiErrorHandler)
  }
  const getFirstTryMessage = () => {
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_FIRST_TRY')
      .then(setShowFirstTry)
      .catch(apiErrorHandler)
    getFirstTryAfterMessage()
  }
  const getFirstTryAfterMessage = () => {
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_FIRST_TRY_AFTER')
      .then(setShowFirstTryAfter)
      .catch(apiErrorHandler)
  }
  const getCoachMark = () => {
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_COACH_MARK')
      .then(setShowCoachMark)
      .catch(apiErrorHandler)
  }

  // そのほか質問
  const getPickupQuestions = () => {
    QuestionControllerService.getPickupQuestions()
      .then(res => {
        setPickupQuestions(res)
      })
      .catch(apiErrorHandler)
  }

  const answerPickupQuestion = (questionId: number, choiceId: number) => {
    answerQuestionsList(
      questionId,
      choiceId,
      pickupQuestions,
      setPickupQuestions,
      apiErrorHandler,
    )
  }

  const deletePickupQuestion = (questionId: number) => {
    deleteQuestionFromList(
      questionId,
      pickupQuestions,
      setPickupQuestions,
      apiErrorHandler,
    )
  }

  // 最新の質問
  const getLatestQuestions = () => {
    QuestionControllerService.getQuestions('ALL', 1)
      .then(res => {
        if (res.length) {
          const latestQuestions = res
          setLatestQuestions(latestQuestions)
        }
      })
      .catch(apiErrorHandler)
  }
  const answerLatestQuestions = (questionId: number, choiceId: number) => {
    answerQuestionsList(
      questionId,
      choiceId,
      latestQuestions,
      setLatestQuestions,
      apiErrorHandler,
    )
  }

  const deleteLatestQuestions = (questionId: number) => {
    deleteQuestionFromList(
      questionId,
      latestQuestions,
      setLatestQuestions,
      apiErrorHandler,
    )
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.DEFAULT} />
      <SideNavi />
      {showCoachMark && <CoachMark getCoachMark={getCoachMark} />}

      <main className="main-sidenav">
        <div className="main_sidenav__inner">
          <Timeline
            showAbout={showAbout}
            showFirstTry={showFirstTry}
            showFirstTryAfter={showFirstTryAfter}
            memberId={member.memberId}
            memberName={member.memberName}
            singleWord={member.singleWord}
            profileImageUrl={member.profileImageUrl}
            positionName={member.position?.positionName}
            memberCubes={member.cubes}
            closeModal={closeModal}
            getAboutMessage={getAboutMessage}
            getFirstTryMessage={getFirstTryMessage}
            getFirstTryAfterMessage={getFirstTryAfterMessage}
          />
          <aside className="home-sidebar">
            <div className="home-sidebar__question">
              <section className="home-sidebar__section">
                {latestQuestions && (
                  <QuestionCardList
                    questions={latestQuestions.slice(0, 1)}
                    title="最新の質問"
                    answerQuestion={answerLatestQuestions}
                    deleteQuestion={deleteLatestQuestions}
                  />
                )}
                {pickupQuestions && (
                  <QuestionCardList
                    questions={pickupQuestions}
                    title="そのほかの質問"
                    linkTo={'/question'}
                    answerQuestion={answerPickupQuestion}
                    deleteQuestion={deletePickupQuestion}
                  />
                )}
              </section>
            </div>
          </aside>
        </div>
      </main>
      <Button
        className="home__cube-btn coach-mark_step-sp-1"
        onClick={() => openModal(PostModal)}
        imageUrl="/images/cube_btn.png"
      ></Button>
    </>
  )
}
