/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MentionTargetMember } from '../models/MentionTargetMember';
import type { PostedTimelineCommentDto } from '../models/PostedTimelineCommentDto';
import type { TimelineCommentForm } from '../models/TimelineCommentForm';
import type { TimelineCommentQuery } from '../models/TimelineCommentQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimelineCommentControllerService {

    /**
     * @param timelineId
     * @returns TimelineCommentQuery OK
     * @throws ApiError
     */
    public static getTimelineComments(
        timelineId: number,
    ): CancelablePromise<Array<TimelineCommentQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline/comments',
            query: {
                'timelineId': timelineId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns PostedTimelineCommentDto OK
     * @throws ApiError
     */
    public static post(
        requestBody: TimelineCommentForm,
    ): CancelablePromise<PostedTimelineCommentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/timeline/comments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns MentionTargetMember OK
     * @throws ApiError
     */
    public static getMentionTargetMembers(): CancelablePromise<Array<MentionTargetMember>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline/comments/members',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
