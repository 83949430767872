import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const RedirectToTalkDetail: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const talkId = queryParams.get('talkId')
  useEffect(() => {
    if (talkId) {
      navigate(`/talk-detail/${talkId}`)
    } else {
      navigate(`/schedule-list`)
    }
  }, [])
  return <></>
}
