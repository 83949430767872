import React, { FC, useState, useEffect } from 'react'
import {
  ReactionQuery,
  TimelineCardQuery,
  TimelineReactionControllerService,
  TimelineReactionMemberQuery,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { ReactionDetailModal } from '../../modals/reactionDetailModal'
import { useModal } from '../../contexts/modalContext'
import { useNavigate } from 'react-router-dom'

interface Props {
  timelineCard: TimelineCardQuery
  myTimelineReactionMemberQuery: TimelineReactionMemberQuery
}

export const ToggleReactionButton: FC<Props> = ({
  timelineCard,
  myTimelineReactionMemberQuery,
}) => {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const reactionChoices = [
    'THUMBS_UP',
    'HEART',
    'SMILE_FACE',
    'GRINNING_SQUINTING_FACE',
    'LOUDLY_CRYING_FACE',
  ]

  const getReactedMembers = (reaction?: ReactionQuery) => {
    return reaction?.sentMemberMap
      ? Object.values(reaction.sentMemberMap).flat()
      : []
  }
  // リアクション選択肢の表示/非表示
  const [isVisibleReactionChoices, setVisibleReactionChoices] = useState(false)
  // リアクション情報
  const [timelineReaction, setTimelineReaction] = useState<
    ReactionQuery | undefined
  >(timelineCard.reaction)

  const [reactionCount, setReactionCount] = useState(
    getReactedMembers(timelineCard.reaction).length,
  )
  const [selectedReactionType, setSelectedReactionType] = useState(
    timelineReaction ? timelineReaction.sentReactionType : '',
  )

  // timelineCardが変更されたときに状態を更新する
  useEffect(() => {
    setTimelineReaction(timelineCard.reaction)
    setReactionCount(getReactedMembers(timelineCard.reaction).length)
    setSelectedReactionType(
      timelineCard.reaction ? timelineCard.reaction.sentReactionType : '',
    )
  }, [timelineCard])

  useEffect(() => {
    if (timelineReaction) {
      setReactionCount(getReactedMembers(timelineReaction).length)
    }
  }, [timelineReaction])

  // リアクション送信
  const toggleTimelineReaction = (
    timelineId: number,
    timelineReactionType: string,
  ) => {
    TimelineReactionControllerService.toggle({
      timelineCardId: timelineId,
      reactionType: timelineReactionType,
    })
      .then(() => {
        // 初リアクションの場合
        if (!selectedReactionType) {
          toast.success('リアクションを送信しました')
          setReactionCount(reactionCount + 1)
          setSelectedReactionType(timelineReactionType)
          toggleState(timelineReactionType, true)
        } else if (
          selectedReactionType &&
          selectedReactionType !== timelineReactionType
        ) {
          // リアクション変更の場合
          toast.success('リアクションを送信しました')
          setSelectedReactionType(timelineReactionType)
          changeState(selectedReactionType, timelineReactionType)
        } else {
          // リアクション取り消しの場合
          toast.success('リアクションを取り消しました')
          setReactionCount(reactionCount - 1)
          setSelectedReactionType('')
          toggleState(timelineReactionType, false)
        }
      })
      .catch(apiErrorHandler)
  }

  const toggleVisibleReactionChoices = () => {
    setVisibleReactionChoices(!isVisibleReactionChoices)
  }

  const reactionCountMessage = (): string => {
    if (selectedReactionType && reactionCount === 1) {
      return `<strong>あなた</strong>がリアクションしました`
    } else if (selectedReactionType && reactionCount > 1) {
      return `<strong>あなた</strong>と<strong>他${
        reactionCount - 1
      }人</strong>がリアクションしました`
    } else if (!selectedReactionType && reactionCount >= 1) {
      return `<strong>${reactionCount}人</strong>がリアクションしました`
    } else {
      return ''
    }
  }

  const toggleState = (timelineReactionType: string, add: boolean) => {
    if (!timelineReaction) {
      // 初回にリアクションを送信した場合にもリアクション情報をセットする
      setTimelineReaction({
        sentReactionType: timelineReactionType,
        sentMemberMap: {
          [timelineReactionType]: add ? [myTimelineReactionMemberQuery] : [],
        },
      } as ReactionQuery)
    }

    if (!timelineReaction) return

    const newTimelineReaction = {
      ...timelineReaction,
      sentMemberMap: {
        ...timelineReaction.sentMemberMap,
        [timelineReactionType]: add
          ? [
              ...(timelineReaction.sentMemberMap[timelineReactionType] || []),
              myTimelineReactionMemberQuery,
            ]
          : timelineReaction.sentMemberMap[timelineReactionType]?.filter(
              member =>
                member.memberId !== myTimelineReactionMemberQuery.memberId,
            ),
      },
    } as ReactionQuery
    setTimelineReaction(newTimelineReaction)
  }

  const changeState = (
    oldTimelineReactionType: string,
    newTimelineReactionType: string,
  ) => {
    if (!timelineReaction) return

    const newTimelineReaction = {
      ...timelineReaction,
      sentMemberMap: {
        ...timelineReaction.sentMemberMap,
        [oldTimelineReactionType]: timelineReaction.sentMemberMap[
          oldTimelineReactionType
        ]?.filter(
          member => member.memberId !== myTimelineReactionMemberQuery.memberId,
        ),
      },
    } as ReactionQuery
    newTimelineReaction.sentMemberMap[newTimelineReactionType] = [
      ...(timelineReaction.sentMemberMap[newTimelineReactionType] || []),
      myTimelineReactionMemberQuery,
    ]
    setTimelineReaction(newTimelineReaction)
  }

  return (
    <div className="toggle-reaction-container" 
    
    onClick={() => navigate(`/timeline-detail/${timelineCard.timelineCardId}`)}>
      <div className="toggle-reaction">
        <span className="toggle-reaction__balloon" onClick={event => {
            event.stopPropagation()}}>
          クリックしてリアクションする
        </span>
        <button
          className="toggle-reaction__button"
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            toggleVisibleReactionChoices()
          }}
        >
          <div
            className={`toggle-reaction__button__choices ${
              isVisibleReactionChoices ? 'isVisible' : ''
            }`}
          >
            {reactionChoices.map((reaction, index) => (
              <button
                key={index}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  toggleTimelineReaction(timelineCard.timelineCardId, reaction)
                  toggleVisibleReactionChoices()
                }}
              >
                <img src={`/images/reaction-${reaction}.svg`} />
              </button>
            ))}
          </div>
          <img
            className="toggle-reaction__button__icon"
            src={
              selectedReactionType
                ? `/images/reaction-${selectedReactionType}.svg`
                : '/images/reaction.svg'
            }
          />
        </button>
      </div>
      {(getReactedMembers(timelineReaction).length > 0 ||
        selectedReactionType) && (
        <div className="toggle-reaction__message">
          <ul className="toggle-reaction__message__avatars">
            {getReactedMembers(timelineReaction)
              .slice(0, 4)
              .map((member, index) => (
                <li key={index}>
                  <img
                    src={
                      member.profileImageUrl
                        ? member.profileImageUrl
                        : '/icons/avatar-sample.png'
                    }
                  />
                </li>
              ))}
            {getReactedMembers(timelineReaction).length > 4 && <span>...</span>}
          </ul>
          <button
            className="toggle-reaction__message__link"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              openModal(ReactionDetailModal, {
                timelineReaction: timelineReaction,
              })
            }}
            dangerouslySetInnerHTML={{
              __html: reactionCountMessage(),
            }}
          ></button>
        </div>
      )}
    </div>
  )
}
