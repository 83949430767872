import { Step } from 'react-joyride'

export enum CoachMarkStepsForSP {
  STEP_0 = '.coach-mark_step-sp-0',
  STEP_1 = '.coach-mark_step-sp-1',
  STEP_2 = '.coach-mark_step-sp-2',
  STEP_3 = '.coach-mark_step-sp-3',
  STEP_4 = '.coach-mark_step-sp-4',
  STEP_5 = '.coach-mark_step-sp-5',
  STEP_6 = '.coach-mark_step-sp-6',
  STEP_7 = 'body',
}
export enum CoachMarkStepsForPC {
  STEP_0 = '.coach-mark_step-pc-0',
  STEP_1 = '.coach-mark_step-pc-1',
  STEP_2 = '.coach-mark_step-pc-2',
  STEP_3 = '.coach-mark_step-pc-3',
  STEP_4 = '.coach-mark_step-pc-4',
  STEP_5 = '.coach-mark_step-pc-5',
  STEP_6 = '.coach-mark_step-pc-6',
  STEP_7 = 'body',
}

export const stepsForPC: Step[] = [
  {
    target: CoachMarkStepsForPC.STEP_0,
    content: '', // ロード直後からコーチマーク(step1)を表示するために、step0が必要。
  },
  {
    target: CoachMarkStepsForPC.STEP_1,
    content:
      'メンバーにキューブを送ることができます。あなたが感じたメンバーの特技や個性を、キューブとして送りましょう！',
  },
  {
    target: CoachMarkStepsForPC.STEP_2,
    content:
      'メンバーの名前やキーワードで検索できます。業務で困ったらキーワード検索で得意なメンバーを見つけてみましょう！',
  },
  {
    target: CoachMarkStepsForPC.STEP_3,
    content: 'プロフィールや自分のキューブの確認・編集ができます。',
  },
  {
    target: CoachMarkStepsForPC.STEP_4,
    content:
      'ホームのフィードには新しいメンバーが参加したときやキューブが送られたときなど、メンバーにまつわる情報が表示されます。ぜひチェックしてみてください。',
  },
  {
    target: CoachMarkStepsForPC.STEP_5,
    content: '1対1でもグループでも！メンバーとチャットをすることができます。',
  },
  {
    target: CoachMarkStepsForPC.STEP_6,
    content: 'サマリーやキューブランキングといったデータを見ることができます。',
  },
  {
    target: CoachMarkStepsForPC.STEP_7,
    content:
      'parksをブックマークに登録して、いつでもアクセスできるようにしましょう！',
    placement: 'center',
  },
]

export const stepsForSP: Step[] = [
  {
    target: CoachMarkStepsForSP.STEP_0,
    content: '', // ロード直後からコーチマーク(step1)を表示するために、step0が必要。
  },
  {
    target: CoachMarkStepsForSP.STEP_1,
    content:
      'メンバーにキューブを送ることができます。あなたが感じたメンバーの特技や個性を、キューブとして送りましょう！',
  },
  {
    target: CoachMarkStepsForSP.STEP_2,
    content:
      'メンバーの名前やキーワードで検索できます。業務で困ったらキーワード検索で得意なメンバーを見つけてみましょう！',
  },
  {
    target: CoachMarkStepsForSP.STEP_3,
    content: 'プロフィールや自分のキューブの確認・編集ができます。',
  },
  {
    target: CoachMarkStepsForSP.STEP_4,
    content:
      'ホームのフィードには新しいメンバーが参加したときやキューブが送られたときなど、メンバーにまつわる情報が表示されます。ぜひチェックしてみてください。',
  },
  {
    target: CoachMarkStepsForSP.STEP_5,
    content: '1対1でもグループでも！メンバーとチャットをすることができます。',
  },
  {
    target: CoachMarkStepsForSP.STEP_6,
    content: 'サマリーやキューブランキングといったデータを見ることができます。',
  },
  {
    target: CoachMarkStepsForSP.STEP_7,
    content:
      'parksをブックマークに登録して、いつでもアクセスできるようにしましょう！',
    placement: 'center',
  },
]
