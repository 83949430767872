import { CareerQuery, MemberCubeQuery } from '../services'
import { format, parseISO } from 'date-fns'
import env from '../config/env'

// キューブ名の合計が20文字程度になるようにタグをピックアップする
const pickupCubes = (
  cubes: Array<MemberCubeQuery> | undefined,
  cubeNameLength?: number,
): Array<MemberCubeQuery> => {
  cubeNameLength = cubeNameLength || 20
  const result: MemberCubeQuery[] = []
  if (!cubes) {
    return result
  }
  let totalLength = 0
  for (const cube of cubes) {
    if (cube.cubeName.length > cubeNameLength - 5) {
      // 長すぎるキューブはスキップ
      continue
    }
    totalLength += cube.cubeName.length
    if (totalLength > cubeNameLength) {
      // 長くなりすぎたらスキップ
      totalLength -= cube.cubeName.length
      continue
    }
    result.push(cube)
    if (totalLength > cubeNameLength - 2) {
      // 20文字程度になったら終了
      break
    }
  }
  if (result.length === 6) {
    result.length = 5
  }
  return result
}

const getDateOptions = (start: number, end: number) => {
  const options = [{ value: '', label: '' }]
  for (let i = start; i <= end; i++) {
    options.push({
      value: i.toString().padStart(2, '0'),
      label: i.toString().padStart(2, '0'),
    })
  }
  return options
}

const getDate = (dateString: string) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}年${month}月${day}日`
}

const getWeek = (dateString: string) => {
  const date = new Date(dateString)
  const week = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()]
  return `(${week})`
}

const getTime = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

const createDate = (year?: string, month?: string, day?: string): string => {
  return `${year || ''}-${month || ''}-${day || ''}`
}

const convertDateFromResponse = (dateString: string) => {
  // 数値以外を取り除く
  const date = dateString.replace(/\D/g, '')
  const year = date.slice(0, 4)
  const month = date.slice(4, 6)
  const day = date.slice(6, 8)
  return createDate(year, month, day)
}

const getCareerInfo = (careers: CareerQuery[]) => {
  const career = careers.filter(c => c.headlineFlg)
  if (career.length === 0) {
    return []
  }

  return career.map(c => `${c.companyName} / ${c.occupationName}`)
}

const exportCsv = (title: string, header: string[], data: string[][]) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const csvData = [header, ...data]
  const blob = new Blob([bom, csvData.map(r => r.join(',')).join('\r\n')], {
    type: 'text/csv',
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.download = `${title}.csv`
  a.href = url
  a.click()
  window.URL.revokeObjectURL(url)
}

// 日付を整形
const formatDate = (
  dateString: string | undefined,
  formatPattern: string,
): string => {
  if (!dateString) {
    return ''
  }

  return format(new Date(dateString), formatPattern)
}

const timeAgoInWords = (notifyAtString: string | undefined): string => {
  if (!notifyAtString) {
    return ''
  }
  if (env.ENV === 'mock') {
    return format(new Date(), 'yyyy/MM/dd HH:mm')
  }
  const notifyAt = parseISO(notifyAtString)
  const now = new Date()
  // 現在の日時とnotifyAtを比較して、適切なテキストを返す
  // 例えば24時間以内だったら「約1時間前」など
  // 24時間以上だったら「2021/01/01 12:34」など
  if (now.getTime() - notifyAt.getTime() < 24 * 60 * 60 * 1000) {
    const diff = Math.floor((now.getTime() - notifyAt.getTime()) / 1000)
    if (diff < 60 * 60) {
      return `約${Math.floor(diff / 60)}分前`
    }
    return `約${Math.floor(diff / 60 / 60)}時間前`
  }
  return format(notifyAt, 'yyyy/MM/dd HH:mm')
}

export {
  pickupCubes,
  getDateOptions,
  getDate,
  getWeek,
  getTime,
  createDate,
  convertDateFromResponse,
  getCareerInfo,
  exportCsv,
  formatDate,
  timeAgoInWords,
}
