/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageUpload } from '../models/ImageUpload';
import type { MemberRoleMap } from '../models/MemberRoleMap';
import type { RankingTypeMap } from '../models/RankingTypeMap';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonControllerService {

    /**
     * @param formData
     * @returns ImageUpload OK
     * @throws ApiError
     */
    public static uploadImage(
        formData?: {
            file: Blob;
        },
    ): CancelablePromise<ImageUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/upload/image',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns RankingTypeMap OK
     * @throws ApiError
     */
    public static getRankingTypes(): CancelablePromise<Array<RankingTypeMap>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ranking-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns MemberRoleMap OK
     * @throws ApiError
     */
    public static getMemberRoles(): CancelablePromise<Array<MemberRoleMap>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member-roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static healthcheck(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/healthcheck',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
