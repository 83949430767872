import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { SideNavi } from '../../layouts/sideNavi'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  AdminControllerService,
  AdminMember,
  CommonControllerService,
  MemberControllerService,
  MemberQuery,
  MemberRoleMap,
  MemberRoleQuery,
  UpdateMemberForm,
} from '../../services'
import { MemberSaveModal } from '../../modals/memberSaveModal'
import { useModal } from '../../contexts/modalContext'
import { AdminProfileEditModal } from '../../modals/adminProfileEditModal'
import { MemberInviteForMailModal } from '../../modals/memberInviteForMailModal'
import { Button } from '../../components/button'

export const Member: FC = () => {
  const { openModal } = useModal()

  const [member, setMember] = useState({} as MemberQuery)

  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])
  const [adminMembers, setAdminMembers] = useState<AdminMember[]>([])
  const [selectedMemberRoles, setSelectedMemberRoles] = useState<
    MemberRoleQuery.memberRole[]
  >([])

  const [isActive, setIsActive] = useState(true)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isNotLogin, setIsNotLogin] = useState(false)

  useEffect(() => {
    MemberControllerService.getMember(0).then(setMember).catch(apiErrorHandler)

    setSelectedMemberRoles(Object.values(AdminMember.memberRole))
    CommonControllerService.getMemberRoles()
      .then(setMemberRoleMaps)
      .catch(apiErrorHandler)
    AdminControllerService.getAdminMembers()
      .then(res => {
        setAdminMembers(res)
      })
      .catch(apiErrorHandler)
  }, [openModal])

  const getFilteredAdminMembers = () => {
    return (
      adminMembers
        // キーワードで絞り込み
        ?.filter(
          adminMember =>
            adminMember.memberName.includes(keyword) ||
            adminMember.email.includes(keyword),
        )
        // 権限で絞り込み
        .filter(adminMember =>
          selectedMemberRoles.includes(adminMember.memberRole),
        )
        // ステータスで絞り込み
        .filter(
          adminMember =>
            (isActive && !adminMember.deleteFlg && adminMember.loginFlg) ||
            (isDeleted && adminMember.deleteFlg && adminMember.loginFlg) ||
            (isNotLogin && !adminMember.loginFlg),
        )
    )
  }

  const [keyword, setKeyword] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const updateMemberStatus = (adminMember: AdminMember) => {
    window.confirm(
      adminMember.deleteFlg
        ? 'メンバーを有効にしますか？'
        : 'メンバーを休止にしますか？',
    ) &&
      AdminControllerService.updateMemberFromAdmin(adminMember.memberId, {
        deleteFlg: !adminMember.deleteFlg,
      } as UpdateMemberForm)
        .then(() => {
          // 再取得
          AdminControllerService.getAdminMembers()
            .then(setAdminMembers)
            .catch(apiErrorHandler)
        })
        .catch(apiErrorHandler)
  }
  return (
    <>
      <Head />
      {/* 画面遷移なしで検索を使うために組み込み */}
      <header className="fixed top-0 right-0 w-full z-50 max-w-full h-[3.25rem] bg-white md:h-[4.5rem] md:max-w-[calc(100%-223px)] md:bg-transparent">
        <div className="flex justify-between items-center max-w-[106.0625rem] mx-auto py-2 px-4 gap-2 md:px-10 md:py-4 md:gap-6">
          <div className="relative w-full max-w-full md:w-[480px]">
            <svg
              className="absolute top-1/2 left-4 transform -translate-y-1/2 w-5 h-5 text-green"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0918 16.9075L15.0002 13.8409C16.2002 12.3445 16.7814 10.4453 16.6242 8.5336C16.4669 6.62194 15.5832 4.84317 14.1548 3.56306C12.7263 2.28294 10.8617 1.59878 8.94427 1.65125C7.02686 1.70371 5.20243 2.48882 3.84612 3.84514C2.4898 5.20146 1.70469 7.02589 1.65222 8.94329C1.59976 10.8607 2.28392 12.7253 3.56403 14.1538C4.84415 15.5822 6.62292 16.466 8.53458 16.6232C10.4462 16.7804 12.3455 16.1993 13.8418 14.9992L16.9085 18.0659C16.986 18.144 17.0781 18.206 17.1797 18.2483C17.2812 18.2906 17.3902 18.3124 17.5002 18.3124C17.6102 18.3124 17.7191 18.2906 17.8206 18.2483C17.9222 18.206 18.0144 18.144 18.0918 18.0659C18.242 17.9105 18.326 17.7028 18.326 17.4867C18.326 17.2706 18.242 17.0629 18.0918 16.9075ZM9.16683 14.9992C8.01311 14.9992 6.88529 14.6571 5.926 14.0161C4.96672 13.3751 4.21904 12.4641 3.77753 11.3982C3.33602 10.3323 3.2205 9.15938 3.44558 8.02783C3.67066 6.89627 4.22624 5.85687 5.04204 5.04107C5.85785 4.22526 6.89725 3.66969 8.0288 3.44461C9.16036 3.21953 10.3332 3.33505 11.3992 3.77656C12.4651 4.21807 13.3761 4.96574 14.0171 5.92503C14.658 6.88432 15.0002 8.01213 15.0002 9.16585C15.0002 10.713 14.3856 12.1967 13.2916 13.2906C12.1977 14.3846 10.7139 14.9992 9.16683 14.9992Z"
                fill="currentColor"
              />
            </svg>
            <input
              type="text"
              placeholder="キーワードで絞り込み"
              value={keyword}
              onChange={handleChange}
              className="w-full h-full px-10 py-2 rounded-lg shadow-none bg-gray-100 text-inherit text-base appearance-none md:py-3 md:bg-white md:shadow-lg md:rounded-xl"
            />
          </div>
          <div className="flex items-center ml-auto gap-4">
            {/* TODO 通知画面がないので非表示 */}
            {/*
              <button className="header_login__btn_nortification">
                <img
                  src="/images/icon_timeline.svg"
                  alt="ベルマークにチェックあり"
                />
              </button>
              */}
            <Button
              className="justify-center items-center block w-8 h-8 rounded-[33px] shadow-md bg-white transition ease-out duration-300 gap-1 md:flex md:w-11 md:h-11"
              onClick={() => {
                window.location.href = '/profile'
              }}
            >
              <img
                src={
                  member.profileImageUrl
                    ? member.profileImageUrl
                    : '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
                className="w-full h-full rounded-full object-cover md:w-8 md:h-8"
              />
            </Button>
          </div>
        </div>
      </header>
      <SideNavi />
      <main className="ml-auto w-full mt-[3.25rem] md:mt-[4.5rem] md:w-[calc(100%-223px)]">
        <div className="max-w-[106.0625rem] mx-auto mt-6 px-4 md:mt-8 md:px-10">
          <div>
            <div>
              <p className="font-bold text-xs mt-4">権限で絞り込み</p>
              <ul className="flex flex-nowrap mt-2 overflow-scroll gap-2 hidden-scrollbar">
                {Object.values(AdminMember.memberRole).map(
                  (memberRole, index) => {
                    const isSelected = selectedMemberRoles.includes(memberRole)
                    const label =
                      memberRoleMaps.find(
                        memberRoleMap => memberRoleMap.name === memberRole,
                      )?.label || memberRole
                    const handleClick = () => {
                      const updatedRoles = isSelected
                        ? selectedMemberRoles.filter(
                            selectedMemberRole =>
                              selectedMemberRole !== memberRole,
                          )
                        : [...selectedMemberRoles, memberRole]
                      setSelectedMemberRoles(updatedRoles)
                    }
                    return (
                      <li key={index}>
                        <Button
                          className={`block px-3 py-2 pr-9 rounded-lg text-sm font-bold ${
                            isSelected
                              ? 'text-white bg-gray-900'
                              : 'text-gray-400 bg-white'
                          } relative whitespace-nowrap`}
                          onClick={handleClick}
                        >
                          {label}
                          <span
                            className={`absolute top-1/2 right-3 transform -translate-y-1/2 block w-5 h-5 bg-center bg-no-repeat ${
                              isSelected
                                ? 'bg-[url("/images/icon_clear.svg")] rounded-full'
                                : 'bg-[url("/images/icon_plus.svg")]'
                            }`}
                          ></span>
                        </Button>
                      </li>
                    )
                  },
                )}
              </ul>
            </div>
            <div>
              <p className="mt-5 font-bold text-xs lg:mt-4">
                ステータスで絞り込み
              </p>
              <ul className="flex-nowrap overflow-scroll gap-2 mt-2 flex mb-[0.625rem] hidden-scrollbar">
                <li>
                  <Button
                    className={`block px-3 py-2 pr-9 rounded-lg text-sm font-bold ${
                      isActive
                        ? 'text-white bg-gray-900'
                        : 'text-gray-400 bg-white'
                    } relative whitespace-nowrap`}
                    onClick={() => setIsActive(!isActive)}
                  >
                    有効
                    <span
                      className={`absolute top-1/2 right-3 transform -translate-y-1/2 block w-5 h-5 bg-center bg-no-repeat ${
                        isActive
                          ? 'bg-[url("/images/icon_clear.svg")] rounded-full'
                          : 'bg-[url("/images/icon_plus.svg")]'
                      }`}
                    ></span>
                  </Button>
                </li>
                <li>
                  <Button
                    className={`block px-3 py-2 pr-9 rounded-lg text-sm font-bold ${
                      isDeleted
                        ? 'text-white bg-gray-900'
                        : 'text-gray-400 bg-white'
                    } relative whitespace-nowrap`}
                    onClick={() => setIsDeleted(!isDeleted)}
                  >
                    休止
                    <span
                      className={`absolute top-1/2 right-3 transform -translate-y-1/2 block w-5 h-5 bg-center bg-no-repeat ${
                        isDeleted
                          ? 'bg-[url("/images/icon_clear.svg")] rounded-full'
                          : 'bg-[url("/images/icon_plus.svg")]'
                      }`}
                    ></span>
                  </Button>
                </li>
                <li>
                  <Button
                    className={`block px-3 py-2 pr-9 rounded-lg text-sm font-bold ${
                      isNotLogin
                        ? 'text-white bg-gray-900'
                        : 'text-gray-400 bg-white'
                    } relative whitespace-nowrap`}
                    onClick={() => setIsNotLogin(!isNotLogin)}
                  >
                    未ログイン
                    <span
                      className={`absolute top-1/2 right-3 transform -translate-y-1/2 block w-5 h-5 bg-center bg-no-repeat ${
                        isNotLogin
                          ? 'bg-[url("/images/icon_clear.svg")] rounded-full'
                          : 'bg-[url("/images/icon_plus.svg")]'
                      }`}
                    ></span>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-between flex-col lg:flex-row">
            <div className="flex items-center gap-2">
              <div className="font-bold text-base md:text-sm">メンバー</div>
              <div className="block text-gray-400 font-bold text-sm">
                {getFilteredAdminMembers().length}人
              </div>
            </div>
            <div className="flex justify-end gap-2 flex-col items-center mt-2 sm:flex-row sm:items-end sm:mt-0">
              <Button
                className="w-full px-6 py-3 flex justify-center items-center btn-primary-base sm:w-auto"
                onClick={() => {
                  openModal(MemberSaveModal)
                }}
              >
                メンバーを招待
              </Button>

              <Button
                className="w-full px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base sm:w-auto"
                onClick={() => {
                  openModal(MemberInviteForMailModal)
                }}
              >
                招待URL発行(メールログイン用)
              </Button>
            </div>
          </div>
          <div className="max-w-[101.0625rem] mx-auto pb-10 mb-[3.4375rem] md:mb-0">
            <ul className="flex flex-wrap mt-7 mb-5 gap-y-2 gap-x-2">
              {getFilteredAdminMembers().map((adminMember, index) => (
                <li
                  className="min-h-[73px] w-full lg:w-[calc(50%-4px)]"
                  key={index}
                >
                  <div
                    className="w-full max-w-[28.25rem] p-4 border border-gray-100 rounded-xl shadow-md bg-white cursor-pointer h-full mx-auto lg:max-w-full"
                    onClick={event => {
                      event.stopPropagation()
                      event.preventDefault()
                      openModal(AdminProfileEditModal, {
                        adminMember: adminMember,
                        memberRoleMaps: memberRoleMaps,
                        disabled: adminMember.memberId === member.memberId,
                      })
                    }}
                  >
                    <div className="flex justify-between gap-0 items-center">
                      <div className="flex items-center gap-2">
                        <div className="w-10 aspect-[40/40]">
                          <img
                            src={
                              adminMember.profileImageUrl
                                ? adminMember.profileImageUrl
                                : '/icons/avatar-sample.png'
                            }
                            alt="プロフィール画像"
                            className="w-full h-full rounded-full object-cover"
                          />
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col flex-wrap items-start gap-[0.125rem]">
                            <h3 className="font-bold text-sm line-clamp-2 break-all">
                              {adminMember.memberName}
                            </h3>
                            <p className="ml-0 text-gray-500 text-[0.625rem]">
                              {memberRoleMaps.find(
                                memberRoleMap =>
                                  memberRoleMap.name === adminMember.memberRole,
                              )?.label || adminMember.memberRole}
                            </p>
                          </div>
                          <p className="mt-1 text-gray-500 text-[0.625rem]">
                            {adminMember.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        {adminMember.deleteFlg ? (
                          <Button
                            className="btn-base btn-primary-base block max-w-[72px] px-3 py-2 rounded-lg"
                            onClick={event => {
                              event.stopPropagation()
                              event.preventDefault()
                              updateMemberStatus(adminMember)
                            }}
                          >
                            有効
                          </Button>
                        ) : (
                          <Button
                            className="btn-base btn-outline-base block max-w-[72px] px-3 py-2 rounded-lg"
                            onClick={event => {
                              event.stopPropagation()
                              event.preventDefault()
                              updateMemberStatus(adminMember)
                            }}
                          >
                            休止
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </>
  )
}
