import { FC } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { Footer } from '../../layouts/footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const Top: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="mx-4 bg-white px-4 rounded-md pt-8">
          <p className="">
            <b>parks（パークス）とは</b>
            <br />

            <div className="text-sm">
              parksは、個々の能力を可視化し、
              <br />
              AIを活用して効果的なつながりと連携を促進します。
              <br />
              チームビルディングからコラボレーションまでをスムーズに実現し、
              <br />
              チームの力を最大限に引き出し成果を高める「つながりDXサービス」です。
            </div>

            <hr className="separator" />

            <b>parks（パークス）の機能紹介</b>
            <br />
            <div className="mb-2">
              <b className="text-sm">キューブを送り合い、メンバー情報を更新</b>
              <br />
              <div className="text-sm">
                メンバーのスキルや個性を「キューブ」として送り合うことで、メンバー情報をリアルタイムで可視化・更新します。
              </div>
            </div>
            <div className="mb-2">
              <b className="text-sm">
                きっかけを生み出し、コミュニケーションを活性化
              </b>
              <br />
              <div className="text-sm">
                タイムラインで新メンバーやキューブ情報を共有し、コメントやリアクションでコミュニケーションを促進します。
              </div>
            </div>
            <div className="mb-2">
              <b className="text-sm">
                AIで必要な人材を発見、コラボレーション促進
              </b>
              <br />
              <div className="text-sm">
                AIが最適なメンバーを素早く提案し、効率的な情報共有とスムーズなコラボレーションを支援します。
              </div>
            </div>

            <div className="mb-2">
              <b className="text-sm">メンバーの考えが見える、意見を共有</b>
              <br />
              <div className="text-sm">
                匿名で質問したり、メンバーの考えを知ることで、チーム内のつながりを深めます。
              </div>
            </div>

            <div className="mb-2">
              <b className="text-sm">
                アクティビティを蓄積し、人的資本を可視化
              </b>
              <br />
              <div className="text-sm">
                交換されたキューブやコメントをデータ化し、チームのつながりや知識の流れを見える化します。
              </div>
            </div>
          </p>
          <hr className="separator" />
          <div className="text-sm">
            <b>Googleログインについて</b>
            <div>
              parksではGoogleアカウントを用いてログインすることが可能です。
              <br />
              Googleログインを利用することでGoogleに登録のメールアドレスをparksに登録することができます。
            </div>
          </div>
          <hr className="separator" />
          <p className="form__check form__check-center pb-8">
            <a href="/login" className="underline">
              ログインページへ
            </a>
          </p>
        </div>
      </main>
      <Footer />
    </>
  )
}
