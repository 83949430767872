import { FC, KeyboardEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  MentionTargetMember,
  TimelineCommentControllerService,
  TimelineCommentQuery,
} from '../../services'
import { TimelineCommentInput } from './TimelineCommentInput'
import { compareAsc, parseISO } from 'date-fns'
import apiErrorHandler from '../../api/apiErrorHandler'
import { SafeParagraph } from '../safeParagraph'
import { timeAgoInWords } from '../../lib/common'
import { OnChangeHandlerFunc } from 'react-mentions'
import DOMPurify from 'dompurify'

export interface TimelineCommentProps {
  timelineId?: number
  timelineComments?: TimelineCommentQuery[]
  timelineCommentTotalCount: number
  mentionTargetMembers: MentionTargetMember[]
  isCompact?: boolean
  isFixed?: boolean
  onClick?: () => void
}

export const TimelineComment: FC<TimelineCommentProps> = ({
  timelineId,
  timelineComments,
  timelineCommentTotalCount,
  mentionTargetMembers,
  isCompact = true,
  isFixed = false,
  onClick,
}) => {
  const navigate = useNavigate()
  const [commentForm, setCommentForm] = useState<string>('')
  const [addedComments, setAddedComments] = useState<TimelineCommentQuery[]>([])
  const [selectedMentionMemberIds, setSelectedMentionMemberIds] = useState<
    number[]
  >([])

  const [isSending, setIsSending] = useState(false)

  // コメントのテキストエリア入力時のハンドリング処理
  // メンションされた or 外された場合はメンションリストから追加 or 削除行う
  const handleInputChange: OnChangeHandlerFunc = (
    event,
    newValue,
    newPlainTextValue,
    mentions,
  ) => {
    setSelectedMentionMemberIds(mentions.map(mention => Number(mention.id)))
    setCommentForm(event.target.value)
  }

  // ショートカットでメッセージ送信
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault()
      sendComment()
    }
  }

  // テキストエリアは8行まで表示
  const calculateTextAreaRows = () => {
    const lines = commentForm.split('\n').length
    return Math.min(lines, 8)
  }

  const sendComment = () => {
    if (!timelineId) {
      return
    }
    if (isSending) {
      return
    }

    // メンション時にライブラリ都合で制御文字が入っているため、削除する。追加でclassとaタグを追加
    let replacedCommentForm = commentForm
      .replace(/###\d+###/g, '')
      .replace(/---/g, '')
      .replace(/###\[/g, '')
      .replace(/\]###/g, ' ')

    mentionTargetMembers
      .filter(member => selectedMentionMemberIds.includes(member.memberId))
      .forEach(member => {
        replacedCommentForm = replacedCommentForm.replace(
          `@${member.memberName}`,
          `<span class="mentioned"><a href="/member-detail/${member.memberId}">@${member.memberName}</a></span>`,
        )
      })

    setIsSending(true)
    TimelineCommentControllerService.post({
      timelineId: timelineId,
      comment: replacedCommentForm,
      mentionedMemberIds: selectedMentionMemberIds,
    })
      .then(res => {
        setCommentForm('')
        setAddedComments(prev => [
          ...prev,
          {
            timelineId: timelineId,
            memberId: res.memberId,
            memberName: res.memberName,
            profileImageUrl: res.profileImageUrl,
            comment: res.comment,
            createdAt: res.createdAt,
            mentionedMembers: res.mentionedMembers,
          },
        ])
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsSending(false)
      })
  }

  // 既存コメントと追加コメントを結合させる
  // コンパクト表示の場合は投稿日降順最新2件のみ表示
  // それ以外投稿日昇順は全て表示
  const combineComments = () => {
    const comments =
      timelineComments !== undefined
        ? timelineComments.concat(addedComments)
        : addedComments

    if (isCompact) {
      return comments
        .sort((a, b) =>
          compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
        )
        .slice(-2)
    }
    return comments.sort((a, b) =>
      compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
    )
  }

  // 追加コメントを送信したときに、コメント総数を計算する
  const combineCommentCount = (): number => {
    return timelineCommentTotalCount + addedComments.length
  }

  // 出力可能なhtmlを制御
  const cleanDangerousContent = (dangerousContent: string) => {
    return DOMPurify.sanitize(dangerousContent, {
      ALLOWED_TAGS: ['span', 'a'],
      ALLOWED_ATTR: ['class', 'href'],
    })
  }

  return (
    <>
      {isCompact && combineCommentCount() >= 3 && (
        <>
          <div
            onClick={onClick}
            className="timeline-detail__view-comments-container"
          >
            <button className="timeline-detail__view-comments">
              みんなのコメントを見る ({combineCommentCount()}件）
            </button>
          </div>
        </>
      )}

      <div
        className="other-members__block other-members__block-home"
        onClick={onClick}
      >
        {!isCompact && (
          <>
            <div className="other-members__block-title">
              <h2 className="modal-edit__sub-title modal-edit__sub-title-comment">
                タイムラインコメント
              </h2>
            </div>
          </>
        )}
        <ul
          className={`timeline-detail__timeline ${
            isFixed ? 'timeline-detail__timeline--fixed' : ''
          }`}
        >
          {combineComments()?.map((timelineComment, index) => (
            <li className="timeline-detail__timeline__item" key={index}>
              <div className="timeline-detail__timeline__item__head">
                <img
                  src={
                    timelineComment.profileImageUrl
                      ? timelineComment.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  width={32}
                  height={32}
                  onClick={event => {
                    event.stopPropagation()
                    navigate(`/member-detail/${timelineComment.memberId}`)
                  }}
                />
                <span
                  className="timeline-detail__timeline__item__head__user"
                  onClick={event => {
                    event.stopPropagation()
                    navigate(`/member-detail/${timelineComment.memberId}`)
                  }}
                >
                  {timelineComment.memberName}
                </span>
                <span className="timeline-detail__timeline__item__head__time">
                  {timeAgoInWords(timelineComment.createdAt)}
                </span>
              </div>
              <SafeParagraph
                className="timeline-detail__timeline__item__body"
                content={timelineComment.comment}
                dangerouslySetInnerHTML={true}
                cleanDangerousContent={cleanDangerousContent}
              />
            </li>
          ))}
        </ul>
      </div>

      <TimelineCommentInput
        timelineCommentForm={commentForm}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        calculateTextAreaRows={calculateTextAreaRows}
        sendTimelineComment={sendComment}
        mentionTargetMembers={mentionTargetMembers}
        isFixed={isFixed}
      />
    </>
  )
}
