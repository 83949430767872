import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import {
  CommonControllerService,
  DeveloperControllerService,
  RankingControllerService,
  RankingQuery,
  RankingTypeMap,
  WorkspaceDto,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { RankingTable } from '../../components/ranking/RankingTable'
import { useNavigate } from 'react-router-dom'

interface Props {
  developerMode?: boolean
}

export const Ranking: FC<Props> = ({ developerMode = false }) => {
  const navigate = useNavigate()
  const [workspaces, setWorkspaces] = useState([] as WorkspaceDto[])
  const [workspaceId, setWorkspaceId] = useState<string>()

  const [rankingTypeMaps, setRankingTypeMaps] = useState([] as RankingTypeMap[])
  const [rankingType, setRankingType] = useState('')
  const [rankingQueries, setRankingQueries] = useState([] as RankingQuery[])
  const [filterDate, setFilterDate] = useState('')
  const [headContents, setHeadContents] = useState([
    '順位',
    'キューブ名',
    'キューブ数',
  ])

  useEffect(() => {
    // ランキング種別を取得
    CommonControllerService.getRankingTypes()
      .then(res => {
        if (rankingTypeMaps.length === 0) {
          setRankingTypeMaps(res)
          setRankingType(res[0].name)
        }

        // CUBEが含まれる場合は順位、キューブ名、キューブ数
        if ((rankingType || res[0].name).includes('CUBE')) {
          setHeadContents(['順位', 'キューブ名', 'キューブ数'])
        } else {
          setHeadContents(['順位', 'メンバー名', 'キューブ数'])
        }

        if (!rankingType) {
          return
        }

        const getRanking = developerMode
          ? DeveloperControllerService.getDeveloperRanking(
              // @ts-ignore
              rankingType,
              workspaceId,
            )
          : // @ts-ignore
            RankingControllerService.getRanking(rankingType)
        !(developerMode && !workspaceId) &&
          getRanking
            .then(res => {
              setRankingQueries(res)
              setFilterDate('')
            })
            .catch(apiErrorHandler)
      })
      .catch(apiErrorHandler)

    if (developerMode) {
      DeveloperControllerService.getAllWorkspaces()
        .then(setWorkspaces)
        .catch(apiErrorHandler)
    }
  }, [workspaceId, rankingType])

  const handleRankingType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRankingType(e.target.value)
    setFilterDate('')
    navigate('?page=1') // ページ番号をリセット
  }

  const handleFilterDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterDate(e.target.value)
    navigate('?page=1') // ページ番号をリセット
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav">
        <div className="main_sidenav__inner ranking_page__inner">
          <div className="ranking_page__block">
            {developerMode && workspaces.length !== 0 && (
              <div className="ranking_page__content">
                <div className="form">
                  <div className="form__item">
                    <label htmlFor="workspace" className="form__label">
                      ワークスペース
                    </label>
                    <select
                      className="form__select video-setting__select"
                      value={workspaceId}
                      onChange={e => setWorkspaceId(e.target.value)}
                    >
                      <option value="">選択してください</option>
                      {workspaces.map((workspace, index) => (
                        <option key={index} value={workspace.workspaceId}>
                          {workspace.workspaceName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="ranking_page__block">
            <div className="ranking_page__content">
              <h3 className="ranking-page__lead">
                <div className="ranking-page_sort-text">
                  <span className="sort-icon" />
                  <select onChange={handleRankingType}>
                    {rankingTypeMaps.map((rankingTypeMap, index) => (
                      <option value={rankingTypeMap.name} key={index}>
                        {rankingTypeMap.label}
                      </option>
                    ))}
                  </select>
                  <div className="arrow-down-icon-wrapper">
                    <span className="arrow-down-icon" />
                  </div>
                </div>
                <div className="ranking-page_sort-text">
                  <span className="sort-icon" />
                  <select onChange={handleFilterDateChange} value={filterDate}>
                    <option value="">全期間</option>
                    {rankingQueries
                      .map(rankingQuery => {
                        const date = new Date(rankingQuery.createdAt)
                        const year = date.getFullYear()
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          '0',
                        )
                        return { year, month }
                      })
                      .filter(
                        (date, index, self) =>
                          self.findIndex(
                            d => d.year === date.year && d.month === date.month,
                          ) === index,
                      )
                      .sort((a, b) => {
                        if (a.year < b.year) return 1
                        if (a.year > b.year) return -1
                        if (a.month < b.month) return 1
                        if (a.month > b.month) return -1
                        return 0
                      })
                      .map((date, index) => (
                        <option
                          value={`${date.year}-${date.month}`}
                          key={index}
                        >
                          {`${date.year}年${date.month}月`}
                        </option>
                      ))}
                  </select>
                  <div className="arrow-down-icon-wrapper">
                    <span className="arrow-down-icon" />
                  </div>
                </div>
              </h3>
              <RankingTable
                developerMode={developerMode}
                workspaceName={
                  workspaces.find(
                    workspace => workspace.workspaceId === workspaceId,
                  )?.workspaceName
                }
                rankingTypeMaps={rankingTypeMaps}
                rankingType={rankingType}
                headContents={headContents}
                rankingQueries={rankingQueries.filter(rankingQuery => {
                  const date = new Date(rankingQuery.createdAt)
                  const year = date.getFullYear()
                  const month = String(date.getMonth() + 1).padStart(2, '0')
                  return filterDate === '' || `${year}-${month}` === filterDate
                })}
                filterDate={filterDate}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
