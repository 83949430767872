import {
  ApiError,
  FormValidationErrors,
  MemberControllerService,
  MemberLogoutControllerService,
} from '../services'
import { toast } from 'react-toastify'

const apiErrorHandler = async (error: ApiError) => {
  // 未認証
  if (error.status === undefined) {
    // 認証されていなかったらログイン画面へリダイレクト
    window.location.href = '/login'
    return
  }
  // 認証エラー
  if (error.status === 401) {
    // ログアウトを実行してログイン画面へ遷移
    try {
      await MemberLogoutControllerService.logout()
      window.location.href = '/login'
    } catch (err) {
      apiErrorHandler(err as ApiError)
    }
    return
  }
  // 権限エラー
  if (error.status === 403 || error.status === 404) {
    MemberControllerService.getLoggedInMember().then(loggedInMember => {
      const isUserLoggedIn = !!loggedInMember.memberId
      // ログインしていればホーム画面へ遷移
      isUserLoggedIn
        ? (window.location.href = '/not-found')
        : (window.location.href = '/login')
    })
    return
  }
  // 通常エラー
  if (error.status === 400) {
    const formErrors = error.body.formErrors as FormValidationErrors
    formErrors
      ? Object.values(formErrors).forEach(value => {
          // @ts-ignore
          toast.error(value)
        })
      : toast.error(error.body.errorMessage)
    return
  }
  // その他のエラーはトースト表示
  toast.error(error.body.errorMessage)
  return
}

export default apiErrorHandler
