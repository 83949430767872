import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { Footer } from '../../layouts/footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import {
  InviterWorkspaceControllerService,
  MemberRegistrationControllerService,
} from '../../services'
import axios from 'axios'
import env from '../../config/env'
import { Button } from '../../components/button'

export const MemberRegistration: FC = () => {
  const queryParams = new URLSearchParams(location.search)

  const navigate = useNavigate()

  const [inviteWorkspaceName, setInviteWorkspaceName] = useState('')
  const [isAtiveInvitetionUrl, setIsAtiveInvitetionUrl] = useState(true)

  const [memberRegistrationForm, setMemberRegistrationForm] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  })

  const [errorMessageMap, setErrorMessageMap] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    samePassword: '',
    privacyPolicy: '',
  })

  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  const togglePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy)
  }

  const registerMember = () => {
    if (!privacyPolicy) {
      setErrorMessageMap({
        email: '',
        password: '',
        passwordConfirm: '',
        samePassword: '',
        privacyPolicy: 'プライバシーポリシーに同意してください',
      })
      return
    }

    MemberRegistrationControllerService.registerMember({
      email: memberRegistrationForm.email,
      password: memberRegistrationForm.password,
      passwordConfirm: memberRegistrationForm.passwordConfirm,
      workspaceInvitationToken: queryParams.get(
        'workspaceInvitationToken',
      ) as string,
    })
      .then(() => {
        login(memberRegistrationForm.email, memberRegistrationForm.password)
      })
      .catch(err => {
        setErrorMessageMap(err.body.formErrors)
      })
  }

  const login = (email: string, password: string) => {
    const params = new URLSearchParams()
    params.append('username', email)
    params.append('password', password)

    axios
      .post(`${env.BACKEND_BASE_URL}/api/sign-in`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      })
      .then(() => {
        navigate('/sign-up')
      })
  }

  useEffect(() => {
    const getInviterWorkspaceName = () => {
      InviterWorkspaceControllerService.getInviterWorkspaceName(
        queryParams.get('workspaceInvitationToken') as string,
      ).then(res => {
        setInviteWorkspaceName(res)
        if (!res) {
          setIsAtiveInvitetionUrl(false)
        }
      })
    }

    getInviterWorkspaceName()
  }, [location.search])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">新規登録</h1>
          <hr className="separator" />
          {isAtiveInvitetionUrl && (
            <>
              <p className="form__check form__check-center">
                <input
                  type="checkbox"
                  id="privacy-policy"
                  name="privacy-policy"
                  onChange={togglePrivacyPolicy}
                  checked={privacyPolicy}
                />
                <label htmlFor="privacy-policy">
                  プライバシーポリシーに同意する
                </label>
              </p>
              <p className="form__link signup">
                <a href="/privacy-policy" target="_blank">
                  プライバシーポリシー
                </a>
              </p>
              <hr className="separator" />
              {errorMessageMap && (
                <>
                  <p className="form__item">
                    {errorMessageMap.privacyPolicy && (
                      <>
                        <span className="form__error">
                          {errorMessageMap.privacyPolicy}
                        </span>
                      </>
                    )}

                    {errorMessageMap.email && (
                      <>
                        <span className="form__error">
                          {errorMessageMap.email}
                        </span>
                      </>
                    )}
                    {errorMessageMap.password && (
                      <>
                        <span className="form__error">
                          {errorMessageMap.password}
                        </span>
                      </>
                    )}
                    {errorMessageMap.passwordConfirm && (
                      <>
                        <span className="form__error">
                          {errorMessageMap.passwordConfirm}
                        </span>
                      </>
                    )}
                    {errorMessageMap.samePassword && (
                      <>
                        <span className="form__error">
                          {errorMessageMap.samePassword}
                        </span>
                      </>
                    )}
                    {Object.entries(errorMessageMap)
                      .filter(([key]) => !key)
                      .map(([, value]) => (
                        <>
                          <span className="form__error">{value}</span>
                        </>
                      ))}
                    <br />
                  </p>
                </>
              )}
            </>
          )}

          {inviteWorkspaceName && (
            <>
              <b>{inviteWorkspaceName}</b>に招待されています
            </>
          )}

          {!isAtiveInvitetionUrl && (
            <p className="form__errorMessage">
              ※招待URLが間違っているか、有効期限が切れています。
              <br />
              ワークスペース管理者に連絡し、再度招待URLを発行してもらってください。
            </p>
          )}

          {isAtiveInvitetionUrl && (
            <>
              <p className="form__item">
                <label className="form__label">
                  メールアドレス
                  <input
                    type="email"
                    name="email"
                    onChange={e =>
                      setMemberRegistrationForm({
                        ...memberRegistrationForm,
                        email: e.target.value,
                      })
                    }
                  />
                </label>
              </p>
              <p className="form__item">
                <label className="form__label">
                  パスワード(8~20文字以内・英数字必須)
                  <input
                    type="password"
                    name="password"
                    onChange={e =>
                      setMemberRegistrationForm({
                        ...memberRegistrationForm,
                        password: e.target.value,
                      })
                    }
                  />
                </label>
              </p>
              <p className="form__item">
                <label className="form__label">
                  確認用パスワード
                  <input
                    type="password"
                    name="passwordConfirm"
                    onChange={e =>
                      setMemberRegistrationForm({
                        ...memberRegistrationForm,
                        passwordConfirm: e.target.value,
                      })
                    }
                  />
                </label>
              </p>
              <div className="custom-login-button">
                <Button className="btn" onClick={registerMember}>
                  新規登録
                </Button>
              </div>
            </>
          )}
        </div>
      </main>
      <Footer />
    </>
  )
}
