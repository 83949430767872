import React, { FC } from 'react'
import { Text } from '@visx/text'
import { scaleLog } from '@visx/scale'
import Wordcloud from '@visx/wordcloud/lib/Wordcloud'

export interface WordData {
  text: string
  value: number
}

export interface Props {
  words: WordData[]
  dimensions: { width: number; height: number }
}

const colors = ['#007559', '#89c61e', '#212643']

export const ParksWordCloud: FC<Props> = ({ words, dimensions }) => {
  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map(w => w.value)),
      Math.max(...words.map(w => w.value)),
    ],
    range: [10, 100],
  })
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value)
  const fixedValueGenerator = () => 0.5

  return (
    <div className="wordcloud">
      <Wordcloud
        words={words}
        width={dimensions.width}
        height={dimensions.height}
        fontSize={fontSizeSetter}
        font={'Roboto'}
        padding={2}
        rotate={0}
        random={fixedValueGenerator}
      >
        {cloudWords =>
          cloudWords.map((word, number) => (
            <Text
              key={word.text}
              fill={colors[number % colors.length]}
              textAnchor={'middle'}
              transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
              fontSize={word.size}
              fontFamily={word.font}
            >
              {word.text}
            </Text>
          ))
        }
      </Wordcloud>
    </div>
  )
}

export default ParksWordCloud
