import { FC, useState } from 'react'
import { Button } from '../../components/button'
import {
  CubeControllerService,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
  MemberQuery,
} from '../../services'
import React from 'react'
import apiErrorHandler from '../../api/apiErrorHandler'
import { SuggestMemberCard } from '../../components/suggestMemberCard'
import { SuggestTextInput } from '../../components/suggestTextInput'
import Loading from 'react-loading'
import { toast } from 'react-toastify'

interface Props {
  memberId?: number
  closeModal: () => void
}

export const CubeSendModal: FC<Props> = ({ memberId, closeModal }) => {
  const [modalPage, setModalPage] = useState<'SELECT' | 'EXPLAIN'>('SELECT')

  const [isLoading, setIsLoading] = useState(false)
  const [inputCubeMap, setInputCubeMap] = useState<Map<number, string>>(
    new Map(),
  )
  // チャットメンバー選択と同じコンポーネントを利用しているため配列で受け取る
  const [members, setMembers] = useState<MemberQuery[]>()
  const [cubeMap, setCubeMap] = useState<Map<number, string[]>>(new Map())

  // キューブごとに説明を設定するかどうか
  const [isIndividualExplanation, setIsIndividualExplanation] = useState(false)
  // 説明（共通）
  const [giftMessage, setGiftMessage] = useState<string>('')
  // 説明（個別）
  const [giftMessageMap, setGiftMessageMap] = useState<Map<number, string[]>>(
    new Map(),
  )

  const goPage = (page: 'SELECT' | 'EXPLAIN') => {
    setModalPage(page)
  }

  // キューブ登録
  const createCubes = () => {
    if (members?.length !== 1) {
      return
    }
    const memberCubeForms = Array.from(cubeMap)
      .map(([cubeCategoryId, cubes]) => {
        return cubes.map((cubeName, index) => {
          return {
            memberId: members[0].memberId,
            cubeCategoryId: cubeCategoryId,
            cubeName: cubeName,
            giftMessage: isIndividualExplanation
              ? giftMessageMap.get(cubeCategoryId)?.[index]
              : giftMessage,
          } as MemberCubeForm
        })
      })
      .flat()
    setIsLoading(true)
    MemberCubeControllerService.createMemberCubes({
      memberCubes: memberCubeForms,
    } as MemberCubesForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  // cube入力
  const setInput = (cubeCategoryId: number, value: string) => {
    const newInputCubeMap = new Map(inputCubeMap)
    newInputCubeMap.set(cubeCategoryId, value)
    setInputCubeMap(newInputCubeMap)
  }

  // cube追加
  const setCube = (cubeCategoryId: number, value: string) => {
    // 空文字や同名の場合は追加しない
    if (
      !value ||
      !value.match(/\S/g) ||
      cubeMap.get(cubeCategoryId)?.includes(value)
    ) {
      return
    }

    const newCubeMap = new Map(cubeMap)
    newCubeMap.set(cubeCategoryId, [
      ...(newCubeMap.get(cubeCategoryId) || []),
      value,
    ])
    setCubeMap(newCubeMap)
  }

  // cube削除
  const deleteCube = (cubeCategoryId: number, index: number) => {
    const newCubeMap = new Map(cubeMap)
    const cubes = newCubeMap.get(cubeCategoryId)
    if (cubes) {
      cubes.splice(index, 1)
      newCubeMap.set(cubeCategoryId, cubes)
      setCubeMap(newCubeMap)
    }
  }

  // サジェスト候補を取得
  const getSuggests = async (value: string): Promise<string[]> => {
    return await CubeControllerService.suggestCubes(value)
      .then(res => {
        return res.map(cube => cube.cubeName)
      })
      .catch(err => {
        apiErrorHandler(err)
        return []
      })
  }

  return (
    <div
      className="fixed bottom-0 top-[5%] w-full left-1/2 transform -translate-x-1/2 md:w-[590px] max-w-full overflow-hidden md:rounded-xl bg-white flex flex-col justify-start p-6 md:p-4 md:max-h-full rounded-t-xl md:top-1/2 md:bottom-auto md:flex md:transform md:-translate-x-1/2 md:-translate-y-1/2"
      role="dialog"
      aria-modal="true"
      aria-labelledby="send-cube__title"
    >
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-2xl md:text-3xl" id="send-cube__title">
          キューブを送る
        </h1>

        <Button className="col-start-3 justify-self-end" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <div className="flex flex-col">
          {modalPage === 'SELECT' && (
            <>
              <div className="mt-5 mb-1 md:mb-12 pb-4">
                <div
                  className={`${memberId && members?.length === 0 && 'hidden'}`}
                >
                  <SuggestMemberCard
                    defaultMemberIds={
                      memberId
                        ? [memberId]
                        : members?.length === 1
                        ? [members[0].memberId]
                        : []
                    }
                    setMembers={setMembers}
                  />
                </div>
                {members?.length === 1 && (
                  <div className="max-h-[350px] md:max-h-[330px] overflow-y-auto">
                    {members[0].cubeCategories.map((cubeCategory, index) => (
                      <React.Fragment key={index}>
                        <label className="font-bold text-sm block text-left mb-2">
                          {cubeCategory.cubeCategoryName}
                        </label>
                        <div className="relative rounded-2xl bg-gray-100">
                          <div className="icon-center left-3">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.50016 2.27687C9.80956 2.09824 10.1908 2.09824 10.5002 2.27687L16.4387 5.7055C16.7481 5.88414 16.9387 6.21426 16.9387 6.57153V13.4288C16.9387 13.7861 16.7481 14.1162 16.4387 14.2948L10.5002 17.7235C10.1908 17.9021 9.80956 17.9021 9.50016 17.7235L3.5616 14.2948C3.2522 14.1162 3.0616 13.7861 3.0616 13.4288V6.57153C3.0616 6.21426 3.2522 5.88414 3.5616 5.7055L9.50016 2.27687Z"
                                stroke="#008666"
                                strokeWidth="2"
                              />
                              <path
                                d="M10 10V17.9167"
                                stroke="#008666"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                              <path
                                d="M10 10L3.33318 6.25"
                                stroke="#008666"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                              <path
                                d="M10 10L16.6667 6.25"
                                stroke="#008666"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                          <SuggestTextInput
                            className="block w-full h-full p-3 pl-10 rounded-2xl text-base text-left"
                            placeholder="キューブを入力"
                            value={
                              inputCubeMap.get(cubeCategory.cubeCategoryId) ||
                              ''
                            }
                            setValue={value =>
                              setInput(cubeCategory.cubeCategoryId, value)
                            }
                            onSelected={value => {
                              setCube(cubeCategory.cubeCategoryId, value)
                            }}
                            getSuggests={getSuggests}
                          />
                        </div>
                        <ul className="flex flex-wrap mt-4 mb-4 gap-2">
                          {Array.from(cubeMap)
                            .filter(
                              ([cubeCategoryId]) =>
                                cubeCategoryId === cubeCategory.cubeCategoryId,
                            )
                            .map(([cubeCategoryId, cubes]) =>
                              cubes.map((cubeName, index) => (
                                <li
                                  className="relative p-1.5 pr-6 pl-2 border border-blue-200 bg-blue-50 inline-block rounded font-bold text-sm text-center"
                                  key={index}
                                >
                                  {cubeName}
                                  <Button
                                    className="block absolute top-1/2 right-1 -translate-y-1/2 cursor-none"
                                    onClick={() =>
                                      deleteCube(cubeCategoryId, index)
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="25"
                                      viewBox="0 0 24 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="cursor-pointer"
                                    >
                                      <path
                                        d="M13.1977 12.5002L16.5496 9.14828C16.7087 8.98948 16.7982 8.77399 16.7984 8.54921C16.7986 8.32444 16.7095 8.10879 16.5507 7.94971C16.3919 7.79062 16.1764 7.70114 15.9516 7.70094C15.7268 7.70075 15.5112 7.78985 15.3521 7.94865L12.0002 11.3006L8.64828 7.94865C8.4892 7.78957 8.27344 7.7002 8.04846 7.7002C7.82349 7.7002 7.60773 7.78957 7.44865 7.94865C7.28957 8.10773 7.2002 8.32349 7.2002 8.54846C7.2002 8.77344 7.28957 8.9892 7.44865 9.14828L10.8006 12.5002L7.44865 15.8521C7.28957 16.0112 7.2002 16.227 7.2002 16.4519C7.2002 16.6769 7.28957 16.8927 7.44865 17.0517C7.60773 17.2108 7.82349 17.3002 8.04846 17.3002C8.27344 17.3002 8.4892 17.2108 8.64828 17.0517L12.0002 13.6998L15.3521 17.0517C15.5112 17.2108 15.727 17.3002 15.9519 17.3002C16.1769 17.3002 16.3927 17.2108 16.5517 17.0517C16.7108 16.8927 16.8002 16.6769 16.8002 16.4519C16.8002 16.227 16.7108 16.0112 16.5517 15.8521L13.1977 12.5002Z"
                                        fill="#212643"
                                      />
                                    </svg>
                                  </Button>
                                </li>
                              )),
                            )}
                        </ul>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
              <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
                <Button
                  className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                  onClick={closeModal}
                >
                  キャンセル
                </Button>
                <Button
                  className="w-32 px-6 py-3 flex justify-center items-center btn-primary-base"
                  onClick={() => {
                    goPage('EXPLAIN')
                  }}
                  disabled={members?.length !== 1 || cubeMap.size === 0}
                >
                  次へ
                </Button>
              </div>
            </>
          )}
          {modalPage === 'EXPLAIN' && (
            <>
              <div className="mt-6 mb-[100px]">
                <label className="font-bold text-sm block text-left">
                  説明
                </label>
                {/* cubeMapの値が2つ以上なら表示 */}
                {Array.from(cubeMap)
                  .map(([, cubes]) => cubes)
                  .flat().length > 1 && (
                  <div className="flex items-center justify-between w-full">
                    <label htmlFor="isCommonExplanation" className="text-sm">
                      キューブごとに設定する
                    </label>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        id="isCommonExplanation"
                        name="isCommonExplanation"
                        checked={isIndividualExplanation}
                        onChange={() =>
                          setIsIndividualExplanation(!isIndividualExplanation)
                        }
                        className="sr-only peer"
                      />
                      <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green"></div>
                    </label>
                  </div>
                )}
                {isIndividualExplanation ? (
                  // キューブごとに説明を設定する場合
                  <div className="max-h-[600px] overflow-y-auto">
                    {Array.from(cubeMap).map(([cubeCategoryId, cubes]) =>
                      cubes.map((cubeName, index) => (
                        <React.Fragment key={index}>
                          <label className="font-bold text-sm leading-6 block text-left">
                            {members
                              ? members[0]?.cubeCategories.find(
                                  category =>
                                    category.cubeCategoryId === cubeCategoryId,
                                )?.cubeCategoryName
                              : ''}
                          </label>
                          <ul className="flex flex-wrap mt-4 mb-4 gap-2">
                            <li className="relative inline-block px-2 py-1.5 border border-sky-200 bg-[#e8f1fe] cursor-pointer rounded font-bold text-sm text-center">
                              {cubeName}
                            </li>
                          </ul>
                          <label className="font-bold text-sm leading-6 block text-left">
                            <textarea
                              className="block w-full mt-1 p-3 rounded-xl bg-gray-100 font-normal text-base resize-none"
                              value={
                                giftMessageMap.get(cubeCategoryId)?.[index]
                              }
                              placeholder="きっかけや理由などをコメントしましょう！（任意）"
                              onChange={e => {
                                const newExplanationMap = new Map(
                                  giftMessageMap,
                                )
                                const newExplanations =
                                  newExplanationMap.get(cubeCategoryId)
                                if (newExplanations) {
                                  newExplanations[index] = e.target.value
                                  newExplanationMap.set(
                                    cubeCategoryId,
                                    newExplanations,
                                  )
                                } else {
                                  newExplanationMap.set(cubeCategoryId, [
                                    e.target.value,
                                  ])
                                }
                                setGiftMessageMap(newExplanationMap)
                              }}
                              style={{ height: '10rem' }}
                            ></textarea>
                          </label>
                        </React.Fragment>
                      )),
                    )}
                  </div>
                ) : (
                  // 全てのキューブに共通の説明を設定する場合
                  <div className="max-h-[600px] overflow-y-auto">
                    <ul className="flex flex-wrap mt-4 mb-4 gap-2">
                      {Array.from(cubeMap).map(([, cubes]) =>
                        cubes.map((cubeName, index) => (
                          <li
                            className="relative inline-block px-2 py-1.5 border border-sky-200 bg-[#e8f1fe] cursor-pointer rounded font-bold text-sm text-center"
                            key={index}
                          >
                            {cubeName}
                          </li>
                        )),
                      )}
                    </ul>
                    <label className="font-bold text-sm leading-6 block text-left">
                      <textarea
                        className="block w-full mt-1 p-3 rounded-xl bg-gray-100 font-normal text-base resize-none"
                        value={giftMessage}
                        placeholder="きっかけや理由などをコメントしましょう！（任意）"
                        onChange={e => setGiftMessage(e.target.value)}
                        style={{ height: '10rem' }}
                      ></textarea>
                    </label>
                  </div>
                )}
              </div>
              <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
                <Button
                  className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                  onClick={() => {
                    goPage('SELECT')
                  }}
                >
                  戻る
                </Button>
                <Button
                  className="px-6 py-3 flex justify-center items-center btn-primary-base"
                  onClick={createCubes}
                  disabled={members?.length !== 1 || cubeMap.size === 0}
                >
                  キューブを送る
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
