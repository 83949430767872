export const SESSION_KEY = {
  TIMELINE: {
    PAGE_NAME: 'timeline',
    STORAGE_KEY: 'timelineSession',
    DETAIL_PATH_KEY: 'timelineDetailPathFromTimeline',
  },
  QUESTION: {
    PAGE_NAME: 'question',
    STORAGE_KEY: 'questionSession',
    DETAIL_PATH_KEY: 'timelineDetailPathFromQuestion',
  },
}
