import { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { HeaderType } from '../../enums/HeaderType'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { EmailSetting } from '../../enums/EmailSetting'
import { MemberEmailSettingControllerService } from '../../services'

export const Setting: FC = () => {
  const [emailSettings, setEmailSettings] = useState([] as EmailSetting[])

  useEffect(() => {
    MemberEmailSettingControllerService.getMemberEmailSettings()
      // @ts-ignore
      .then(setEmailSettings)
      .catch(apiErrorHandler)
  }, [])

  const isInclude = (key: EmailSetting) => {
    return emailSettings.includes(key)
  }

  const toggleSettings = (key: EmailSetting) => {
    let newEmailSettings = [] as EmailSetting[]
    if (isInclude(key)) {
      newEmailSettings = emailSettings.filter(k => k !== key)
    } else {
      newEmailSettings = [...emailSettings, key]
    }

    MemberEmailSettingControllerService.updateMemberEmailSettings({
      // @ts-ignore
      memberEmailSettingTypes: newEmailSettings,
    })
      .then(() => {
        toast.success('設定を更新しました')
        setEmailSettings(newEmailSettings)
      })
      .catch(err => {
        toast.error('更新に失敗しました')
        apiErrorHandler(err)
      })
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.SETTING} />
      <SideNavi />
      <main className="main-sidenav">
        <div className="main_sidenav__inner main_setting__inner">
          {/* 通知設定 */}
          <div className="setting__content">
            <section className="setting__block is-selected timeline-settings__item">
              <h2 className="setting__detail-title">メール通知</h2>
              <p className="setting__detail-text">
                設定したお知らせのほかにparksから重要なお知らせが送信される場合があります。
              </p>
              <div className="setting__detail">
                <fieldset>
                  <div className="setting__detail-item">
                    {Object.entries(EmailSetting).map(([key, value]) => (
                      <div key={key}>
                        <input
                          type="checkbox"
                          id={key}
                          name={key}
                          checked={emailSettings.includes(key as EmailSetting)}
                          onChange={() => toggleSettings(key as EmailSetting)}
                        />
                        <label htmlFor={key}>{value}</label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  )
}
