import React, { FC } from 'react'
import Linkify from 'linkify-react'

interface SafeParagraphProps {
  content?: string | null
  className?: string
  dangerouslySetInnerHTML?: boolean
  cleanDangerousContent?: (dangerousContent: string) => string
}

export const SafeParagraph: FC<SafeParagraphProps> = ({
  content,
  className,
  dangerouslySetInnerHTML = false,
  cleanDangerousContent,
}) => {
  const linkifyOptions = {
    className: 'link',
    attributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }

  const cleanContent = (dangerousContent: string | null | undefined) => {
    if (!dangerousContent) return ''
    return cleanDangerousContent?.(dangerousContent) || dangerousContent
  }

  return (
    <Linkify
      as="p"
      className={className}
      options={linkifyOptions}
      style={{ whiteSpace: 'pre-wrap' }}
    >
      {dangerouslySetInnerHTML ? (
        <div dangerouslySetInnerHTML={{ __html: cleanContent(content) }} />
      ) : (
        <>{content || ''}</>
      )}
    </Linkify>
  )
}
