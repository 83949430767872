import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { QuestionCard } from '../../components/question/questionCard'

import {
  DeveloperControllerService,
  QuestionControllerService,
  QuestionQuery,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  answerQuestionsList,
  deleteQuestionFromList,
} from '../../lib/questionActions'
import { useLocation, useNavigate } from 'react-router-dom'
import env from '../../config/env'
import { SESSION_KEY } from '../../constants/session'
import _ from 'lodash'
import Loading from 'react-loading'
import { toast } from 'react-toastify'

const TABS = [
  { filter: 'NOT_ANSWERED', name: '未回答' },
  { filter: 'ANSWERED', name: '回答済み' },
  { filter: 'YOURS', name: 'あなたの質問' },
  { filter: 'MOST_ANSWERED', name: '回答が多い質問' },
  { filter: 'ALL', name: 'すべて' },
]

type Filter = (typeof TABS)[number]['filter']

const isValidFilter = (tab: any): tab is Filter =>
  TABS.some(t => t.filter === tab)

interface QuestionSessionState {
  activeFilter: string
  questions: QuestionQuery[]
  page: number
  hasMore: boolean
  path: string
}

interface QuestionProps {
  developerMode?: boolean
}

export const Question: FC<QuestionProps> = ({ developerMode = false }) => {
  const navigate = useNavigate()
  const isFirstRender = useRef(true)

  const query = new URLSearchParams(useLocation().search)
  const filter = query.get('filter')

  const [activeFilter, setActiveFilter] = React.useState<Filter>(
    isValidFilter(filter) ? filter : TABS[0].filter,
  )
  const [questions, setQuestions] = React.useState<QuestionQuery[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isShared, setIsShared] = useState(false)

  // 初回レンダリング時に1ページ目のデータを取得
  const firstFetch = () => {
    const defaultPage = 1
    setQuestions([])
    setIsLoading(true)
    setPage(defaultPage)
    // @ts-ignore
    QuestionControllerService.getQuestions(activeFilter, defaultPage)
      .then(res => {
        if (res.length === 0) {
          setHasMore(false)
        } else {
          setQuestions(res)
          setPage(defaultPage + 1)
          setHasMore(true)
        }
        setIsLoading(false)
      })
      .catch(apiErrorHandler)
  }

  // ページング用のデータを取得
  const fetch = () => {
    setIsFetching(true)
    // @ts-ignore
    QuestionControllerService.getQuestions(activeFilter, page).then(res => {
      if (res.length === 0) {
        setHasMore(false)
      } else {
        setQuestions(oldData => [...oldData, ...res])
        setPage(oldPage => oldPage + 1)
      }
      setIsFetching(false)
    })
  }

  // 初回レンダリング時に1ページ目のデータを取得
  useLayoutEffect(() => {
    if (developerMode) {
      // アカウントを確認
      DeveloperControllerService.getDeveloperPositions().catch(apiErrorHandler)
    }

    const questionSessionStr = sessionStorage.getItem(
      SESSION_KEY.QUESTION.STORAGE_KEY,
    )
    if (questionSessionStr && env.ENV !== 'mock') {
      // セッションを取得できた場合はデータを復元
      try {
        const sessionState = JSON.parse(
          questionSessionStr,
        ) as QuestionSessionState
        const timelineDetailPath = sessionStorage.getItem(
          SESSION_KEY.QUESTION.DETAIL_PATH_KEY,
        )
        // タイムライン詳細から戻った場合はセッションを復元
        if (timelineDetailPath === sessionState.path) {
          setActiveFilter(sessionState.activeFilter)
          setQuestions(sessionState.questions)
          setPage(sessionState.page)
          setHasMore(sessionState.hasMore)
          setIsLoading(false)
        }
      } catch (e) {
        firstFetch()
        console.log(e)
      } finally {
        // セッションを削除
        sessionStorage.removeItem(SESSION_KEY.QUESTION.STORAGE_KEY)
        sessionStorage.removeItem(SESSION_KEY.QUESTION.DETAIL_PATH_KEY)
      }
    } else {
      firstFetch()
    }
  }, [activeFilter])

  // スクロール位置の検出
  useEffect(() => {
    // 初回レンダリング時はスクロールイベントを設定しない
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const handleScroll = _.throttle(() => {
      // データ取得を開始する閾値を設定
      const threshold = 200
      // スクロールが閾値以下になったらデータフェッチを開始
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - threshold
      ) {
        return
      }
      // 追加のデータが無い場合、またはデータをフェッチ中の場合は処理を終了
      if (!hasMore || isFetching) {
        return
      }
      fetch()
    }, 1000) // 1秒間に最大1回だけイベントハンドラが実行されるようにスロットリング

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [page, hasMore, isFetching])

  const handleTab = (filter: Filter) => {
    setActiveFilter(filter)
    if (developerMode) {
      navigate(`/developer/question?filter=${filter}`)
    } else {
      navigate(`/question?filter=${filter}`)
    }
  }

  const onMoreViewCommentClick = (timelineId: number) => {
    const timelineDetailPath = `/timeline-detail/${timelineId}`
    const state = {
      activeFilter: activeFilter,
      questions: questions,
      page: page,
      hasMore: hasMore,
      path: timelineDetailPath,
    } as QuestionSessionState
    sessionStorage.setItem(
      SESSION_KEY.QUESTION.STORAGE_KEY,
      JSON.stringify(state),
    )
    navigate(timelineDetailPath, {
      state: { from: SESSION_KEY.QUESTION.PAGE_NAME },
    })
  }

  const answerQuestion = (questionId: number, choiceId: number) => {
    answerQuestionsList(
      questionId,
      choiceId,
      questions,
      setQuestions,
      apiErrorHandler,
    )
  }

  const deleteQuestion = (questionId: number) => {
    deleteQuestionFromList(questionId, questions, setQuestions, apiErrorHandler)
  }

  const share = (question: QuestionQuery) => {
    if (isShared) return
    if (!window.confirm('この質問を他のワークスペースに共有しますか？')) return
    DeveloperControllerService.createQuestions({
      question: question.question,
      choices: question.choices.map(choice => choice.choice),
      anonymousFlg: false,
    })
      .then(() => {
        toast.success('質問を共有しました')
        setIsShared(true)
      })
      .catch(apiErrorHandler)
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="w-full mt-[52px] md:ml-auto md:w-[calc(100%-223px)] md:mt-[72px]">
        <div className="max-w-[1800px] md:mx-auto md:my-[26px] md:px-[40px] md:py-[0px] p-0 mb-[55px] mx-0 mt-0">
          <ul className="flex gap-4 list-none font-bold whitespace-nowrap overflow-x-auto md:p-0 md:h-[54px] h-[72px] pt-[16px] pl-[16px]">
            {TABS.map(tab => (
              <li key={tab.filter}>
                {/* is-activeクラスで選択中のタブの色を変える */}
                <a
                  className={`pb-[12px] md:text-xs text-sm transition-all cursor-pointer ${
                  activeFilter === tab.filter ? 'border-b-4 border-green hover:opacity-100' : 'opacity-70'
                }`}
                  onClick={() => handleTab(tab.filter)}
                >
                  {tab.name}
                </a>
              </li>
            ))}
          </ul>

          <div>
            <ul className="mt-[0px] md:mt-[16px] flex flex-col gap-2 md:gap-4">
              {isLoading && (
                <Loading className="mt-[90px] mb-[90px] mx-auto" type="spin" color="#007559" />
              )}
              {!isLoading && questions.length === 0 && (
                <li className="bg-white shadow-revert cursor-pointer md:shadow-card md:rounded-xl rounded-2xl md:p-6 p-4">
                  <p className="block mt-[4px] ml-auto text-[#858a99] text-xs">
                    対象の質問がありません
                  </p>
                </li>
              )}
              {!isLoading &&
                questions.map(question => (
                  <QuestionCard
                    key={question.questionId}
                    question={question}
                    activeFilter={activeFilter}
                    answerQuestion={answerQuestion}
                    deleteQuestion={deleteQuestion}
                    onMoreViewCommentClick={onMoreViewCommentClick}
                    onShare={developerMode && !isShared ? share : undefined}
                    developerMode={developerMode}
                  />
                ))}
              {!isLoading && isFetching && (
                <Loading className="mt-[90px] mb-[90px] mx-auto" type="spin" color="#007559" />
              )}
            </ul>
          </div>
        </div>
      </main>
    </>
  )
}
