/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimelineDetailQuery } from '../models/TimelineDetailQuery';
import type { TimelineQuery } from '../models/TimelineQuery';
import type { TimelineUnreadCount } from '../models/TimelineUnreadCount';
import type { UpdateCubeCommentInfo } from '../models/UpdateCubeCommentInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimelineControllerService {

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static createTimelineJoin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/timeline/join',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param page
     * @param limit
     * @returns TimelineQuery OK
     * @throws ApiError
     */
    public static getTimeline(
        page: number = 1,
        limit: number = 10,
    ): CancelablePromise<TimelineQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param memberCubeId
     * @returns UpdateCubeCommentInfo OK
     * @throws ApiError
     */
    public static getLatestUpdateMemberCubeTimelineId(
        memberCubeId: number,
    ): CancelablePromise<UpdateCubeCommentInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline/update-member-cube/{memberCubeId}',
            path: {
                'memberCubeId': memberCubeId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns TimelineUnreadCount OK
     * @throws ApiError
     */
    public static getCountUnread(): CancelablePromise<TimelineUnreadCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline/unread/count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param timelineId
     * @returns TimelineDetailQuery OK
     * @throws ApiError
     */
    public static getTimelineDetail(
        timelineId: number,
    ): CancelablePromise<TimelineDetailQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline-detail',
            query: {
                'timelineId': timelineId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
