import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MemberQuery } from '../../services'
import { pickupCubes } from '../../lib/common'
import ChatButton from '../button/chatButton'
import CubeList from './CubeList'

interface MemberCardProps {
  member: MemberQuery
  className: string
  cubeListClassName: string
  cubeItemClassName: string
}

const MemberCard: React.FC<MemberCardProps> = ({
  member,
  className,
  cubeListClassName,
  cubeItemClassName,
}) => {
  const navigate = useNavigate()

  const handleMemberClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(`/member-detail/${member.memberId}`)
  }

  const profileImageUrl = member.profileImageUrl || '/icons/avatar-sample.png'

  return (
    <li className={className} onClick={handleMemberClick}>
      <div className="card_list-m">
        <div className="card_list-m__head">
          <div className="card_list-m__profile-wrapper">
            <div className="card_list-m_avatar">
              <picture>
                <source srcSet={profileImageUrl} media="(min-width: 768px)" />
                <img src={profileImageUrl} alt="プロフィール画像" />
              </picture>
            </div>

            <div className="card_list-m__profile">
              <div className="card_list-m_name__wrapper">
                <h3 className="card_list-m_name">{member.memberName}</h3>
                <p className="card_list-m_year_of_service">
                  {member.lengthOfService}
                </p>
              </div>
              <p className="card_list-m__position">
                {member.position?.positionName}
              </p>
            </div>
          </div>

          <div className="btn_card_list-m__wrapper">
            <ChatButton
              className="btn_secondary-s btn_card_list-m"
              memberId={member.memberId}
            />
          </div>
        </div>

        <div className="card_list-m__body">
          <CubeList
            cubes={pickupCubes(member.cubes)}
            className={cubeListClassName}
            itemClassName={cubeItemClassName}
          />
        </div>
      </div>
    </li>
  )
}

export default MemberCard
