import { FC } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { Footer } from '../../layouts/footer'
import { HeaderType } from '../../enums/HeaderType'
import { Button } from '../../components/button'

export const Signup: FC = () => {
  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">新規登録</h1>
          <form action="" method="post" className="form">
            <p className="form__item">
              <label className="form__label">
                お名前
                <input type="text" name="name" placeholder="山田 太郎" />
              </label>
            </p>
            <p className="form__item">
              <label className="form__label">
                メールアドレス
                <input
                  type="email"
                  name="email"
                  placeholder="sample@parks.co.jp"
                />
              </label>
            </p>
            <div className="form__item">
              <label className="form__label">
                パスワード
                <input
                  type="password"
                  name="password"
                  placeholder="○文字以上の英数字"
                />
              </label>
              <p className="password__form-text">
                8~20文字以内で半角英数字必須 （記号は.!_/`()[]+-=$#&@~\
                が使用可能）
              </p>
            </div>
            <p className="form__check">
              <input type="checkbox" id="unmask" name="unmask" />
              <label htmlFor="unmask">パスワードを表示</label>
            </p>

            <p className="form__submit">
              <Button type="submit" className="btn btn--disabled">
                新規登録
              </Button>
            </p>
            <p className="form__link signup__link">
              <a href="#">登録済みの方はログインへ</a>
            </p>
          </form>
        </div>
      </main>
      <Footer />
    </>
  )
}
