import React, { FC, useEffect, useState } from 'react'
import { SelectInput } from '../selectInput'
import { createDate, getDateOptions } from '../../lib/common'

interface Props {
  label: string
  periodStart?: string
  periodEnd?: string
  setPeriodStart: (dateString: string | undefined) => void
  setPeriodEnd: (dateString: string | undefined) => void
  periodStartDisabled?: boolean
  periodEndDisabled?: boolean
  error?: string | null | undefined
}

export const PeriodInput: FC<Props> = ({
  label,
  periodStart,
  periodEnd,
  setPeriodStart,
  setPeriodEnd,
  periodStartDisabled = false,
  periodEndDisabled = false,
  error,
}) => {
  const years = getDateOptions(1950, new Date().getFullYear())
  const months = getDateOptions(1, 12)

  const [selectedStartYear, setSelectedStartYear] = useState('')
  const [selectedStartMonth, setSelectedStartMonth] = useState('')
  const [selectedEndYear, setSelectedEndYear] = useState('')
  const [selectedEndMonth, setSelectedEndMonth] = useState('')

  useEffect(() => {
    if (periodStart) {
      const dateSplit = periodStart
        .replace(/[^0-9]/g, ' ')
        .trim()
        .split(' ')
      if (dateSplit.length === 3) {
        setSelectedStartYear(dateSplit[0])
        setSelectedStartMonth(dateSplit[1])
      }
    }
    if (periodEnd) {
      const dateSplit = periodEnd
        .replace(/[^0-9]/g, ' ')
        .trim()
        .split(' ')
      if (dateSplit.length === 3) {
        setSelectedEndYear(dateSplit[0])
        setSelectedEndMonth(dateSplit[1])
      }
    }
  }, [periodStart, periodEnd])

  const periodStartY = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setSelectedStartYear(value)
    if (value === '' || selectedStartMonth === '') {
      setPeriodStart(undefined)
    } else {
      const dateString = createDate(value, selectedStartMonth, '01')
      setPeriodStart(dateString)
    }
  }

  const periodStartM = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setSelectedStartMonth(value)
    if (value === '' || selectedStartYear === '') {
      setPeriodStart(undefined)
    } else {
      const dateString = createDate(selectedStartYear, value, '01')
      setPeriodStart(dateString)
    }
  }

  const periodEndY = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setSelectedEndYear(value)
    if (value === '' || selectedEndMonth === '') {
      setPeriodEnd(undefined)
    } else {
      const dateString = createDate(value, selectedEndMonth, '01')
      setPeriodEnd(dateString)
    }
  }

  const periodEndM = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setSelectedEndMonth(value)
    if (value === '' || selectedEndYear === '') {
      setPeriodEnd(undefined)
    } else {
      const dateString = createDate(selectedEndYear, value, '01')
      setPeriodEnd(dateString)
    }
  }
  // 年選択の初期値を指定
  const handleStartYearSelect = () => {
    if (!selectedStartYear) {
      setSelectedStartYear('2000')
    }
  }
  const handleEndYearSelect = () => {
    if (!selectedEndYear) {
      setSelectedEndYear('2000')
    }
  }

  return (
    <>
      <label className="form__label">{label}</label>

      <div className="signup-profile__period">
        <div>
          <span className="signup-profile__period-name">開始</span>
          <div className="form__select-wrapper signup-profile__form-select">
            <SelectInput
              options={years}
              selected={selectedStartYear}
              onChange={periodStartY}
              disabled={periodStartDisabled}
              onOpen={handleStartYearSelect}
            />
            <span>年</span>
            <SelectInput
              options={months}
              selected={selectedStartMonth}
              onChange={periodStartM}
              disabled={periodStartDisabled}
            />
            <span>月</span>
          </div>
        </div>

        <span className="signup-profile__period-between">~</span>

        {periodEndDisabled ? (
          <>
            <span className="signup-profile__period-between">現在</span>
          </>
        ) : (
          <div>
            <span className="signup-profile__period-name">終了</span>
            <div className="form__select-wrapper signup-profile__form-select">
              <SelectInput
                options={years}
                selected={selectedEndYear}
                onChange={periodEndY}
                disabled={periodEndDisabled}
                onOpen={handleEndYearSelect}
              />
              <span>年</span>
              <SelectInput
                options={months}
                selected={selectedEndMonth}
                onChange={periodEndM}
                disabled={periodEndDisabled}
              />
              <span>月</span>
            </div>
          </div>
        )}
      </div>
      {error && <div className="form__error_message">{error}</div>}
    </>
  )
}
