/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatMessageQuery } from '../models/ChatMessageQuery';
import type { ChatRoomAttendeeQuery } from '../models/ChatRoomAttendeeQuery';
import type { ChatRoomQuery } from '../models/ChatRoomQuery';
import type { MemberIdsForm } from '../models/MemberIdsForm';
import type { MemberIdsUpdateForm } from '../models/MemberIdsUpdateForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatRoomControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateChatRoomMembers(
        requestBody: MemberIdsUpdateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/chat-room-members',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static createChatRoom(
        requestBody: MemberIdsForm,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chat-room',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns ChatRoomQuery OK
     * @throws ApiError
     */
    public static getChatRooms(): CancelablePromise<Array<ChatRoomQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chat-rooms',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param chatRoomId
     * @returns ChatRoomAttendeeQuery OK
     * @throws ApiError
     */
    public static getChatRoomAttendees(
        chatRoomId: string,
    ): CancelablePromise<Array<ChatRoomAttendeeQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chat-rooms/{chatRoomId}',
            path: {
                'chatRoomId': chatRoomId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param chatRoomId
     * @returns ChatMessageQuery OK
     * @throws ApiError
     */
    public static getChatMessages(
        chatRoomId: string,
    ): CancelablePromise<Array<ChatMessageQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chat-rooms/{chatRoomId}/messages',
            path: {
                'chatRoomId': chatRoomId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
