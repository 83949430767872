/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationCardResultQuery } from '../models/NotificationCardResultQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationCardControllerService {

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static readAllNotificationCards(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notification-cards/read-all',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param exclusiveStartNotificationCardId
     * @param exclusiveStartNotificationNotifyAt
     * @param limit
     * @returns NotificationCardResultQuery OK
     * @throws ApiError
     */
    public static getNotificationCards(
        exclusiveStartNotificationCardId?: string,
        exclusiveStartNotificationNotifyAt?: string,
        limit: number = 20,
    ): CancelablePromise<NotificationCardResultQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification-cards',
            query: {
                'exclusiveStartNotificationCardId': exclusiveStartNotificationCardId,
                'exclusiveStartNotificationNotifyAt': exclusiveStartNotificationNotifyAt,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns number OK
     * @throws ApiError
     */
    public static getUnreadNotificationCardCount(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notification-cards/unread-count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
