import { useDroppable } from '@dnd-kit/core'
import { FC, ReactNode } from 'react'

type DroppableProp = {
  id: number
  children: ReactNode
}

export const Droppable: FC<DroppableProp> = ({ id, children }) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
  })

  return (
    <div
      ref={setNodeRef}
      style={{
        backgroundColor: isOver ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
        // 角丸
        borderRadius: 16,
        padding: '4px 16px 16px',
      }}
    >
      {children}
    </div>
  )
}
