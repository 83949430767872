import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Button } from '../button'

interface PaginationProps {
  currentPage: number
  totalPage: number
  goNext: () => void
  goBack: () => void
  goPage: (page: number) => void
}

export const Pagination: FC<PaginationProps> = ({
  currentPage = 1,
  totalPage = 1,
  goNext,
  goBack,
  goPage,
}) => {
  const [width, setWidth] = useState(window.innerWidth)
  const mobileWidth = 768

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const createBackButton = () => {
    const text = '前へ'
    if (currentPage === 1) {
      return (
        <Button className="btn_secondary-l btn_search_input-page-pale btn-disabled">
          {text}
        </Button>
      )
    } else {
      return (
        <Button className="btn btn_search_input-page-dark" onClick={goBack}>
          {text}
        </Button>
      )
    }
  }
  const createNextButton = () => {
    const text = '次へ'
    if (currentPage === totalPage) {
      return (
        <Button className="btn_secondary-l btn_search_input-page-pale btn-disabled">
          {text}
        </Button>
      )
    } else {
      return (
        <Button className="btn btn_search_input-page-dark" onClick={goNext}>
          {text}
        </Button>
      )
    }
  }

  const getPageNumbers = (currentPage: number, totalPage: number) => {
    // 表示するページ数（横幅によって調整）
    const visiblePages = width < mobileWidth ? 3 : 5
    const pageOffset = Math.floor(visiblePages / 2)

    const startPage = Math.max(currentPage - pageOffset, 1)
    const endPage = Math.min(currentPage + pageOffset, totalPage)

    const pages = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    // 最初のページと最後のページを表示する必要がある場合はページ数をvisiblePagesに調整する
    const extraPages = visiblePages - pages.length
    if (extraPages > 0 && startPage > 1) {
      const pagesToAdd = Math.min(extraPages, startPage - 1)
      for (let i = startPage - pagesToAdd; i < startPage; i++) {
        pages.unshift(i)
      }
      pages.sort((a, b) => a - b)
    }
    if (extraPages > 0 && endPage < totalPage) {
      const pagesToAdd = Math.min(extraPages, totalPage - endPage)
      for (let i = endPage + 1; i <= endPage + pagesToAdd; i++) {
        pages.push(i)
      }
    }

    return pages
  }

  const createPaginationNumbers = () => {
    const paginationNumberList: ReactElement[] = []
    const pageNumbers = getPageNumbers(currentPage, totalPage)
    pageNumbers.forEach(pageNumber => {
      const primaryFlg = pageNumber === currentPage
      paginationNumberList.push(
        <PaginationNumber
          key={pageNumber}
          page={pageNumber}
          goPage={goPage}
          primaryFlg={primaryFlg}
        />,
      )
    })
    return paginationNumberList
  }

  return totalPage > 1 ? (
    <div className="search_input-page__bottom-block">
      {createBackButton()}
      <ul className="search_input-page_pagination__list">
        {createPaginationNumbers()}
      </ul>
      {createNextButton()}
    </div>
  ) : (
    <></>
  )
}

interface PaginationNumberProps {
  page: number
  goPage: (page: number) => void
  primaryFlg: boolean
}

const PaginationNumber: FC<PaginationNumberProps> = ({
  page,
  goPage,
  primaryFlg,
}) => {
  return primaryFlg ? (
    <li className="search_input-page_pagination-list__item search_input-page_pagination-item-selected">
      {page}
    </li>
  ) : (
    <li
      className="search_input-page_pagination-list__item"
      onClick={() => {
        goPage(page)
      }}
    >
      {page}
    </li>
  )
}
