import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#app')

interface ModalInstance {
  component: FC<any>
  props: any
}

interface ModalContextType {
  modals: ModalInstance[]
  openModal: (component: FC<any>, props?: any) => void
  closeModal: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

interface ModalProviderProps {
  children: ReactNode
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<ModalInstance[]>([])

  const openModal = (component: FC<any>, props: any = {}) => {
    document.body.style.overflow = 'hidden'
    setModals([...modals, { component, props }])
    // .home__cube-btnにdisplay: noneを付与する
    const homeCubeBtn = document.querySelector('.home__cube-btn')
    if (homeCubeBtn) {
      homeCubeBtn.setAttribute('style', 'display: none')
    }
  }

  const closeModal = () => {
    document.body.style.overflow = 'auto'
    if (modals.length > 0) {
      const newModals = modals.slice(0, -1)
      setModals(newModals)
    }
    // .home__cube-btnにdisplay: blockを付与する
    const homeCubeBtn = document.querySelector('.home__cube-btn')
    if (homeCubeBtn) {
      homeCubeBtn.setAttribute('style', 'display: block')
    }
  }

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
      {modals.map((modal, index) => (
        <Modal
          key={index}
          isOpen={true}
          onRequestClose={closeModal}
          className="custom-modal"
          overlayClassName="custom-modal-overlay"
          closeTimeoutMS={200}
        >
          {React.createElement(modal.component, { ...modal.props, closeModal })}
        </Modal>
      ))}
    </ModalContext.Provider>
  )
}
