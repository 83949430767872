import { FC, KeyboardEvent } from 'react'
import { Button } from '../button'
import { MentionTargetMember } from '../../services'
import { Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions'

interface TimelineCommentInputProps {
  timelineCommentForm: string
  handleInputChange: OnChangeHandlerFunc
  handleKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  calculateTextAreaRows: () => number
  sendTimelineComment: (message: string) => void
  mentionTargetMembers: MentionTargetMember[]
  isFixed?: boolean
}

export const TimelineCommentInput: FC<TimelineCommentInputProps> = ({
  timelineCommentForm,
  handleInputChange,
  handleKeyDown,
  calculateTextAreaRows,
  sendTimelineComment,
  mentionTargetMembers,
  isFixed = false,
}) => {
  const data = mentionTargetMembers
    .filter(member => member.memberName !== '')
    .map(member => {
      return {
        id: member.memberId,
        memberId: member.memberId,
        display: member.memberName,
        email: member.email,
        profileImageUrl: member.profileImageUrl,
      }
    })

  const renderSuggestion = (
    suggestion: any,
    search: string,
    highlightedDisplay: any,
    index: number,
    focused: boolean,
  ) => {
    return (
      <div className={`mention-suggestion-item ${focused ? 'focused' : ''}`}>
        <img
          className="mention-suggestion-item__avatar"
          src={
            suggestion.profileImageUrl
              ? suggestion.profileImageUrl
              : '/icons/avatar-sample.png'
          }
          alt="プロフィール画像"
          width={32}
          height={32}
        />
        <div>{highlightedDisplay}</div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`form__label home-timeline__send-cube_btn-wrapper ${
          isFixed ? 'home-timeline__send-cube_btn-wrapper--fixed' : ''
        }`}
        onClick={event => event.stopPropagation()}
      >
        <MentionsInput
          className="input-mentions"
          placeholder="コメントを送る"
          value={timelineCommentForm}
          onKeyDownCapture={handleKeyDown}
          rows={calculateTextAreaRows()}
          onChange={handleInputChange}
          forceSuggestionsAboveCursor={true}
          allowSpaceInQuery={true}
        >
          <Mention
            trigger="@"
            data={data}
            displayTransform={(id, display) => `@${display} `}
            className="mentioned"
            markup="@###[__display__]###---###__id__###"
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>

        <div className="chat-input-container__button">
          <Button
            className={`btn chat__submit-btn ${
              timelineCommentForm === '' ? 'btn-disabled' : ''
            }`}
            disabled={timelineCommentForm === ''}
            onClick={event => {
              event.stopPropagation()
              sendTimelineComment(timelineCommentForm)
            }}
          >
            送信
          </Button>
        </div>
      </div>
    </>
  )
}
