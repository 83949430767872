import React from 'react'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  imageUrl?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      disabled,
      type = 'button',
      imageUrl,
      onClick,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        type={type}
        className={className}
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt="button-icon"
            className="inline-btn-with-margin-right"
          />
        )}
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'
