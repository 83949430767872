import React from 'react'
import { ChatMessageQuery } from '../../services'
import { getChatMessageAt } from '../../lib/chatUtils'
import { SafeParagraph } from '../safeParagraph'

interface ChatMessageItemProps {
  message: ChatMessageQuery
  isFromMe: boolean
  scrollRef?: React.RefObject<HTMLLIElement>
}

const ChatMessageItem: React.FC<ChatMessageItemProps> = ({
  message,
  isFromMe,
  scrollRef,
}) => {
  return (
    <li
      className={`chat__list__item ${isFromMe ? 'fromMe' : 'fromOther'}`}
      ref={scrollRef}
    >
      {!isFromMe && (
        <p className="chat__list__item__name">{message.memberName}</p>
      )}
      <div className="chat__list__item__container">
        {!isFromMe && (
          <img
            className="chat__list__item__icon"
            src={message.profileImageUrl || '/icons/avatar-sample.png'}
            alt="プロフィール画像"
          />
        )}
        <div className="chat__list__item__content">
          <SafeParagraph
            className="chat__list__item__comment"
            content={message.message}
          />
          <time className="chat__list__item__time">
            {getChatMessageAt(message.datetime)}
          </time>
        </div>
      </div>
    </li>
  )
}

export default ChatMessageItem
