import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SimpleMemberQuery, TimelineCardQuery } from '../../services'
import { timeAgoInWords } from '../../lib/common'

interface IndividualUpdateCubeCardProps {
  timelineCard: TimelineCardQuery
  index: number
  member: SimpleMemberQuery
  suffix: string
  children: React.ReactNode
  timelineCommentComponent?: JSX.Element
  onClick?: () => void
}

const IndividualUpdateCubeCard: React.FC<IndividualUpdateCubeCardProps> = ({
  timelineCard,
  member,
  index,
  suffix = '',
  children,
  timelineCommentComponent,
  onClick,
}) => {
  const navigate = useNavigate()
  return (
    <li
      className={`home-timeline__card home-timeline__card-cube home-timeline__card-cube--simple ${
        timelineCard?.unreadFlg ? 'home-timeline__card-is-unread' : ''
      }`}
      key={index}
    >
      <div
        className={`${
          onClick
            ? 'home-timeline__card-link--active'
            : 'home-timeline__card-link'
        }`}
        onClick={onClick}
      >
        <div className="home-timeline__card-upper">
          <div className="home-timeline__card-upper-avater">
            <img
              src={
                member.profileImageUrl
                  ? member.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  navigate(`/member-detail/${member.memberId}`)
              }}
              alt="プロフィール画像"
              width={32}
              height={32}
            />
          </div>
          <div className="home-timeline__card-upper-content">
            <h2 className="home-timeline__card-upper-title">
              <span
                  onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      navigate(`/member-detail/${member.memberId}`)
                  }}
              >
                  {member.memberName}
              </span>
              さんは{suffix}
            </h2>
            <span className="home-timeline__card-posttime">
              {timeAgoInWords(timelineCard.createdAt)}
            </span>
          </div>
        </div>
      </div>
      <div className="home-timeline__card-upper-content">{children}</div>

      {timelineCommentComponent}
    </li>
  )
}

export default IndividualUpdateCubeCard
