/**
 * 環境変数
 */
export class Environment {
  ENV = process.env.REACT_APP_ENV
  BACKEND_BASE_URL = ''
  FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
}

const env = new Environment()
switch (process.env.REACT_APP_ENV) {
  case 'mock':
    env.BACKEND_BASE_URL = 'http://127.0.0.1:8000'
    break
  case 'development':
    env.BACKEND_BASE_URL = 'http://localhost:8080'
    break
  case 'staging':
    env.BACKEND_BASE_URL = 'https://api.stg.xparks.jp'
    break
  case 'production':
    env.BACKEND_BASE_URL = 'https://api.xparks.jp'
    break
}

if (!env.BACKEND_BASE_URL) throw new Error('BACKEND_BASE_URL is not set')

export default env
