import React, { FC, useEffect, useState } from 'react'
import { TextInput } from '../../components/textInput'
import { PeriodInput } from '../../components/periodInput'
import { CareerQuery } from '../../services'
import {
  changeCareerValue,
  clearValidationError,
  validateCareer,
} from '../../lib/career'
import { Button } from '../../components/button'

interface Props {
  careers: CareerQuery[]
  setCareers: React.Dispatch<React.SetStateAction<CareerQuery[]>>
  navigateToPreviousPage: () => void
  navigateToNextPage: () => void
}

const initialCareer: CareerQuery = {
  companyName: '',
  occupationName: '',
  enrollmentPeriodStart: '',
  enrollmentPeriodEnd: '',
  headlineFlg: false,
  currentFlg: false,
}

export const CareerForm: FC<Props> = ({
  careers,
  setCareers,
  navigateToPreviousPage,
  navigateToNextPage,
}) => {
  const [companyNameErrors, setCompanyNameErrors] = useState<string[]>([])
  const [occupationNameErrors, setOccupationNameErrors] = useState<string[]>([])
  const [enrollmentPeriodErrors, setEnrollmentPeriodErrors] = useState<
    string[]
  >([])

  // 経歴がない場合にinitialCareerを初期表示
  useEffect(() => {
    if (careers.length === 0) {
      setCareers([initialCareer])
    }
  }, [careers, setCareers])

  if (careers.length === 0) {
    return null
  }

  const deleteCareer = (index: number) => {
    const newCareers = [...careers]
    newCareers?.splice(index, 1)
    setCareers(newCareers)
  }
  const changeCareer = (
    index: number,
    key: string,
    value: string | undefined,
  ) => {
    const newCareers = [...careers]
    // 現職の場合はenrollmentPeriodEndの値をクリア
    if (key === 'currentFlg' && value === 'true') {
      newCareers[index] = {
        ...newCareers[index],
        enrollmentPeriodEnd: '',
      }
    }
    newCareers[index] = changeCareerValue(newCareers[index], key, value)
    setCareers(newCareers)
  }
  const addCareer = () => {
    setCareers(prevState => [...prevState, { ...initialCareer }])
  }

  // バリデーション後、ページ遷移
  const handleNextPageClick = () => {
    let valid = true
    const validCareers: React.SetStateAction<CareerQuery[]> = []

    ;(careers || []).forEach((career, index) => {
      // 空の経歴をスキップ
      if (career.companyName || career.occupationName) {
        valid =
          validateCareer(
            career,
            index,
            setCompanyNameErrors,
            setOccupationNameErrors,
            setEnrollmentPeriodErrors,
          ) && valid
        if (valid) {
          validCareers.push(career)
        }
      }
    })

    if (valid) {
      setCareers(validCareers)
      navigateToNextPage()
    }
  }

  return (
    <div className="form__wrapper form__wrapper--wide form__wrapper--square">
      <div className="form form--wide">
        <section className="form__section">
          <h2 className="form__section-title">経歴を登録</h2>
          {careers.map((career, index) => (
            <div key={index} className="form__item">
              {index !== 0 && (
                <Button
                  className="career-close-button"
                  onClick={() => {
                    deleteCareer(index)
                  }}
                >
                  <img alt="close" src="/icons/close.svg" />
                </Button>
              )}

              <div className="form__item">
                <TextInput
                  label="会社名（必須）"
                  placeholder="株式会社Rond"
                  value={career.companyName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    changeCareer(index, 'companyName', event.target.value)
                    clearValidationError(index, setCompanyNameErrors)
                  }}
                  maxLength={64}
                  error={companyNameErrors[index]}
                />
              </div>
              <div className="form__item">
                <TextInput
                  label="職種（必須）"
                  placeholder="UI/UXデザイナー"
                  value={career.occupationName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    changeCareer(index, 'occupationName', event.target.value)
                    clearValidationError(index, setOccupationNameErrors)
                  }}
                  maxLength={64}
                  error={occupationNameErrors[index]}
                />
              </div>
              <div className="form__item">
                <label className="signup-profile__period-label">在職期間</label>
                <p className="form__check">
                  <input
                    type="checkbox"
                    id={`current-job-${index}`}
                    name={`current-job-${index}`}
                    checked={career?.currentFlg}
                    onChange={event => {
                      changeCareer(
                        index,
                        'currentFlg',
                        event.target.checked.toString(),
                      )
                    }}
                  />
                  <label htmlFor={`current-job-${index}`}>現職</label>
                </p>
                <PeriodInput
                  label=""
                  periodStart={career.enrollmentPeriodStart}
                  periodEnd={career.enrollmentPeriodEnd}
                  setPeriodStart={(value: string | undefined) => {
                    changeCareer(index, 'enrollmentPeriodStart', value)
                    clearValidationError(index, setEnrollmentPeriodErrors)
                  }}
                  setPeriodEnd={(value: string | undefined) => {
                    changeCareer(index, 'enrollmentPeriodEnd', value)
                    clearValidationError(index, setEnrollmentPeriodErrors)
                  }}
                  periodEndDisabled={career.currentFlg}
                  error={enrollmentPeriodErrors[index]}
                />
                <p
                  className={`form__check form__check-profile-headline ${
                    !career?.currentFlg &&
                    'form__check-profile-headline-disabled'
                  }`}
                >
                  <input
                    type="checkbox"
                    id={`profile-headline-display-${index}`}
                    className="profile-headline-display"
                    name={`profile-headline-display-${index}`}
                    checked={career?.currentFlg && career?.headlineFlg}
                    onChange={event => {
                      changeCareer(
                        index,
                        'headlineFlg',
                        event.target.checked.toString(),
                      )
                    }}
                    disabled={!career?.currentFlg}
                  />
                  <label htmlFor={`profile-headline-display-${index}`}>
                    この経歴をプロフィールのヘッドラインに表示
                  </label>
                </p>
              </div>
            </div>
          ))}
          <Button
            className="btn btn__secondary btn__secondary--add"
            onClick={addCareer}
          >
            経歴を追加
          </Button>
        </section>
        <div className="form__submit">
          <Button className="btn" onClick={handleNextPageClick}>
            つぎへ
          </Button>
          <Button
            className="btn btn_secondary-l with-margin-top"
            onClick={navigateToPreviousPage}
          >
            前に戻る
          </Button>
        </div>
      </div>
    </div>
  )
}
