import React from 'react'
import { MemberCubeQuery, TimelineCardQuery } from '../../services'
import IndividualUpdateCubeCard from './IndividualUpdateCubeCard'
import GiftUpdateCubeCard from './GiftUpdateCubeCard'
import { Cube } from '../cube'
import { SafeParagraph } from '../safeParagraph'

interface UpdateCubeCardProps {
  timelineCard: TimelineCardQuery
  memberId: number
  memberCubes: MemberCubeQuery[]
  index: number
  timelineCommentComponent?: JSX.Element
  onClickTimelineCard?: () => void
}

const UpdateCubeCard: React.FC<UpdateCubeCardProps> = ({
  timelineCard,
  memberId,
  memberCubes,
  index,
  timelineCommentComponent,
  onClickTimelineCard,
}) => {
  const memberCube = timelineCard.updateCubeCard?.linkMemberCubes[0]
  const from = timelineCard.updateCubeCard?.from
  const to = timelineCard.updateCubeCard?.to

  if (!to || !from) return null

  const commonMessage =
    to.memberId !== memberId &&
    memberCubes?.some(
      (cube: MemberCubeQuery) => cube.cubeId === memberCube?.cubeId,
    )
      ? 'あなたと同じ'
      : ''
  let suffix = ''
  let individualFlg = false

  if (
    from.memberId === memberId && // 送ったのが自分
    to.memberId === memberId // 受け取ったのが自分
  ) {
    // 個人型：自分から自分
    individualFlg = true
    suffix = `プロフィールにキューブを追加しました👏`
  } else if (
    from.memberId === memberId && // 送ったのが自分
    to.memberId !== memberId // 受け取ったのが他人
  ) {
    // 複数人型：自分から他人
    suffix = `${commonMessage}キューブを送りました👏`
  } else if (
    from.memberId !== memberId && // 送ったのが他人
    to.memberId === memberId // 受け取ったのが自分
  ) {
    // 複数人型：他人から自分
    suffix = `${commonMessage}キューブを送りました👏`
  } else if (
    from.memberId !== memberId && // 送ったのが他人
    to.memberId !== memberId && // 受け取ったのが他人
    from.memberId === to.memberId // 送受信が同一人物
  ) {
    // 個人型：他人から他人（送受信が同一人物）
    individualFlg = true
    suffix = `プロフィールに${commonMessage}キューブを追加しました👏`
  } else if (
    from.memberId !== memberId && // 送ったのが他人
    to.memberId !== memberId && // 受け取ったのが他人
    from.memberId !== to.memberId // 送受信が別人物
  ) {
    // 複数人型：他人から他人（送受信が別人物）
    suffix = `${commonMessage}キューブを送りました👏`
  }

  // キューブをカテゴリーごとにグルーピング
  const cubeMap = new Map<string, MemberCubeQuery[]>()
  timelineCard.updateCubeCard?.linkMemberCubes?.forEach(cube => {
    const cubeCategoryName = cube.cubeCategory.cubeCategoryName
    if (cubeMap.has(cubeCategoryName)) {
      cubeMap.get(cubeCategoryName)?.push(cube)
    } else {
      cubeMap.set(cubeCategoryName, [cube])
    }
  })

  // メッセージをグルーピング
  const messageMap = new Map<string | undefined, number[]>()
  timelineCard.updateCubeCard?.messages.forEach(messageQuery => {
    const message = messageQuery.message
    if (messageMap.has(message)) {
      const memberCubeIds = messageMap.get(message)
      if (memberCubeIds) {
        memberCubeIds.push(messageQuery.memberCubeId)
        messageMap.set(message, memberCubeIds)
      }
    } else {
      messageMap.set(message, [messageQuery.memberCubeId])
    }
  })

  const createCubeView = () => {
    // データ整形
    const processedData: {
      message: string | undefined
      categories: {
        categoryId: number
        categoryName: string
        cubes: MemberCubeQuery[]
      }[]
    }[] = Array.from(messageMap).map(([message, memberCubeIds]) => {
      const categories = Array.from(cubeMap)
        .map(([categoryName, cubes]) => {
          // メンバーキューIDが一致するキューのみ抽出
          const filteredCubes = cubes.filter(cube =>
            memberCubeIds.includes(cube.memberCubeId),
          )

          if (filteredCubes.length === 0) {
            return null
          }

          return {
            categoryId: cubes[0].cubeCategory.cubeCategoryId,
            categoryName,
            cubes: filteredCubes,
          }
        })
        // nullを除外
        .filter(
          (
            category,
          ): category is {
            categoryId: number
            categoryName: string
            cubes: MemberCubeQuery[]
          } => category !== null,
        )

      return {
        message,
        categories,
      }
    })
    // 逆順にする（登録順）
    processedData.reverse()
    // カテゴリーが多い順にソートして、その中でカテゴリーIDが小さい順にソート
    processedData.sort((a, b) => {
      if (a.categories.length !== b.categories.length) {
        return b.categories.length - a.categories.length
      }
      return a.categories[0].categoryId - b.categories[0].categoryId
    })

    // DOM 変換
    return processedData.map(({ message, categories }, index1) => (
      <React.Fragment key={index1}>
        {categories.map(({ categoryName, cubes }, index2) => (
          <React.Fragment key={index2}>
            <h2 className="cube__category">{categoryName}</h2>
            <ul className="cube__list">
              {cubes.map(cube => (
                <Cube
                  memberId={to.memberId}
                  cube={cube}
                  key={cube.cubeId}
                  deleteButtonEnabled={
                    cube.giftMembers[0]?.memberId === memberId
                  }
                  onMoreViewCommentClick={onClickTimelineCard}
                />
              ))}
            </ul>
          </React.Fragment>
        ))}
        {message && (
          <SafeParagraph
            className={`${
              // カテゴリーが複数の場合はマージンを広くする
              categories.length === 1
                ? 'home-timeline__card-upper-text'
                : 'home-timeline__card-upper-text--multi'
            }`}
            content={message}
          />
        )}
        {index1 !== processedData.length - 1 && (
          <hr className="home-timeline__card-posttime" />
        )}
      </React.Fragment>
    ))
  }

  return individualFlg ? (
    <IndividualUpdateCubeCard
      timelineCard={timelineCard}
      member={to}
      index={index}
      suffix={suffix}
      timelineCommentComponent={timelineCommentComponent}
      onClick={onClickTimelineCard}
    >
      {createCubeView()}
    </IndividualUpdateCubeCard>
  ) : (
    <GiftUpdateCubeCard
      timelineCard={timelineCard}
      member={to}
      giftMember={from}
      index={index}
      suffix={suffix}
      timelineCommentComponent={timelineCommentComponent}
      onClick={onClickTimelineCard}
    >
      {createCubeView()}
    </GiftUpdateCubeCard>
  )
}

export default UpdateCubeCard
