import { FC, useState } from 'react'
import { Button } from '../../components/button'
import {
  CubeControllerService,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
  MemberQuery,
} from '../../services'
import React from 'react'
import apiErrorHandler from '../../api/apiErrorHandler'
import { SuggestMemberCard } from '../../components/suggestMemberCard'
import { SuggestTextInput } from '../../components/suggestTextInput'
import { toast } from 'react-toastify'

interface Props {
  memberId?: number
  closeModal: () => void
  setIsLoading: ( isLoading:boolean) => void;
}

export const SendCubeForm: FC<Props> = ({ memberId, closeModal, setIsLoading }) => {
  const [modalPage, setModalPage] = useState<'SELECT' | 'EXPLAIN'>('SELECT')

  const [inputCubeMap, setInputCubeMap] = useState<Map<number, string>>(
    new Map(),
  )
  // チャットメンバー選択と同じコンポーネントを利用しているため配列で受け取る
  const [members, setMembers] = useState<MemberQuery[]>()
  const [cubeMap, setCubeMap] = useState<Map<number, string[]>>(new Map())

  // キューブごとに説明を設定するかどうか
  const [isIndividualExplanation, setIsIndividualExplanation] = useState(false)
  // 説明（共通）
  const [giftMessage, setGiftMessage] = useState<string>('')
  // 説明（個別）
  const [giftMessageMap, setGiftMessageMap] = useState<Map<number, string[]>>(
    new Map(),
  )

  const goPage = (page: 'SELECT' | 'EXPLAIN') => {
    setModalPage(page)
  }

  // キューブ登録
  const createCubes = () => {
    if (members?.length !== 1) {
      return
    }
    const memberCubeForms = Array.from(cubeMap)
      .map(([cubeCategoryId, cubes]) => {
        return cubes.map((cubeName, index) => {
          return {
            memberId: members[0].memberId,
            cubeCategoryId: cubeCategoryId,
            cubeName: cubeName,
            giftMessage: isIndividualExplanation
              ? giftMessageMap.get(cubeCategoryId)?.[index]
              : giftMessage,
          } as MemberCubeForm
        })
      })
      .flat()
    setIsLoading(true)
    MemberCubeControllerService.createMemberCubes({
      memberCubes: memberCubeForms,
    } as MemberCubesForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  // cube入力
  const setInput = (cubeCategoryId: number, value: string) => {
    const newInputCubeMap = new Map(inputCubeMap)
    newInputCubeMap.set(cubeCategoryId, value)
    setInputCubeMap(newInputCubeMap)
  }

  // cube追加
  const setCube = (cubeCategoryId: number, value: string) => {
    // 空文字や同名の場合は追加しない
    if (
      !value ||
      !value.match(/\S/g) ||
      cubeMap.get(cubeCategoryId)?.includes(value)
    ) {
      return
    }

    const newCubeMap = new Map(cubeMap)
    newCubeMap.set(cubeCategoryId, [
      ...(newCubeMap.get(cubeCategoryId) || []),
      value,
    ])
    setCubeMap(newCubeMap)
  }

  // cube削除
  const deleteCube = (cubeCategoryId: number, index: number) => {
    const newCubeMap = new Map(cubeMap)
    const cubes = newCubeMap.get(cubeCategoryId)
    if (cubes) {
      cubes.splice(index, 1)
      newCubeMap.set(cubeCategoryId, cubes)
      setCubeMap(newCubeMap)
    }
  }

  // サジェスト候補を取得
  const getSuggests = async (value: string): Promise<string[]> => {
    return await CubeControllerService.suggestCubes(value)
      .then(res => {
        return res.map(cube => cube.cubeName)
      })
      .catch(err => {
        apiErrorHandler(err)
        return []
      })
  }

  return (
    <>
      <div className="flex flex-col h-full hidden-scrollbar">
        {modalPage === 'SELECT' && (
          <>
            <div className="mt-6 mb-14">
              <div
                className={`${memberId && members?.length === 0 && 'hidden'}`}
              >
                <SuggestMemberCard
                  defaultMemberIds={
                    memberId
                      ? [memberId]
                      : members?.length === 1
                      ? [members[0].memberId]
                      : []
                  }
                  setMembers={setMembers}
                />
              </div>
              {members?.length === 1 && (
                <div className="max-h-[470px] md:max-h-[450px] overflow-y-auto">
                  {members[0].cubeCategories.map((cubeCategory, index) => (
                    <React.Fragment key={index}>
                      <label className="font-bold text-sm block text-left mb-2">
                        {cubeCategory.cubeCategoryName}
                      </label>
                      <div className="relative rounded-2xl bg-gray-100">
                        <div className="icon-center left-3">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.50016 2.27687C9.80956 2.09824 10.1908 2.09824 10.5002 2.27687L16.4387 5.7055C16.7481 5.88414 16.9387 6.21426 16.9387 6.57153V13.4288C16.9387 13.7861 16.7481 14.1162 16.4387 14.2948L10.5002 17.7235C10.1908 17.9021 9.80956 17.9021 9.50016 17.7235L3.5616 14.2948C3.2522 14.1162 3.0616 13.7861 3.0616 13.4288V6.57153C3.0616 6.21426 3.2522 5.88414 3.5616 5.7055L9.50016 2.27687Z"
                              stroke="#008666"
                              strokeWidth="2"
                            />
                            <path
                              d="M10 10V17.9167"
                              stroke="#008666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M10 10L3.33318 6.25"
                              stroke="#008666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M10 10L16.6667 6.25"
                              stroke="#008666"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                        <SuggestTextInput
                          className="block w-full h-full p-3 pl-10 rounded-2xl text-base text-left"
                          placeholder="キューブを入力"
                          value={
                            inputCubeMap.get(cubeCategory.cubeCategoryId) || ''
                          }
                          setValue={value =>
                            setInput(cubeCategory.cubeCategoryId, value)
                          }
                          onSelected={value => {
                            setCube(cubeCategory.cubeCategoryId, value)
                          }}
                          getSuggests={getSuggests}
                        />
                      </div>
                      <ul className="flex flex-wrap mt-4 mb-4 gap-2">
                        {Array.from(cubeMap)
                          .filter(
                            ([cubeCategoryId]) =>
                              cubeCategoryId === cubeCategory.cubeCategoryId,
                          )
                          .map(([cubeCategoryId, cubes]) =>
                            cubes.map((cubeName, index) => (
                              <li
                                className="relative p-1.5 pr-6 pl-2 border border-blue-200 bg-blue-50 inline-block rounded font-bold text-sm text-center"
                                key={index}
                              >
                                {cubeName}
                                <Button
                                  className="block absolute top-1/2 right-1 -translate-y-1/2 cursor-none"
                                  onClick={() =>
                                    deleteCube(cubeCategoryId, index)
                                  }
                                >
                                  <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="cursor-pointer"
                                  >
                                    <path
                                      d="M13.1977 12.5002L16.5496 9.14828C16.7087 8.98948 16.7982 8.77399 16.7984 8.54921C16.7986 8.32444 16.7095 8.10879 16.5507 7.94971C16.3919 7.79062 16.1764 7.70114 15.9516 7.70094C15.7268 7.70075 15.5112 7.78985 15.3521 7.94865L12.0002 11.3006L8.64828 7.94865C8.4892 7.78957 8.27344 7.7002 8.04846 7.7002C7.82349 7.7002 7.60773 7.78957 7.44865 7.94865C7.28957 8.10773 7.2002 8.32349 7.2002 8.54846C7.2002 8.77344 7.28957 8.9892 7.44865 9.14828L10.8006 12.5002L7.44865 15.8521C7.28957 16.0112 7.2002 16.227 7.2002 16.4519C7.2002 16.6769 7.28957 16.8927 7.44865 17.0517C7.60773 17.2108 7.82349 17.3002 8.04846 17.3002C8.27344 17.3002 8.4892 17.2108 8.64828 17.0517L12.0002 13.6998L15.3521 17.0517C15.5112 17.2108 15.727 17.3002 15.9519 17.3002C16.1769 17.3002 16.3927 17.2108 16.5517 17.0517C16.7108 16.8927 16.8002 16.6769 16.8002 16.4519C16.8002 16.227 16.7108 16.0112 16.5517 15.8521L13.1977 12.5002Z"
                                      fill="#212643"
                                    />
                                  </svg>
                                </Button>
                              </li>
                            )),
                          )}
                      </ul>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
            <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
              <Button
                className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                onClick={closeModal}
              >
                キャンセル
              </Button>
              <Button
                className="w-32 px-6 py-3 flex justify-center items-center btn-primary-base"
                onClick={() => {
                  goPage('EXPLAIN')
                }}
                disabled={members?.length !== 1 || cubeMap.size === 0}
              >
                次へ
              </Button>
            </div>
          </>
        )}
        {modalPage === 'EXPLAIN' && (
          <>
            <div className="mt-5 mb-1 md:mb-12 pb-4 max-h-[650px] md:max-h-[550px] overflow-y-auto">
              <label className="font-bold text-sm block text-left mb-1">
                説明
              </label>
              {/* cubeMapの値が2つ以上なら表示 */}
              {Array.from(cubeMap)
                .map(([, cubes]) => cubes)
                .flat().length > 1 && (
                <div
                  className="flex items-center text-left"
                  style={{ marginTop: 0 }}
                >
                  <input
                    type="checkbox"
                    id="isCommonExplanation"
                    name="isCommonExplanation"
                    checked={isIndividualExplanation}
                    onChange={() =>
                      setIsIndividualExplanation(!isIndividualExplanation)
                    }
                    className="h-4 w-4 border border-gray-300 rounded checked:bg-green checked:border-green checked:bg-[url('/images/icon_checked.svg')] checked:bg-center checked:bg-no-repeat focus:ring-green"
                  />
                  <label
                    htmlFor="isCommonExplanation"
                    className="block relative w-full ml-2 font-medium text-sm select-none cursor-pointer"
                  >
                    キューブごとに設定する
                  </label>
                </div>
              )}
              {isIndividualExplanation ? (
                // キューブごとに説明を設定する場合
                <div className="mt-5">
                  {Array.from(cubeMap).map(([cubeCategoryId, cubes]) =>
                    cubes.map((cubeName, index) => (
                      <React.Fragment key={index}>
                        <label className="font-bold mt-4 text-sm block text-left">
                          {members
                            ? members[0]?.cubeCategories.find(
                                category =>
                                  category.cubeCategoryId === cubeCategoryId,
                              )?.cubeCategoryName
                            : ''}
                        </label>
                        <ul className="flex flex-wrap my-3 gap-2">
                          <li className="relative font-bold text-sm py-1 px-2 border border-blue-200 bg-blue-100 text-left cursor-default">
                            {cubeName}
                          </li>
                        </ul>
                        <label className="font-bold text-sm block text-left">
                          <textarea
                            className="block w-full mt-1 p-3 rounded-lg bg-gray-100 font-normal text-base resize-none"
                            value={giftMessageMap.get(cubeCategoryId)?.[index]}
                            placeholder="きっかけや理由などをコメントしましょう！（任意）"
                            onChange={e => {
                              const newExplanationMap = new Map(giftMessageMap)
                              const newExplanations =
                                newExplanationMap.get(cubeCategoryId)
                              if (newExplanations) {
                                newExplanations[index] = e.target.value
                                newExplanationMap.set(
                                  cubeCategoryId,
                                  newExplanations,
                                )
                              } else {
                                newExplanationMap.set(cubeCategoryId, [
                                  e.target.value,
                                ])
                              }
                              setGiftMessageMap(newExplanationMap)
                            }}
                            style={{ height: '10rem' }}
                          ></textarea>
                        </label>
                      </React.Fragment>
                    )),
                  )}
                </div>
              ) : (
                // 全てのキューブに共通の説明を設定する場合
                <div>
                  <ul className="flex flex-wrap mt-4 mb-4 gap-2">
                    {Array.from(cubeMap).map(([, cubes]) =>
                      cubes.map((cubeName, index) => (
                        <li
                          className="relative font-bold text-sm py-1 px-2 border border-blue-200 bg-blue-100 text-left cursor-default"
                          key={index}
                        >
                          {cubeName}
                        </li>
                      )),
                    )}
                  </ul>
                  <label className="font-bold text-sm block text-left">
                    <textarea
                      className="block w-full mt-1 p-3 rounded-lg bg-gray-100 font-normal text-base resize-none"
                      value={giftMessage}
                      placeholder="きっかけや理由などをコメントしましょう！（任意）"
                      onChange={e => setGiftMessage(e.target.value)}
                      style={{ height: '10rem' }}
                    ></textarea>
                  </label>
                </div>
              )}
            </div>
            <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
              <Button
                className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                onClick={() => {
                  goPage('SELECT')
                }}
              >
                戻る
              </Button>
              <Button
                className="px-6 py-3 flex justify-center items-center btn-primary-base"
                onClick={createCubes}
                disabled={members?.length !== 1 || cubeMap.size === 0}
              >
                キューブを送る
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
