import React, { FC } from 'react'

interface Props {
  publicFlg?: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const PublishingSettingInput: FC<Props> = ({ publicFlg, onChange }) => {
  return (
    <>
      <label className="font-bold text-sm text-left">公開範囲</label>
      <div className="relative inline-block">
        <select
          name="form__public-range"
          className="pr-8 pl-2 py-2 cursor-pointer"
          onChange={onChange}
          value={publicFlg ? 'true' : 'false'}
        >
          <option value="true">全員</option>
          <option value="false">自分のみ</option>
        </select>
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
              fill="#8A8F9F"
            />
          </svg>
        </div>
      </div>
    </>
  )
}
