import { CareerQuery } from '../services'
import React from 'react'

const validateCareer = (
  career: CareerQuery,
  index: number,
  setCompanyNameErrors: React.Dispatch<React.SetStateAction<string[]>>,
  setOccupationNameErrors: React.Dispatch<React.SetStateAction<string[]>>,
  setEnrollmentPeriodErrors: React.Dispatch<React.SetStateAction<string[]>>,
): boolean => {
  const errors: string[] = []

  // 各項目のバリデーション
  if (!career.companyName) {
    errors.push('会社名を入力してください')
    setCompanyNameErrors(prevErrors => {
      const updatedErrors = [...prevErrors]
      updatedErrors[index] = '会社名を入力してください'
      return updatedErrors
    })
  }

  if (!career.occupationName) {
    errors.push('職種を入力してください')
    setOccupationNameErrors(prevErrors => {
      const updatedErrors = [...prevErrors]
      updatedErrors[index] = '職種を入力してください'
      return updatedErrors
    })
  }

  if (
    !career.currentFlg &&
    career.enrollmentPeriodStart &&
    career.enrollmentPeriodEnd &&
    career.enrollmentPeriodStart > career.enrollmentPeriodEnd
  ) {
    errors.push('開始日は終了日よりも前の日付に設定してください')
    setEnrollmentPeriodErrors(prevErrors => {
      const updatedErrors = [...prevErrors]
      updatedErrors[index] = '開始日は終了日よりも前の日付に設定してください'
      return updatedErrors
    })
  }

  return errors.length === 0
}

// バリデーションクリア
const clearValidationError = (
  index: number,
  setError: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  setError(prevErrors => {
    const updatedErrors = [...prevErrors]
    updatedErrors[index] = ''
    return updatedErrors
  })
}

const changeCareerValue = (
  career: CareerQuery | undefined,
  key: string,
  value: string | undefined,
) => {
  const newCareer = { ...career } as CareerQuery
  switch (key) {
    case 'companyName':
      newCareer.companyName = value || ''
      break
    case 'occupationName':
      newCareer.occupationName = value || ''
      break
    case 'enrollmentPeriodStart':
      newCareer.enrollmentPeriodStart = value
      break
    case 'enrollmentPeriodEnd':
      newCareer.enrollmentPeriodEnd = value
      break
    case 'currentFlg':
      newCareer.currentFlg = value === 'true'
      break
    case 'headlineFlg':
      newCareer.headlineFlg = value === 'true'
      break
  }
  return newCareer
}

export { validateCareer, clearValidationError, changeCareerValue }
