import { FC, KeyboardEvent } from 'react'
import { Button } from '../../components/button'

interface ChatInputProps {
  chatMessageForm: string
  setChatMessageForm: (value: string) => void
  handleKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  calculateTextAreaRows: () => number
  sendChatMessage: (message: string) => void
}

export const ChatInput: FC<ChatInputProps> = ({
  chatMessageForm,
  setChatMessageForm,
  handleKeyDown,
  calculateTextAreaRows,
  sendChatMessage,
}) => {
  return (
    <div className="form__label chat__input-container">
      <textarea
        className="chat__input"
        placeholder="コメントを送る"
        onChange={e => setChatMessageForm(e.target.value)}
        onKeyDown={handleKeyDown}
        rows={calculateTextAreaRows()}
        value={chatMessageForm}
      ></textarea>
      <div className="chat-input-container__button">
        <Button
          className={`btn chat__submit-btn ${
            chatMessageForm === '' ? 'btn-disabled' : ''
          }`}
          disabled={chatMessageForm === ''}
          onClick={() => sendChatMessage(chatMessageForm)}
        >
          送信
        </Button>
      </div>
    </div>
  )
}
