import { useEffect, useState } from 'react'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import {
  CancelablePromise,
  ChatMessageQuery,
  ChatMessageReadControllerService,
  ChatRoomControllerService,
} from '../services'
import env from '../config/env'
import { toast } from 'react-toastify'

const MAX_MESSAGE_LENGTH = 10_000

export const useChatRoom = () => {
  const [chatClient, setChatClient] = useState<Stomp.Client>()
  const [chatMessageForm, setChatMessageForm] = useState('')
  const [chatMessages, setChatMessages] = useState<ChatMessageQuery[]>([])

  // このHooksが破棄されるにWebSocketを切断する
  useEffect(() => {
    return () => chatClient?.disconnect(() => 'chatRoom disconnected')
  }, [chatClient])

  const addChatRoom = (
    selectedMemberIds: number[],
  ): CancelablePromise<string> => {
    return ChatRoomControllerService.createChatRoom({
      attendeeMemberIds: selectedMemberIds,
    })
  }

  const updateChatRoomMembers = (
    chatRoomId: string,
    selectedMemberIds: number[],
  ): CancelablePromise<any> => {
    return ChatRoomControllerService.updateChatRoomMembers({
      chatRoomId: chatRoomId,
      attendeeMemberIds: selectedMemberIds,
    })
  }

  // チャットルーム接続（WebSocket）
  const connectChatRoom = (
    chatRoomId: string,
    callbackMessageRecieved: () => void,
    isForceConnect?: boolean,
  ) => {
    const socket = new SockJS(
      `${env.BACKEND_BASE_URL}/ws?chatRoomId=${chatRoomId}`,
    )
    const client = Stomp.over(socket)

    setChatClient(prevClient => {
      if (!isForceConnect && prevClient) {
        return prevClient
      }

      client.connect({}, async () => {
        recieveChatMessage(client, callbackMessageRecieved, chatRoomId)
        recieveChatMessageError(client)
      })

      return client
    })
  }

  // WebSocket経由でチャットメッセージを送信する
  const sendChatMessage = (
    chatMessage: string,
    onDisconnected: (callback: () => void) => void,
  ) => {
    if (chatMessage.length > MAX_MESSAGE_LENGTH) {
      toast.error(
        `メッセージが長すぎます。${MAX_MESSAGE_LENGTH.toLocaleString()}文字以内で入力してください。`,
      )
      return
    }

    if (chatClient?.connected === false) {
      onDisconnected(() => {
        chatClient?.send(
          '/app/chat',
          {},
          JSON.stringify({
            message: chatMessage,
          }),
        )
        setChatMessageForm('')
      })
      return
    }

    chatClient?.send(
      '/app/chat',
      {},
      JSON.stringify({
        message: chatMessage,
      }),
    )
    setChatMessageForm('')
  }

  // WebSocket経由でチャットメッセージを受信する
  const recieveChatMessage = (
    client: Stomp.Client,
    callbackMessageRecieved: () => void,
    chatRoomId: string,
  ) => {
    client.subscribe('/user/topic/receive', (result: any) => {
      const data = JSON.parse(result.body)
      setChatMessages(e => [...e, data])
      callbackMessageRecieved()
      readChatMessages(chatRoomId)
    })
  }

  // メッセージが受信できたら即既読にする
  const readChatMessages = (chatRoomId: string) => {
    ChatMessageReadControllerService.readUnreadChatMessages(chatRoomId)
  }

  // WebSocket経由でチャットメッセージを受信する
  const recieveChatMessageError = (client: Stomp.Client) => {
    client.subscribe('/user/topic/error', (result: any) => {
      const res = JSON.parse(result.body)
      toast.error(res.errorMessage)
      setChatMessageForm(res.sentMessage)
    })
  }

  return {
    addChatRoom,
    updateChatRoomMembers,
    connectChatRoom,
    setChatMessageForm,
    sendChatMessage,
    setChatMessages,
    chatMessageForm,
    chatMessages,
  }
}
