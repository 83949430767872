import React, { FC, useEffect, useState } from 'react'
import { HeaderType } from '../../enums/HeaderType'
import { useLocation, useNavigate } from 'react-router-dom'
import apiErrorHandler from '../../api/apiErrorHandler'
import { MemberControllerService, MemberQuery } from '../../services'
import { useModal } from '../../contexts/modalContext'
import { useRecoilState } from 'recoil'
import { loggedInMemberState } from '../../lib/atoms'
import { chatRoomDetailModal } from '../../modals/chatRoomDetailModal'
import { Button } from '../../components/button'

interface Props {
  headerType?: HeaderType
  headerTitle?: string
  isChatRoomTitle?: boolean
}

export const Header: FC<Props> = ({
  headerType = HeaderType.DEFAULT,
  headerTitle,
  isChatRoomTitle,
}) => {
  const { openModal } = useModal()
  const location = useLocation()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const sort = query.get('sort') as 'COMMON' | 'NEW'

  const navigate = useNavigate()
  const [keyword, setKeyword] = useState((query.get('keyword') as string) || '')
  const [composing, setComposing] = useState(false)
  const [member, setMember] = useState({} as MemberQuery)

  const [, setLoggedInMemberId] = useRecoilState(loggedInMemberState)

  useEffect(() => {
    if (headerType === HeaderType.NOT_LOGIN) return
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
      })
      .catch(apiErrorHandler)

    MemberControllerService.getLoggedInMember().then(loggedInMember => {
      const isUserLoggedIn = !!loggedInMember.memberId
      if (isUserLoggedIn) {
        setLoggedInMemberId({
          memberId: loggedInMember.memberId,
          workspaceId: loggedInMember.workspaceId,
        })
      }
    })
  }, [])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !composing) {
      if (keyword === '') {
        const sortQuery = sort ? `?sort=${sort}` : ''
        window.location.href = `/member-list${sortQuery}`
        return
      } else {
        const sortQuery = sort ? `&sort=${sort}` : ''
        const aiFlg = document.getElementById('ai-search-checkbox')
          ? (document.getElementById('ai-search-checkbox') as HTMLInputElement)
              .checked
          : false
        const aiSearchQuery = aiFlg ? '' : '&ai=false'
        window.location.href = `/member-list?keyword=${keyword}${sortQuery}${aiSearchQuery}`
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const handleCompositionStart = () => {
    setComposing(true)
  }

  const handleCompositionEnd = () => {
    setComposing(false)
  }

  switch (headerType) {
    case HeaderType.DEFAULT:
      return (
        <header className="header_login">
          <div className="header_login__inner">
            <div className="header_login__input-box">
              <input
                type="text"
                className="coach-mark_step-sp-2 coach-mark_step-pc-2"
                placeholder="人やキーワードで検索"
                value={keyword}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
              />
            </div>
            <div className="header_login__right-box">
              {/*
              <button
                className="header_login__btn_nortification"
                onClick={() => navigate('/timeline')}
              >
                <img
                  src="/images/icon_timeline.svg"
                  alt="ベルマークにチェックあり"
                />
              </button>
              */}
              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img coach-mark_step-sp-3 coach-mark_step-pc-3"
                />
              </Button>
            </div>
          </div>
        </header>
      )
    case HeaderType.SIMPLE_WITHOUT_SEARCH:
      return (
        <header className="header_login">
          <div className="header_login__inner">
            {headerTitle && (
              <div className="header_login__left-box">
                <Button
                  className="header_login__back-btn"
                  onClick={() => {
                    location.pathname.includes('chat-detail')
                      ? navigate('/chat-list')
                      : window.history.back()
                  }}
                >
                  <img src="/images/icon_arrow_left.svg" alt="戻る" />
                </Button>
                <h1 className="header_login__title">
                  {isChatRoomTitle ? (
                    <Button
                      className="header_login__title__link"
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        openModal(chatRoomDetailModal)
                      }}
                    >
                      {headerTitle}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 21"
                        fill="#858a99"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z" />
                      </svg>
                    </Button>
                  ) : (
                    <span>{headerTitle}</span>
                  )}
                </h1>
              </div>
            )}
            <div className="header_login__right-box">
              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img"
                />
              </Button>
            </div>
          </div>
        </header>
      )
    case HeaderType.SIMPLE:
      return (
        <header className="header header-loggedin">
          <div className="header__inner">
            <div className="header__left">
              <div className="header__logo">
                <a href="/" className="header__logo-link">
                  <img
                    src="/images/parks_logo.svg"
                    alt="parks"
                    width="87"
                    height="28"
                  />
                </a>
              </div>
              {/* TODO 必要なら追加 */}
              {/*
              <span className="header__name">株式会社〇〇</span>
              */}
              <div className="header__search">
                <div role="search" id="searchform">
                  <input
                    type="text"
                    id="searchsubmit"
                    className="header__search-btn"
                    value={keyword}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                  />
                  <input
                    type="text"
                    name=""
                    id="search-text"
                    placeholder="人やキーワードで検索"
                    className="header__search-input"
                    value={keyword}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                  />
                </div>
              </div>
            </div>
            <div className="header__right">
              {/*
              <div
                className="header-timeline"
                onClick={() => navigate('/timeline')}
              >
                <img
                  src="/images/icon_timeline.svg"
                  alt=""
                  width="20"
                  height="20"
                />
              </div>
              */}
              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img"
                />
              </Button>
            </div>
          </div>
        </header>
      )
    case HeaderType.NOT_LOGIN:
      return (
        <header className="header header--narrow">
          <div className="header__logo">
            <div className="header__logo-link">
              <img
                src="/images/parks_logo.svg"
                alt="parks"
                width="87"
                height="28"
              />
            </div>
          </div>
        </header>
      )
    case HeaderType.SETTING:
      return (
        <header className="header_login setting">
          <div className="header_login__inner">
            <div className="header_login__input-box">
              <input
                type="text"
                placeholder="人やキーワードで検索"
                value={keyword}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="header_login__right-box">
              {/* TODO 通知画面がないので非表示 */}
              {/*
              <button className="header_login__btn_nortification">
                <img
                  src="/images/icon_timeline.svg"
                  alt="ベルマークにチェックあり"
                />
              </button>
              */}

              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img"
                />
              </Button>
            </div>

            <Button
              className="setting__header-icon"
              onClick={() => {
                // bodyのclassからsetting-submenuを削除
                document.body.classList.remove(
                  'setting-submenu',
                  'timeline-settings',
                  'personal-settings',
                )
              }}
            ></Button>
            <h1 className="setting__header-sp-title">設定</h1>
          </div>
        </header>
      )
    case HeaderType.BACK:
      return (
        <header className="header header_search">
          <Button className="header__back-btn" onClick={() => navigate(-1)}>
            <img src="/images/icon_arrow_left.svg" alt="戻るボタン" />
          </Button>
        </header>
      )
  }
}
