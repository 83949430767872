import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChatRoomQuery } from '../../services'
import {
  getChatMessageAt,
  getChatRoomName,
  getSingleAttendeeMemberProfileImageUrl,
  getAttendeesWithoutLoggedInMember,
} from '../../lib/chatUtils'

interface ChatListItemProps {
  chatRoom: ChatRoomQuery
  loggedInMember: { memberId: number; workspaceId: string }
}

export const ChatListItem: FC<ChatListItemProps> = ({
  chatRoom,
  loggedInMember,
}) => {
  const navigate = useNavigate()
  const isMultiple = chatRoom.attendees.length > 2
  const listClassName = `chat__memberList__item ${
    chatRoom.unreadCount != 0 ? 'isActive' : ''
  } ${isMultiple ? 'isMultiple' : ''}`

  return (
    <li
      className={listClassName}
      onClick={() => navigate(`/chat-detail?chatRoomId=${chatRoom.chatRoomId}`)}
    >
      <div className="chat__memberList__item__icon">
        {isMultiple ? (
          <img src="/images/parks_favicon_bg.png" alt="プロフィール画像" />
        ) : (
          <img
            src={getSingleAttendeeMemberProfileImageUrl(
              getAttendeesWithoutLoggedInMember(
                chatRoom.attendees,
                loggedInMember,
              ),
              loggedInMember,
            )}
            alt="プロフィール画像"
          />
        )}
      </div>
      <div className="chat__memberList__item__center">
        <p className="chat__memberList__item__center__name">
          {getChatRoomName(chatRoom.attendees, loggedInMember)}
        </p>
        <p className="chat__memberList__item__center__content">
          {chatRoom.latestMessage}
        </p>
      </div>
      <div>
        {chatRoom.unreadCount != 0 && (
          <span className="chat__memberList__item__unreadCount">
            {chatRoom.unreadCount > 99 ? '99+' : chatRoom.unreadCount}
          </span>
        )}
      </div>
      <div className="chat__memberList__item__right">
        <time className="chat__memberList__item__right__time">
          {getChatMessageAt(chatRoom.latestMessageAt)}
        </time>
      </div>
    </li>
  )
}
