import React, { ReactNode } from 'react'
import { ToggleReactionButton } from '../toggleReactionButton'
import { TimelineCardQuery, TimelineReactionMemberQuery } from '../../services'
import { SafeParagraph } from '../safeParagraph'
import { SimpleMemberQuery } from '../../services'
import { timeAgoInWords } from '../../lib/common'
interface CardWrapperProps {
  className: string
  onClick?: () => void
  profileImageUrl?: string
  title: JSX.Element
  cardText?: string
  createdAt?: string
  children: ReactNode
  timelineCard?: TimelineCardQuery
  myTimelineReactionMemberQuery?: TimelineReactionMemberQuery
  timelineCommentComponent?: JSX.Element
  member: SimpleMemberQuery
}

const CardWrapper: React.FC<CardWrapperProps> = ({
  className,
  onClick,
  profileImageUrl,
  title,
  cardText,
  createdAt,
  children,
  timelineCard,
  myTimelineReactionMemberQuery,
  timelineCommentComponent,
  member,
}) => {
  return (
    <li
      className={`${className} ${
        timelineCard?.unreadFlg ? 'home-timeline__card-is-unread' : ''
      }`}
      onClick={onClick}
    >
      <div
        className={`${
          onClick
            ? 'home-timeline__card-link--active'
            : 'home-timeline__card-link'
        }`}
      >
        <div className="home-timeline__card-upper">
          <div className="home-timeline__card-upper-avater">
            <img
              src={
                profileImageUrl ? profileImageUrl : '/icons/avatar-sample.png'
              }
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                window.location.href = `/member-detail/${member.memberId}`
              }}
              alt="プロフィール画像"
              width={32}
              height={32}
            />
          </div>
          <div className="home-timeline__card-upper-content">
            <h2 className="home-timeline__card-upper-title">{title}</h2>
            {cardText && (
              <SafeParagraph
                className="home-timeline__card-upper-text"
                content={cardText}
              />
            )}
            <span className="home-timeline__card-posttime">
              {timeAgoInWords(createdAt)}
            </span>
          </div>
        </div>
      </div>
      {children}
      {timelineCard && myTimelineReactionMemberQuery && (
        <ToggleReactionButton
          timelineCard={timelineCard}
          myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
        />
      )}
      {timelineCommentComponent}
    </li>
  )
}

export default CardWrapper
