import React from 'react'
import { CareerQuery } from '../../services'
import { formatDate } from '../../lib/common'
import { Button } from '../button'

interface CareerInfoProps {
  careers: CareerQuery[]
  onAddClick?: () => void
  onEditClick?: (career: CareerQuery) => void
  isProfile?: boolean
}

export const CareerInfo: React.FC<CareerInfoProps> = ({
  careers,
  onAddClick,
  onEditClick,
  isProfile,
}) => {
  return (
    <div>
      <h3 className="font-bold text-lg">経歴</h3>
      <dl className="flex flex-col mt-4 gap-4">
        {careers?.map((career, index) => (
          <div className="flex gap-7" key={index}>
            <dt className="w-28 text-sm font-normal">
              {career.enrollmentPeriodStart || career.enrollmentPeriodEnd ? (
                <>
                  <time>
                    {formatDate(career.enrollmentPeriodStart, 'yyyy年MM月')}
                  </time>
                  〜
                  <br />
                  <time>
                    {career.enrollmentPeriodEnd
                      ? formatDate(career.enrollmentPeriodEnd, 'yyyy年MM月')
                      : career.currentFlg
                      ? '現在'
                      : ''}
                  </time>
                </>
              ) : career.currentFlg ? (
                '現職'
              ) : (
                ''
              )}
            </dt>
            <dd className="flex flex-col gap-1 md:inline-flex sm:mt-0 md:mt-1 lg:mt-0">
              <span
                className="font-bold text-sm flex items-center"
                tabIndex={0}
                onClick={() => onEditClick?.(career)}
              >
                {career.companyName}
                {isProfile && (
                  <svg
                    className="ml-1 cursor-pointer"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2583 5.2056L8.77917 2.7556L9.59584 1.93893C9.81945 1.71532 10.0942 1.60352 10.4201 1.60352C10.746 1.60352 11.0205 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.2491 12.4104 3.56527C12.4201 3.88143 12.3132 4.15113 12.0896 4.37435L11.2583 5.2056ZM2.33334 12.2493C2.16806 12.2493 2.02942 12.1933 1.91742 12.0813C1.80542 11.9693 1.74961 11.8309 1.75 11.666V10.0181C1.75 9.94032 1.76459 9.86488 1.79375 9.79177C1.82292 9.71866 1.86667 9.65313 1.925 9.59518L7.93334 3.58685L10.4125 6.06602L4.40417 12.0744C4.34584 12.1327 4.28031 12.1764 4.20759 12.2056C4.13486 12.2348 4.05942 12.2493 3.98125 12.2493H2.33334Z"
                      fill="#8a8f9f"
                    />
                  </svg>
                )}
              </span>
              <span className="text-xs">{career.occupationName}</span>
            </dd>
          </div>
        ))}
      </dl>
      {isProfile && (
        <div className="mt-4">
          <Button
            className="btn-base btn-outline-base px-5 py-3 flex justify-center w-full md:justify-start md:border-0"
            onClick={onAddClick}
          >
            <svg
              className="hidden md:block"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.25 4C11.25 3.30964 10.6904 2.75 10 2.75C9.30964 2.75 8.75 3.30964 8.75 4V8.75H4C3.30964 8.75 2.75 9.30964 2.75 10C2.75 10.6904 3.30964 11.25 4 11.25H8.75V16C8.75 16.6904 9.30964 17.25 10 17.25C10.6904 17.25 11.25 16.6904 11.25 16V11.25H16C16.6904 11.25 17.25 10.6904 17.25 10C17.25 9.30964 16.6904 8.75 16 8.75H11.25V4Z"
                fill="#007559"
              />
            </svg>
            経歴を追加
          </Button>
        </div>
      )}
    </div>
  )
}
