import React, { FC, useState } from 'react'
import {
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { DateInput } from '../../components/dateInput'
import { PublishingSettingInput } from '../../components/publishingSettingInput'
import { convertDateFromResponse } from '../../lib/common'
import { Button } from '../../components/button'

interface Props {
  member: MemberQuery
  closeModal: () => void
}

export const ContactInfoEditModal: FC<Props> = ({ member, closeModal }) => {
  const [dateOfBirth, setDateOfBirth] = useState(
    member.dateOfBirth && convertDateFromResponse(member.dateOfBirth),
  )
  const [dateOfBirthShowFlg, setDateOfBirthShowFlg] = useState(
    member.dateOfBirthShowFlg,
  )
  const [dateOfEntry, setDateOfEntry] = useState(
    member.dateOfEntry && convertDateFromResponse(member.dateOfEntry),
  )

  const changeDateOfBirth = (birthday: string) => {
    setDateOfBirth(birthday)
  }

  const changeDateOfBirthShowFlg = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setDateOfBirthShowFlg(event.target.value === 'true')
  }

  const changeDateOfEntry = (dateOfEntry: string) => {
    setDateOfEntry(dateOfEntry)
  }

  const update = () => {
    MemberControllerService.updateMember({
      dateOfBirth: dateOfBirth,
      dateOfBirthShowFlg: dateOfBirthShowFlg,
      dateOfEntry: dateOfEntry,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }

  return (
    <div
      className="flex flex-col justify-start top-[5%] right-0 bottom-0 left-0 transform-none w-full p-4 rounded-t-lg fixed -translate-x-1/2 -translate-y-1/2 max-w-full overflow-hidden rounded-xl bg-white md:top-1/2 md:left-1/2 md:bottom-auto md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:w-[590px] md:max-w-full md:p-6 md:rounded-xl"
      role="dialog"
      aria-modal="true"
      aria-labelledby="contact-info-edit__title"
    >
      <div className="flex justify-between items-center">
        <h1
          className="font-bold text-2xl md:text-3xl"
          id="contact-info-edit__title"
        >
          基本情報
        </h1>

        <Button className="col-start-3 justify-self-end" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>

      <div className="mt-5 mb-16">
        <div>
          <DateInput
            label="生年月日"
            date={dateOfBirth}
            onChange={changeDateOfBirth}
          />
        </div>
        <div className="mt-3.5 flex items-center gap-4">
          <PublishingSettingInput
            publicFlg={dateOfBirthShowFlg}
            onChange={changeDateOfBirthShowFlg}
          />
        </div>
        <div className="mt-5">
          <DateInput
            label="入社日"
            date={dateOfEntry}
            onChange={changeDateOfEntry}
          />
        </div>
        <p className="mt-5">
          <label className="block text-left font-bold text-sm">
            メールアドレス
            <input
              type="email"
              name="email"
              placeholder={member.email}
              disabled
              className="block w-full mt-1 p-3.5 rounded-xl bg-gray-100 font-normal text-base pt-3 pb-3"
            />
          </label>
        </p>
        {/*
        <p className="form__item">
          <label className="form__label">
            電話番号
            <input type="tel" name="phone" placeholder="" value="08012345678" />
          </label>
        </p>
        <div className="form__item form__item--flex">
          <label className="form__label"> 公開範囲 </label>
          <div className="form__select-wrapper form__public-range-wrapper">
            <select name="form__public-range" className="form__select">
              <option value="1">自分のみ</option>
              <option value="2">非公開</option>
            </select>
          </div>
        </div>
        */}

        <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
          <Button
            className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
            onClick={closeModal}
          >
            キャンセル
          </Button>

          <Button
            className="w-32 px-6 py-3 flex justify-center items-center btn-primary-base"
            onClick={update}
          >
            保存
          </Button>
        </div>
      </div>
    </div>
  )
}
