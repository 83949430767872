/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MemberWorkspaceQuery } from '../models/MemberWorkspaceQuery';
import type { SwitchWorkspaceQuery } from '../models/SwitchWorkspaceQuery';
import type { WorkspaceSwitchingForm } from '../models/WorkspaceSwitchingForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberWorkspaceControllerService {

    /**
     * @param requestBody
     * @returns SwitchWorkspaceQuery OK
     * @throws ApiError
     */
    public static switchWorkspace(
        requestBody: WorkspaceSwitchingForm,
    ): CancelablePromise<SwitchWorkspaceQuery> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/member-workspaces/switch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns MemberWorkspaceQuery OK
     * @throws ApiError
     */
    public static getWorkspaces(): CancelablePromise<Array<MemberWorkspaceQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/member-workspaces',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
