/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuestionCreateForm } from '../models/QuestionCreateForm';
import type { QuestionQuery } from '../models/QuestionQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionControllerService {

    /**
     * @param filter
     * @param page
     * @param limit
     * @returns QuestionQuery OK
     * @throws ApiError
     */
    public static getQuestions(
        filter: 'NOT_ANSWERED' | 'ANSWERED' | 'YOURS' | 'MOST_ANSWERED' | 'ALL' = 'NOT_ANSWERED',
        page: number = 1,
        limit: number = 20,
    ): CancelablePromise<Array<QuestionQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/questions',
            query: {
                'filter': filter,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createQuestion(
        requestBody: QuestionCreateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/questions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param questionId
     * @param choiceId
     * @returns any OK
     * @throws ApiError
     */
    public static answerQuestion(
        questionId: number,
        choiceId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/questions/{questionId}/answer/{choiceId}',
            path: {
                'questionId': questionId,
                'choiceId': choiceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param questionId
     * @returns QuestionQuery OK
     * @throws ApiError
     */
    public static getQuestion(
        questionId: number,
    ): CancelablePromise<QuestionQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/questions/{questionId}',
            path: {
                'questionId': questionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param questionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteQuestion(
        questionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/questions/{questionId}',
            path: {
                'questionId': questionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param limit
     * @returns QuestionQuery OK
     * @throws ApiError
     */
    public static getPickupQuestions(
        limit: number = 3,
    ): CancelablePromise<Array<QuestionQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/questions/pickup',
            query: {
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
