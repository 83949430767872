/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MemberForm = {
    email: string;
    memberRole: MemberForm.memberRole;
};

export namespace MemberForm {

    export enum memberRole {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        MEMBER = 'MEMBER',
    }


}

