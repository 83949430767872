import React, { FC } from 'react'
import { QuestionQuery, ChoiceQuery } from '../../services'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../../lib/common'
import env from '../../config/env'

interface QuestionCardProps {
  question: QuestionQuery
  activeFilter: string
  answerQuestion: (questionId: number, choiceId: number) => void
  deleteQuestion?: (questionId: number) => void
  onShare?: (question: QuestionQuery) => void
  onMoreViewCommentClick?: (timelineId: number) => void
  developerMode?: boolean
}

const getPercentage = (question: QuestionQuery, choice: ChoiceQuery) => {
  if (env.ENV === 'mock') return 80
  return question.totalAnswerCount !== 0
    ? Math.round((choice.answerCount / question.totalAnswerCount) * 1000) / 10 // 少数第一位まで表示
    : 0
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  activeFilter,
  answerQuestion,
  deleteQuestion,
  onShare,
  onMoreViewCommentClick,
  developerMode = false,
}) => {
  const navigate = useNavigate()
  const onClickCard = () => {
    if (onMoreViewCommentClick) {
      onMoreViewCommentClick(question.timelineInfo.timelineId)
    }
  }

  return (
    // 新着の質問の場合、背景色変更
    <li
      onClick={onClickCard}
      className={`
        bg-white shadow-revert cursor-pointer md:shadow-card md:rounded-xl rounded-2xl md:p-6 p-4
        ${
          question.timelineInfo.unreadFlg
            ? 'bg-[#edfaf7]'
            : ''
        }
        `}
      key={activeFilter + question.questionId}
    >
      <span className="text-[#8a8f9f] text-xs">
        {formatDate(question.createdAt, 'yyyy/MM/dd HH:mm')}
      </span>
      <div className="mt-[16px] p-[16px] pb-[24px] rounded-[8px]" style={{ background: 'linear-gradient(#080a0773, #080a0773), url(/images/question_bg.jpg) no-repeat center center / cover' }}>
        <div className="relative min-h-[42px] py-[12px] px-[24px] rounded-[22px] bg-white font-bold text-xs mx-auto w-fit
        after:absolute after:bottom-[-0.5625rem] after:left-1/2 
        after:transform after:-translate-x-1/2 after:w-[12px] 
        after:h-[10px] after:bg-[url('/images/speech_bubble.svg')] 
        after:bg-no-repeat after:bg-center after:bg-contain">
          <p>{question.question}</p>
        </div>

        {question.anonymousFlg ? (
          // 匿名の場合
          <div className="flex items-center gap-[16px] z-1 mt-[6px] max-w-[380px] mt-[12px] mx-auto">
            <div className="underline cursor-pointer">
              <img
                src={'/icons/avatar-sample.png'}
                alt="プロフィール画像"
                width={64}
                height={64}
                className='rounded-full cursor-pointer aspect-[1/1] object-cover'
              />
            </div>
          </div>
        ) : question.member ? (
          // メンバーが存在する場合（通常ユーザ）
          <div className="flex items-center gap-[16px] z-1 mt-[6px] max-w-[380px] mt-xs mx-auto">
            <div
              className="underline cursor-pointer"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                navigate(`/member-detail/${question.member?.memberId}`)
              }}
            >
              <img
                src={
                  (!question.anonymousFlg &&
                    question.member?.profileImageUrl) ||
                  '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
                width={64}
                height={64}
                className='rounded-full cursor-pointer aspect-[1/1] object-cover'
              />
            </div>
            {!question.anonymousFlg && question.member && (
              <div className="text-white">
                <div
                  className="hover:underline cursor-pointer font-bold text-sm w-fit cursor-pointer hover:underline"
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    navigate(`/member-detail/${question.member?.memberId}`)
                  }}
                >
                  {question.member.memberName}
                </div>
                <div className="mt-[4px] text-white text-xs">
                  {question.member.position?.positionName}
                </div>
              </div>
            )}
          </div>
        ) : (
          // メンバーが存在しない場合（公式アカウント）
          <div className="flex items-center gap-[16px] z-1 mt-[6px] max-w-[380px] mt-[12px] mx-auto">
            <div className="underline cursor-pointer">
              <img
                src={'/icons/avatar-parks.png'}
                alt="プロフィール画像"
                width={64}
                height={64}
                className='rounded-full cursor-pointer aspect-[1/1] object-cover'
              />
            </div>
            <div className="text-white">
              <div className="hover:underline cursor-pointer font-bold text-sm w-fit cursor-pointer hover:underline">
                parks
              </div>
            </div>
          </div>
        )}

        {question.answeredChoiceId ? (
          // 回答済みの場合
          <div className="max-w-[380px] mt-[20px] mx-auto">
            <ul className="p-[16px] pl-0 rounded-[8px] bg-white text-xs">
              {question.choices.map(choice => (
                <li className="flex [&:not(:first-child)]:mt-[8px]" key={choice.choiceId}>
                  <div className="grid min-w-[254px] place-items-start">
                    {/* keyframesをReactで調整すると処理が複雑になるため、親要素でグラフの長さを調整 */}
                    {/* グラフの長さはwidthで変更 */}
                    <span
                      className="grid-area-1-1"
                      style={{
                        width: `${getPercentage(question, choice)}%`
                      }}
                    >
                      {/* アニメーション用 */}
                      {/* is-selectedクラスでグラフの色を変える */}
                      <span
                          className={`block h-[24px] rounded-r-[8px] bg-[#dce0e5] animate-expandWidth 
                          ${question.answeredChoiceId === choice.choiceId ? 'bg-lightGreen': ''}`} 
                      ></span>
                    </span>
                    <p className="px-[8px] py-[0px] leading-[24px] grid-area-1-1 md:text-xs text-[9px]">{choice.choice}</p>
                  </div>
                  <p>
                    <span className="leading-[24px]">
                      {getPercentage(question, choice)}
                    </span>
                    %
                  </p>
                </li>
              ))}
            </ul>
            <p className="mt-[8px] text-white text-[12px]">
              投票数：<span>{question.totalAnswerCount}</span>
            </p>
          </div>
        ) : (
          <ul className="mt-[20px] grid gap-2 ">
            {question.choices.map(choice => (
              <li
                className="w-full max-w-[380px] py-[10px] px-[24px] border-2 border-green rounded-full bg-white text-green font-bold text-sm text-center cursor-pointer transition-opacity duration-300 mx-auto hover:opacity-80"
                key={choice.choiceId}
                onClick={event => {
                  event.stopPropagation()
                  answerQuestion(question.questionId, choice.choiceId)
                }}
              >
                {choice.choice}
              </li>
            ))}
          </ul>
        )}
      </div>
      {question.allowDelete && deleteQuestion && (
        <button
          type="button"
          className="block w-fit mt-1 ml-auto text-Link text-xs hover:underline"
          onClick={event => {
            event.stopPropagation()
            deleteQuestion(question.questionId)
          }}
        >
          質問を削除する
        </button>
      )}
      {developerMode && onShare && (
        <button
          type="button"
          className="block w-fit mt-1 ml-auto text-Link text-xs hover:underline"
          onClick={event => {
            event.stopPropagation()
            onShare(question)
          }}
        >
          他のワークスペースに共有する
        </button>
      )}
      {onMoreViewCommentClick && (
        <button
          type="button"
          className="block w-fit mt-4 py-2 px-4 border border-green
          rounded-lg bg-neutral-paleGray text-green font-bold text-xs
           transition-opacity duration-300 mx-auto hover:opacity-80"
          onClick={() => {
            onMoreViewCommentClick(question.timelineInfo.timelineId)
          }}
        >
          {question.timelineInfo.commentCount > 0 ? (
            <>
              みんなのコメントを見る（
              <span>{question.timelineInfo.commentCount}</span>件）
            </>
          ) : (
            'コメントする'
          )}
        </button>
      )}
    </li>
  )
}
