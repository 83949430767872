import React, { FC, useState } from 'react'
import {
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'
import { Button } from '../../components/button'

interface Props {
  member: MemberQuery
  closeModal: () => void
}

export const BusinessContentEditModal: FC<Props> = ({ member, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [businessContent, setBusinessContent] = useState(member.businessContent)
  const update = () => {
    setIsLoading(true)
    MemberControllerService.updateMember({
      businessContent: businessContent,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
      })
      .catch(err => {
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  const changeBusinessContent = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setBusinessContent(event.target.value)
  }

  return (
    <div className="fixed bottom-0 top-[5%] w-full left-1/2 transform -translate-x-1/2 md:w-[590px] max-w-full overflow-hidden rounded-xl bg-white flex flex-col justify-start p-4 md:rounded-t-xl md:top-1/2 md:bottom-auto md:flex md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:p-0">
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <>
          <div>
            <button
              type="button"
              className="absolute top-6 right-6"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                aria-label="Close modal"
              >
                <rect width="24" height="24" rx="12" fill="#EDF0F7" />
                <path
                  d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                  fill="#8A8F9F"
                />
              </svg>
            </button>
          </div>
          <div className="mb-16 p-6">
            <dl className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <dt className="font-bold text-2xl md:text-3xl">主な業務内容</dt>
                <dd className="my-6 mb-4 overflow-hidden rounded-xl bg-gray-100">
                  <textarea
                    value={businessContent}
                    placeholder="主な業務内容を入力"
                    onChange={changeBusinessContent}
                    style={{ height: '10rem' }}
                    className="block w-full p-3 rounded-xl border-none bg-transparent text-inherit resize-none"
                  ></textarea>
                </dd>
              </div>
            </dl>

            <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
              <Button
                className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                onClick={closeModal}
              >
                キャンセル
              </Button>
              <Button
                className="px-6 py-3 w-32 flex justify-center items-center btn-primary-base"
                onClick={update}
              >
                保存
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
