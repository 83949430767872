import React from 'react'
import { MemberCubeQuery } from '../../services'

interface CubeListProps {
  cubes: MemberCubeQuery[]
  className?: string
  itemClassName?: string
}

const CubeList: React.FC<CubeListProps> = ({
  cubes,
  className,
  itemClassName,
}) => {
  return (
    <ul className={className}>
      {cubes.map((cube, index) => (
        <li className={itemClassName} key={index}>
          <span>{cube.cubeName}</span>
        </li>
      ))}
    </ul>
  )
}

export default CubeList
