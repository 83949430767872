import { useCallback } from 'react'
import { SearchQuery } from '../../services'
import SearchResultCubeList from '../SearchResultCubeList'

interface SearchFiltersProps {
  keyword: string
  aiFlg: boolean
  setAiFlg: React.Dispatch<React.SetStateAction<boolean>>
  selectedCubeIds: number[]
  handleCubeClick: (cubeId: number) => void
  search: SearchQuery
  handleSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  sort: string
  isLoading: boolean
}

const SearchFilters: React.FC<SearchFiltersProps> = ({
  keyword,
  aiFlg,
  setAiFlg,
  selectedCubeIds,
  handleCubeClick,
  search,
  handleSortChange,
  sort,
  isLoading,
}) => {
  const toggleAiSearch = useCallback(() => {
    setAiFlg(prev => !prev)
  }, [setAiFlg])

  const searchTitle =
    keyword === '' ? (
      <>
        <p className="member-page_search-title">メンバー</p>
        <p className="member-page_search-results">{search.totalCount}人</p>
      </>
    ) : (
      <>
        <p className="search_input-page_search-results__title">
          「{keyword}」に関連するユーザー
        </p>
        {!isLoading && (
          <p className="search_input-page_search-results__text">
            {search.totalCount}件
          </p>
        )}
      </>
    )

  return (
    <div className="search_input-page__inner">
      <div className="member-page_search">
        <div className="member-page_search-wrapper">{searchTitle}</div>
        <div className="ai-search-checkbox-wrapper">
          <input
            type="checkbox"
            id="ai-search-checkbox"
            checked={aiFlg}
            onChange={toggleAiSearch}
          />
          <label htmlFor="ai-search-checkbox">AIによる検索</label>
        </div>
      </div>
      {search.cubes && search.cubes.length > 0 && (
        <>
          <p className="search_input-page__lead">関連するキューブで検索</p>
          <SearchResultCubeList
            cubes={search.cubes}
            selectedCubeIds={selectedCubeIds}
            handleCubeClick={handleCubeClick}
          />
        </>
      )}
      {!isLoading && search.totalCount !== 0 && (
        <div className="member-page_sort-text">
          <select
            name="sort-type"
            id="sort-type"
            value={sort}
            onChange={handleSortChange}
          >
            <option value="COMMON">共通点が多い順</option>
            <option value="NEW">新着順</option>
          </select>
        </div>
      )}
    </div>
  )
}

export default SearchFilters
