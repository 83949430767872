/**
 * QuestionActions.ts
 *
 * このファイルは、タイムラインや他のコンポーネントにおける質問に関連する
 * 各種アクション（質問への回答、質問の状態更新、質問の削除など）を
 * 処理する関数をまとめたものです。
 * タイムラインと質問一覧とでデータの構造が異なるので、それぞれで関数を分けてます。  
 * 
 */

import { TimelineCardQuery, TimelineDetailQuery, QuestionControllerService, QuestionQuery, ChoiceQuery, ApiError } from '../services'
import { toast } from 'react-toastify'

const updateChoices = (
    question: QuestionQuery,
    choiceId: number
  ) => {
    return {
      ...question,
      answeredChoiceId: choiceId,
      totalAnswerCount: question.totalAnswerCount + 1,
      choices: question.choices.map((choice: ChoiceQuery) =>
        choice.choiceId === choiceId
          ? { ...choice, answerCount: choice.answerCount + 1 }
          : choice
      ),
    };
  };
  
  // タイムラインカードの質問を更新
export const answerTimelineQuestion = (
    questionId: number,
    choiceId: number,
    timelineCards: TimelineCardQuery[], // 型を実際に設定してください
    setTimelineCards: React.Dispatch<React.SetStateAction<TimelineCardQuery[]>>,
    apiErrorHandler: (error: ApiError) => void
  ) => {
    QuestionControllerService.answerQuestion(questionId, choiceId)
      .then(() => {
        setTimelineCards(
          timelineCards.map(timelineCard =>
            timelineCard.timelineCardType === 'QUESTION' &&
            timelineCard.questionCard?.question?.questionId === questionId
              ? {
                  ...timelineCard,
                  questionCard: {
                    ...timelineCard.questionCard,
                    question: updateChoices(timelineCard.questionCard.question, choiceId),
                  },
                }
              : timelineCard
          )
        );
      })
      .catch(apiErrorHandler);
  };

  // タイムライン詳細の質問を更新
export const answerTimelineDetailQuestion = (
    questionId: number,
    choiceId: number,
    timelineDetail: TimelineDetailQuery | undefined,
    setTimelineDetail: React.Dispatch<React.SetStateAction<TimelineDetailQuery | undefined>>,
    apiErrorHandler: (error: ApiError) => void
  ) => {
    if (!timelineDetail) return;
    QuestionControllerService.answerQuestion(questionId, choiceId)
      .then(() => {
        if (timelineDetail.timelineCard.timelineCardType === 'QUESTION' &&
            timelineDetail.timelineCard.questionCard?.question?.questionId === questionId) {
        // 該当の質問に対して回答を更新
        const updatedTimelineCard = {
            ...timelineDetail.timelineCard,
            questionCard: {
              ...timelineDetail.timelineCard.questionCard,
              question: updateChoices(timelineDetail.timelineCard.questionCard.question, choiceId),
            },
          };
  
          // 全体の状態を直接更新
          setTimelineDetail({
            ...timelineDetail,
            timelineCard: updatedTimelineCard
          });
        }
      })
      .catch(apiErrorHandler);
  };
  
  // 質問リストの質問を更新
  export const answerQuestionsList = (
    questionId: number,
    choiceId: number,
    questions: QuestionQuery[],
    setQuestions: React.Dispatch<React.SetStateAction<QuestionQuery[]>>,
    apiErrorHandler: (error: ApiError) => void
  ) => {
    QuestionControllerService.answerQuestion(questionId, choiceId)
      .then(() => {
        setQuestions(
          questions.map(question =>
            question.questionId === questionId
              ? updateChoices(question, choiceId)
              : question
          )
        );
      })
      .catch(apiErrorHandler);
  };
  
  // 質問の削除処理の共通ロジック
const deleteQuestionHandler = <T extends TimelineCardQuery | QuestionQuery>(
    questionId: number,
    items: T[],
    setItems: React.Dispatch<React.SetStateAction<T[]>>,
    filterCondition: (item: T ) => boolean,
    apiErrorHandler: (error: ApiError) => void
  ) => {
    if (!window.confirm('この質問を削除しますか？')) return;
    QuestionControllerService.deleteQuestion(questionId)
      .then(() => {
        toast.success('質問を削除しました');
        setItems(items.filter(filterCondition));
      })
      .catch(apiErrorHandler);
  };
  
  // タイムラインカードから質問を削除する関数
  export const deleteQuestionFromTimeline = (
    questionId: number,
    timelineCards: TimelineCardQuery[],
    setTimelineCards: React.Dispatch<React.SetStateAction<TimelineCardQuery[]>>,
    apiErrorHandler: (error: ApiError) => void
  ) => {
    deleteQuestionHandler(
      questionId,
      timelineCards,
      setTimelineCards,
      (timelineCard) => timelineCard.questionCard?.question?.questionId !== questionId,
      apiErrorHandler
    );
  };
  
  // 質問リストから質問を削除する関数
  export const deleteQuestionFromList = (
    questionId: number,
    questions: QuestionQuery[],
    setQuestions: React.Dispatch<React.SetStateAction<QuestionQuery[]>>,
    apiErrorHandler: (error: ApiError) => void
  ) => {
    deleteQuestionHandler(
      questionId,
      questions,
      setQuestions,
      (question) => question.questionId !== questionId,
      apiErrorHandler
    );
  };