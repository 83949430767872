import React, { FC } from 'react'

interface Option {
  value: string
  label: string
}

interface Props {
  options: Option[]
  selected: string
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onOpen?: () => void
}

export const SelectInput: FC<Props> = ({
  options,
  selected,
  disabled = false,
  onChange,
  onOpen,
}) => {
  return (
    <select
      className="w-[88px] min-w-[88px] py-2.5 pr-8 pl-5 bg-gray-100 text-left mt-1 ml-1 rounded-xl font-normal text-base cursor-pointer md:ml-3 md:w-[100px]"
      disabled={disabled}
      value={selected}
      onChange={onChange}
      onMouseDown={onOpen}
    >
      {options.map((option, index) => (
        <option
          key={index}
          value={option.value}
          disabled={!option.value && option.value !== ''}
        >
          {option.label}
        </option>
      ))}
    </select>
  )
}
