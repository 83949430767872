import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useModal } from '../../contexts/modalContext'
import { CubeDetailModal } from '../../modals/cubeDetailModal'
import { exportCsv } from '../../lib/common'
import { Button } from '../button'
import { Pagination } from '../pagination'
import { RankingQuery, RankingTypeMap } from '../../services'

interface TableProps {
  developerMode?: boolean
  workspaceName?: string
  rankingTypeMaps?: RankingTypeMap[]
  rankingType: string
  headContents: string[]
  rankingQueries: RankingQuery[]
  filterDate: string
}

interface RankingCount {
  id: number
  name: string
  count: number
}

export const RankingTable: FC<TableProps> = ({
  developerMode,
  workspaceName,
  rankingTypeMaps,
  rankingType,
  headContents,
  rankingQueries,
  filterDate,
}) => {
  const query = new URLSearchParams(useLocation().search)
  const page = parseInt(query.get('page') as string) || 1
  const limit = 30
  const navigate = useNavigate()
  const { openModal } = useModal()

  const [rankingCount, setRankingCount] = useState([] as RankingCount[])

  useEffect(() => {
    if (rankingQueries.length > 0) {
      const rankingCountMap = rankingQueries.reduce(
        (acc, rankingQuery) => ({
          ...acc,
          [rankingQuery.id]: (acc[rankingQuery.id] || 0) + 1,
        }),
        {} as { [key: string]: number },
      )

      const newRankingCount = rankingQueries
        .map(
          rankingQuery =>
            ({
              id: rankingQuery.id,
              name: rankingQuery.name,
              count: rankingCountMap[rankingQuery.id],
            } as RankingCount),
        )
        // 重複削除
        .filter(
          (rankingCount, index, self) =>
            self.findIndex(rc => rc.id === rankingCount.id) === index,
        )
        // ソート
        .sort((a, b) => b.count - a.count)

      setRankingCount(newRankingCount)
    }
  }, [rankingQueries])

  return rankingQueries.length === 0 ? (
    <p className="table-contents_none">データが存在しません</p>
  ) : (
    <>
      {developerMode && workspaceName && rankingTypeMaps && (
        <div className="table-export">
          <Button
            className="btn-s"
            onClick={() => {
              exportCsv(
                workspaceName +
                  '_' +
                  rankingTypeMaps.find(
                    rankingTypeMap => rankingTypeMap.name === rankingType,
                  )?.label +
                  '_' +
                  (filterDate ? filterDate : '全期間'),
                headContents,
                rankingCount.map((rankingCount, index) => [
                  (index + 1).toString(),
                  rankingCount.name,
                  rankingCount.count.toString(),
                ]),
              )
            }}
          >
            出力
          </Button>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            {headContents.map((headContent, index) => (
              <th key={index}>{headContent}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rankingCount
            .slice((page - 1) * limit, page * limit)
            .map((rankingCount, index) => (
              <tr
                key={index}
                onClick={() => {
                  if (rankingType.includes('CUBE')) {
                    openModal(CubeDetailModal, {
                      cubeId: rankingCount.id,
                      yearMonth: filterDate,
                    })
                  } else {
                    // 別タブでメンバー詳細画面を開く
                    window.open(`/member-detail/${rankingCount.id}`, '_blank')
                  }
                }}
              >
                <td>{index + 1 + (page - 1) * limit}</td>
                <td>{rankingCount.name}</td>
                <td>{rankingCount.count}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        totalPage={Math.ceil(rankingCount.length / limit)}
        currentPage={page}
        goNext={() => navigate(`?page=${page + 1}`)}
        goBack={() => navigate(`?page=${page - 1}`)}
        goPage={(page: number) => navigate(`?page=${page}`)}
      />
    </>
  )
}
