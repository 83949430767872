import React, { FC } from 'react'
import { TextInput } from '../../components/textInput'
import { DateInput } from '../../components/dateInput'
import { PublishingSettingInput } from '../../components/publishingSettingInput'
import { MemberQuery, MemberUpdateForm } from '../../services'
import { Button } from '../../components/button'

interface Props {
  memberUpdateForm: MemberUpdateForm
  setMemberUpdateForm: React.Dispatch<React.SetStateAction<MemberUpdateForm>>
  navigateToNextPage: () => void
  member: MemberQuery
}
export const BasicInfoForm: FC<Props> = ({
  memberUpdateForm,
  setMemberUpdateForm,
  navigateToNextPage,
  member,
}) => {
  // データ更新
  const updateMemberData = (key: string, value: any) => {
    setMemberUpdateForm(prevData => ({
      ...prevData,
      [key]: value,
    }))
  }
  return (
    <>
      <div className="pagetitle__wrapper">
        <h1 className="pagetitle pagetitle--large">
          {member.memberName ? (
            <>
              ようこそ、<span>{member.memberName}</span>さん👋
            </>
          ) : (
            <>parksへようこそ👋</>
          )}
        </h1>
        <p className="pagetitle__description">
          まずはプロフィールを登録しましょう
          <br />
          プロフィールを充実させるとあなたに合ったメンバーと繋がれます
        </p>
      </div>
      <div className="form__wrapper form__wrapper--wide form__wrapper--square">
        <div className="form form--wide">
          <section className="form__section">
            <h2 className="form__section-title">基本情報登録</h2>
            <p className="form__item">
              <TextInput
                label="氏名"
                placeholder="氏名"
                value={memberUpdateForm.memberName}
                maxLength={64}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateMemberData('memberName', event.target.value)
                }}
              />
            </p>
            <div className="form__item">
              <DateInput
                label="生年月日"
                date={memberUpdateForm.dateOfBirth}
                onChange={(dateOfBirth: string) => {
                  updateMemberData('dateOfBirth', dateOfBirth)
                }}
              />
            </div>
            <div className="form__item form__item--flex form__item--mt-narrow">
              <PublishingSettingInput
                publicFlg={memberUpdateForm.dateOfBirthShowFlg}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  updateMemberData(
                    'dateOfBirthShowFlg',
                    event.target.value === 'true',
                  )
                }}
              />
            </div>
            <div className="form__item">
              <DateInput
                label="入社日"
                date={memberUpdateForm.dateOfEntry}
                onChange={(dateOfEntry: string) => {
                  updateMemberData('dateOfEntry', dateOfEntry)
                }}
              />
            </div>
            <p className="form__item">
              <TextInput
                label="メールアドレス"
                placeholder={member.email}
                value=""
                maxLength={255}
              />
            </p>
            <p className="form__submit">
              <Button
                className="btn btn--sp-narrow"
                onClick={navigateToNextPage}
              >
                つぎへ
              </Button>
            </p>
          </section>
        </div>
      </div>
    </>
  )
}
